import { fetchDbUiFormError, fetchDbUiFormSuccess } from "actions/DbUIActions";
import { FETCH_DBUI_FORM_REQUEST } from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { appAxios } from "util/Api";

const fetchDbUiForm = async formName => {
    return (await appAxios.get(`/dbui/form/by/name/${formName}`)).data;
}

function* fetchDbUiFormAPIRequest(action) {
  const defaultErrorMsg = "Failed to fetch dbui form";
  try {
    const response = yield call(fetchDbUiForm, action.payload);
    if (response?.success) {
        yield put(fetchDbUiFormSuccess(response?.body || {}));
    } else {
        NotificationManager.error(response?.message || defaultErrorMsg);
        yield put(fetchDbUiFormError({}));
    }
  } catch (error) {
    yield put(fetchDbUiFormError({}));
    NotificationManager.error(defaultErrorMsg);
  }
}

function* fetchDbUi() {
  yield takeLatest(FETCH_DBUI_FORM_REQUEST, fetchDbUiFormAPIRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchDbUi)]);
}

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const installations = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      <Route
        path={`${match.url}/Reports`}
        component={asyncComponent(() => import("./routes/Reports"))}
      />
      <Route
        path={`${match.url}/ReportsV2`}
        component={asyncComponent(() => import("./routes/ReportsV2"))}
      />
      <Route
        path={`${match.url}/Rewards`}
        component={asyncComponent(() => import("./routes/Rewards"))}
      />
      <Route
        path={`${match.url}/Refunds`}
        component={asyncComponent(() => import("./routes/Refunds"))}
      />
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./routes/Dashboard"))}
      />
      <Route
        path={`${match.url}/AllCustomers`}
        component={asyncComponent(() => import("./routes/AllCustomers"))}
      />
      <Route
        path={`${match.url}/B2BPayment`}
        component={asyncComponent(() => import("./routes/B2BPayments/index"))}
      />
      {/* <Route
                path={`${match.url}/:deviceId/ticket/:id`}
                component={asyncComponent(() => import("./routes/Dashboard/components/InstallationDetails/components/TicketDetails"))}
            /> */}
      <Route
        path={`${match.url}/paymentLinkLookup`}
        component={asyncComponent(() => import("./routes/GenerateLinkLookup"))}
      />
      <Route
        path={`${match.url}/installationDetail/:id/:walletId/transactions/:type/:dashboard`}
        component={asyncComponent(() =>
          import(
            "./routes/Dashboard/components/InstallationDetails/components/TransactionDetails"
          )
        )}
      />
      {/* <Route
        path={`${match.url}/installationDetail/:id`}
        component={asyncComponent(() =>
          import("./routes/Dashboard/components/InstallationDetails")
        )}
      /> */}
      <Route
        path={`${match.url}/plan/list`}
        component={asyncComponent(() => import("./routes/PlanList"))}
      />
      {/* TODO: chnage URL */}
      <Route
        path={`${match.url}/plan/view/:id`}
        component={asyncComponent(() => import("./routes/ViewPlan"))}
      />
      <Route
        path={`${match.url}/plan/add`}
        component={asyncComponent(() => import("./routes/AddPlan"))}
      />
      <Route
        path={`${match.url}/plan/edit/:id`}
        component={asyncComponent(() => import("./routes/EditPlan"))}
      />
      <Route
        path={`${match.url}/paymentDashboard`}
        component={asyncComponent(() => import("./routes/Payments"))}
      />
      <Route
        path={`${match.url}/planDashboard`}
        component={asyncComponent(() => import("./routes/PlanDashboard"))}
      />
      <Route component={asyncComponent(() => import("components/Error404"))} />
    </Switch>
  </div>
);

export default installations;

export const EMPTY = '';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';

export const IssuancePackageStatus = {
    ASG: "ASSIGNED",
    REC: "RECONCILED",
    DTT: "DELIVERED TO TECH",
    DTW: "DELIVERED TO WAREHOUSE",
    NA: "-"
}

export const defaultProfilePicURL =
  "https://drinkprimeimage.s3.ap-south-1.amazonaws.com/No_Profile_Picture.jpeg";
import {
  CLEAR_CACHE_CPM_MAPPING_ERROR,
  CLEAR_CACHE_CPM_MAPPING_REQUEST,
  CLEAR_CACHE_CPM_MAPPING_SUCCESS,
  CLEAR_DEVICE_MODEL_CACHE_ERROR,
  CLEAR_DEVICE_MODEL_CACHE_REQUEST,
  CLEAR_DEVICE_MODEL_CACHE_SUCCESS,
  CREATE_CHANGE_MODEL_DRT_JOB_ERROR,
  CREATE_CHANGE_MODEL_DRT_JOB_REQUEST,
  CREATE_CHANGE_MODEL_DRT_JOB_SUCCESS,
  ERROR_CREATE_CPM_MAPPING,
  ERROR_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY,
  FETCH_ALL_DEVICE_SUCCESS,
  FETCH_DEVICE_MODEL_OF_DEVICE_ERROR,
  FETCH_DEVICE_MODEL_OF_DEVICE_REQUEST,
  FETCH_DEVICE_MODEL_OF_DEVICE_SUCCESS,
  FETCH_DEVICE_SUCCESS,
  FETCH_PLAN_SUCCESS,
  FETCH_VALID_DEVICE_MODELS_FOR_CITY_ERR,
  FETCH_VALID_DEVICE_MODELS_FOR_CITY_REQ,
  FETCH_VALID_DEVICE_MODELS_FOR_CITY_SUC,
  FETCH_VALID_PLANS_TRANS_FOR_CITY_ERR,
  FETCH_VALID_PLANS_TRANS_FOR_CITY_REQ,
  FETCH_VALID_PLANS_TRANS_FOR_CITY_SUC,
  REQUEST_CREATE_CPM_MAPPING,
  REQUEST_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY,
  SEARCH_ALL_CPM_MAPPING_ERROR,
  SEARCH_ALL_CPM_MAPPING_REQUEST,
  SEARCH_ALL_CPM_MAPPING_SUCCESS,
  SEARCH_ALL_INVALIDATED_CPM_MAPPING_ERROR,
  SEARCH_ALL_INVALIDATED_CPM_MAPPING_REQUEST,
  SEARCH_ALL_INVALIDATED_CPM_MAPPING_SUCCESS,
  SEARCH_CITY_PLAN_MODEL_MAPPINGS_ERROR,
  SEARCH_CITY_PLAN_MODEL_MAPPINGS_REQUEST,
  SEARCH_CITY_PLAN_MODEL_MAPPINGS_SUCCESS,
  SHOW_API_MESSAGE,
  SUCCESS_CREATE_CPM_MAPPING,
  SUCCESS_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY,
  UPDATE_DEVICE_MODEL_NAMES_ERROR,
  UPDATE_DEVICE_MODEL_NAMES_REQUEST,
  UPDATE_DEVICE_MODEL_NAMES_SUCCESS,
  VALIDATE_CPM_MAPPING_ERROR,
  VALIDATE_CPM_MAPPING_REQUEST,
  VALIDATE_CPM_MAPPING_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  devices: [],
  totalCount: 0,
  loader: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_DEVICE_SUCCESS: {
      return {
        ...state,
        allDevices: action.payload,
        devices: action.payload.devices,
        totalCount: action.payload.total,
        loader: false,
      };
    }
    case FETCH_DEVICE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_PLAN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case SEARCH_CITY_PLAN_MODEL_MAPPINGS_REQUEST: {
      return {
        ...state,
        cityPlanModelMappingsPage: [],
        cityPlanModelMappingsPageLoader: true,
        cityPlanModelMappingsPageError: false,
      };
    }
    case SEARCH_CITY_PLAN_MODEL_MAPPINGS_SUCCESS: {
      return {
        ...state,
        cityPlanModelMappingsPage: action.payload,
        cityPlanModelMappingsPageLoader: false,
        cityPlanModelMappingsPageError: false,
      };
    }
    case SEARCH_CITY_PLAN_MODEL_MAPPINGS_ERROR: {
      return {
        ...state,
        cityPlanModelMappingsPage: [],
        cityPlanModelMappingsPageLoader: false,
        cityPlanModelMappingsPageError: true,
      };
    }
    case FETCH_VALID_PLANS_TRANS_FOR_CITY_REQ: {
      return {
        ...state,
        validPlanTransOfCities: [],
        validPlanTransOfCitiesLoader: true,
        validPlanTransOfCitiesError: false,
      };
    }
    case FETCH_VALID_PLANS_TRANS_FOR_CITY_SUC: {
      return {
        ...state,
        validPlanTransOfCities: action?.payload,
        validPlanTransOfCitiesLoader: false,
        validPlanTransOfCitiesError: false,
      };
    }
    case FETCH_VALID_PLANS_TRANS_FOR_CITY_ERR: {
      return {
        ...state,
        validPlanTransOfCities: [],
        validPlanTransOfCitiesLoader: false,
        validPlanTransOfCitiesError: true,
      };
    }
    case FETCH_VALID_DEVICE_MODELS_FOR_CITY_REQ: {
      return {
        ...state,
        validModelsOfCity: [],
        validModelsOfCityLoader: true,
        validModelsOfCityError: false,
      };
    }
    case FETCH_VALID_DEVICE_MODELS_FOR_CITY_SUC: {
      return {
        ...state,
        validModelsOfCity: action.payload,
        validModelsOfCityLoader: false,
        validModelsOfCityError: false,
      };
    }
    case FETCH_VALID_DEVICE_MODELS_FOR_CITY_ERR: {
      return {
        ...state,
        validModelsOfCity: [],
        validModelsOfCityLoader: false,
        validModelsOfCityError: true,
      };
    }
    case REQUEST_CREATE_CPM_MAPPING: {
      return {
        ...state,
        createCPMMappingLoader: true,
        createdCPMMappings: [],
        createCPMMappingError: false,
      };
    }
    case SUCCESS_CREATE_CPM_MAPPING: {
      return {
        ...state,
        createCPMMappingLoader: false,
        createdCPMMappings: action?.payload,
        createCPMMappingError: false,
      };
    }
    case ERROR_CREATE_CPM_MAPPING: {
      return {
        ...state,
        createCPMMappingLoader: false,
        createdCPMMappings: [],
        createCPMMappingError: true,
      };
    }
    case REQUEST_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY: {
      return {
        ...state,
        invalidateCPMMappingLoader: true,
        invalidateCPMMappingSuccess: false,
        invalidateCPMMappingError: false,
      };
    }
    case SUCCESS_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY: {
      return {
        ...state,
        invalidateCPMMappingLoader: false,
        invalidateCPMMappingSuccess: true,
        invalidateCPMMappingError: false,
      };
    }
    case ERROR_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY: {
      return {
        ...state,
        invalidateCPMMappingLoader: false,
        invalidateCPMMappingSuccess: false,
        invalidateCPMMappingError: true,
      };
    }
    case VALIDATE_CPM_MAPPING_REQUEST: {
      return {
        ...state,
        validateCPMMapingLoader: true,
        validateCPMMapingSuccess: false,
        validateCPMMapingError: false,
      };
    }
    case VALIDATE_CPM_MAPPING_SUCCESS: {
      return {
        ...state,
        validateCPMMapingLoader: false,
        validateCPMMapingSuccess: true,
        validateCPMMapingError: false,
      };
    }
    case VALIDATE_CPM_MAPPING_ERROR: {
      return {
        ...state,
        validateCPMMapingLoader: false,
        validateCPMMapingSuccess: false,
        validateCPMMapingError: true,
      };
    }
    case SEARCH_ALL_CPM_MAPPING_REQUEST: {
      return {
        ...state,
        allCpmMappings: [],
        allCpmMappingsLoader: true,
        allCpmMappingsError: false,
      };
    }
    case SEARCH_ALL_CPM_MAPPING_SUCCESS: {
      return {
        ...state,
        allCpmMappings: action?.payload,
        allCpmMappingsLoader: false,
        allCpmMappingsError: false,
      };
    }
    case SEARCH_ALL_CPM_MAPPING_ERROR: {
      return {
        ...state,
        allCpmMappings: [],
        allCpmMappingsLoader: false,
        allCpmMappingsError: true,
      };
    }
    case SEARCH_ALL_INVALIDATED_CPM_MAPPING_REQUEST: {
      return {
        ...state,
        allInvalidatedCpmMappings: [],
        allInvalidatedCpmMappingsLoader: true,
        allInvalidatedCpmMappingsError: false,
      };
    }
    case SEARCH_ALL_INVALIDATED_CPM_MAPPING_SUCCESS: {
      return {
        ...state,
        allInvalidatedCpmMappings: action?.payload,
        allInvalidatedCpmMappingsLoader: false,
        allInvalidatedCpmMappingsError: false,
      };
    }
    case SEARCH_ALL_INVALIDATED_CPM_MAPPING_ERROR: {
      return {
        ...state,
        allInvalidatedCpmMappings: [],
        allInvalidatedCpmMappingsLoader: false,
        allInvalidatedCpmMappingsError: true,
      };
    }
    case UPDATE_DEVICE_MODEL_NAMES_REQUEST: {
      return {
        ...state,
        renameDeviceModelNameLoader: true,
        renameDeviceModelNameSuccess: false,
        renameDeviceModelNameError: false,
      };
    }
    case UPDATE_DEVICE_MODEL_NAMES_SUCCESS: {
      return {
        ...state,
        renameDeviceModelNameLoader: false,
        renameDeviceModelNameSuccess: true,
        renameDeviceModelNameError: false,
      };
    }
    case UPDATE_DEVICE_MODEL_NAMES_ERROR: {
      return {
        ...state,
        renameDeviceModelNameLoader: false,
        renameDeviceModelNameSuccess: false,
        renameDeviceModelNameError: true,
      };
    }
    case FETCH_DEVICE_MODEL_OF_DEVICE_REQUEST: {
      return {
        ...state,
        deviceModelOfDevice: null,
        deviceModelOfDeviceLoader: true,
        deviceModelOfDeviceError: false,
      };
    }
    case FETCH_DEVICE_MODEL_OF_DEVICE_SUCCESS: {
      return {
        ...state,
        deviceModelOfDevice: action?.payload,
        deviceModelOfDeviceLoader: false,
        deviceModelOfDeviceError: false,
      };
    }
    case FETCH_DEVICE_MODEL_OF_DEVICE_ERROR: {
      return {
        ...state,
        deviceModelOfDevice: null,
        deviceModelOfDeviceLoader: false,
        deviceModelOfDeviceError: true,
      };
    }
    case CREATE_CHANGE_MODEL_DRT_JOB_REQUEST: {
      return {
        ...state,
        createChangeModelDRTJobLoader: true,
        createChangeModelDRTJobSuccess: false,
        createChangeModelDRTJobError: false,
      };
    }
    case CREATE_CHANGE_MODEL_DRT_JOB_ERROR: {
      return {
        ...state,
        createChangeModelDRTJobLoader: false,
        createChangeModelDRTJobSuccess: false,
        createChangeModelDRTJobError: true,
      };
    }
    case CREATE_CHANGE_MODEL_DRT_JOB_SUCCESS: {
      return {
        ...state,
        createChangeModelDRTJobLoader: false,
        createChangeModelDRTJobSuccess: true,
        createChangeModelDRTJobError: false,
      };
    }
    case CLEAR_CACHE_CPM_MAPPING_REQUEST: {
      return {
        ...state,
        clearCPMCacheLoader: true,
        clearCPMCacheSuccess: false,
        clearCPMCacheError: false,
      };
    }
    case CLEAR_CACHE_CPM_MAPPING_SUCCESS: {
      return {
        ...state,
        clearCPMCacheLoader: false,
        clearCPMCacheSuccess: true,
        clearCPMCacheError: false,
      };
    }
    case CLEAR_CACHE_CPM_MAPPING_ERROR: {
      return {
        ...state,
        clearCPMCacheLoader: false,
        clearCPMCacheSuccess: false,
        clearCPMCacheError: true,
      };
    }

    case CLEAR_DEVICE_MODEL_CACHE_REQUEST: {
      return {
        ...state,
        clearDeviceModelLoader: true,
        clearDeviceModelSuccess: false,
        clearDeviceModelError: false,
      };
    }
    case CLEAR_DEVICE_MODEL_CACHE_SUCCESS: {
      return {
        ...state,
        clearDeviceModelCacheLoader: false,
        clearDeviceModelSuccess: true,
        clearDeviceModelError: false,
      };
    }
    case CLEAR_DEVICE_MODEL_CACHE_ERROR: {
      return {
        ...state,
        clearDeviceModelLoader: false,
        clearDeviceModelSuccess: false,
        clearDeviceModelError: true,
      };
    }
    default:
      return state;
  }
};
import {
  ACTIVATE_DEVICE,
  APPLY_PLAN_FOR_INSTALLATION,
  CLEAR_CACHE_CPM_MAPPING_ERROR,
  CLEAR_CACHE_CPM_MAPPING_REQUEST,
  CLEAR_CACHE_CPM_MAPPING_SUCCESS,
  CLEAR_DEVICE_MODEL_CACHE_ERROR,
  CLEAR_DEVICE_MODEL_CACHE_REQUEST,
  CLEAR_DEVICE_MODEL_CACHE_SUCCESS,
  CREATE_CHANGE_MODEL_DRT_JOB_ERROR,
  CREATE_CHANGE_MODEL_DRT_JOB_REQUEST,
  CREATE_CHANGE_MODEL_DRT_JOB_SUCCESS,
  ERROR_CREATE_CPM_MAPPING,
  ERROR_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY,
  FETCH_ALL_DEVICE,
  FETCH_ALL_DEVICE_SUCCESS,
  FETCH_COUPON_FAILURE,
  FETCH_COUPON_REQUEST,
  FETCH_COUPON_SUCCESS,
  FETCH_DEVICE,
  FETCH_DEVICE_ERROR,
  FETCH_DEVICE_MODEL_OF_DEVICE_ERROR,
  FETCH_DEVICE_MODEL_OF_DEVICE_REQUEST,
  FETCH_DEVICE_MODEL_OF_DEVICE_SUCCESS,
  FETCH_DEVICE_SUCCESS,
  FETCH_PLAN,
  FETCH_PLAN_SUCCESS,
  FETCH_VALID_DEVICE_MODELS_FOR_CITY_ERR,
  FETCH_VALID_DEVICE_MODELS_FOR_CITY_REQ,
  FETCH_VALID_DEVICE_MODELS_FOR_CITY_SUC,
  FETCH_VALID_PLANS_TRANS_FOR_CITY_ERR,
  FETCH_VALID_PLANS_TRANS_FOR_CITY_REQ,
  FETCH_VALID_PLANS_TRANS_FOR_CITY_SUC,
  REQUEST_CREATE_CPM_MAPPING,
  REQUEST_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY,
  SEARCH_ALL_CPM_MAPPING_ERROR,
  SEARCH_ALL_CPM_MAPPING_REQUEST,
  SEARCH_ALL_CPM_MAPPING_SUCCESS,
  SEARCH_ALL_INVALIDATED_CPM_MAPPING_ERROR,
  SEARCH_ALL_INVALIDATED_CPM_MAPPING_REQUEST,
  SEARCH_ALL_INVALIDATED_CPM_MAPPING_SUCCESS,
  SEARCH_CITY_PLAN_MODEL_MAPPINGS_ERROR,
  SEARCH_CITY_PLAN_MODEL_MAPPINGS_REQUEST,
  SEARCH_CITY_PLAN_MODEL_MAPPINGS_SUCCESS,
  SHOW_API_MESSAGE,
  SUCCESS_CREATE_CPM_MAPPING,
  SUCCESS_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY,
  UPDATE_DEVICE_MODEL_NAMES_ERROR,
  UPDATE_DEVICE_MODEL_NAMES_REQUEST,
  UPDATE_DEVICE_MODEL_NAMES_SUCCESS,
  VALIDATE_CPM_MAPPING_ERROR,
  VALIDATE_CPM_MAPPING_REQUEST,
  VALIDATE_CPM_MAPPING_SUCCESS,
} from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";

export const fetchDevice = id => {
  return {
    type: FETCH_DEVICE,
    payload: { id },
  };
};
export const fetchPlans = () => {
  return {
    type: FETCH_PLAN,
  };
};
export const fetchPlanSuccess = plans => {
  return {
    type: FETCH_PLAN_SUCCESS,
    payload: {
      uiState: SUCCESS,
      plans: plans,
    },
  };
};

export const fetchDevices = payload => {
  return {
    type: FETCH_ALL_DEVICE,
    payload,
  };
};

export const activateDevice = device => {
  return {
    type: ACTIVATE_DEVICE,
    payload: {
      deviceCode: device,
    },
  };
};

export const applyPlan = (installationId, plan) => {
  return {
    type: APPLY_PLAN_FOR_INSTALLATION,
    payload: {
      installationId: installationId,
      plan: plan,
    },
  };
};
export const fetchDeviceSuccess = device => {
  return {
    type: FETCH_DEVICE_SUCCESS,
    payload: {
      uiState: SUCCESS,
      device: device,
    },
  };
};

export const fetchDeviceError = error => {
  return {
    type: FETCH_DEVICE_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const fetchDevicesSuccess = device => {
  return {
    type: FETCH_ALL_DEVICE_SUCCESS,
    payload: device,
  };
};

export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};

export const fetchCouponRequest = () => {
  return {
    type: FETCH_COUPON_REQUEST,
  };
};
export const fetchCouponSuccess = data => {
  return {
    type: FETCH_COUPON_SUCCESS,
    payload: {
      ...data,
    },
  };
};
export const fetchCouponError = data => {
  return {
    type: FETCH_COUPON_FAILURE,
    payload: {
      ...data,
    },
  };
};
export const searchCityPlanModelMappingsRequest = payload => {
  return {
    type: SEARCH_CITY_PLAN_MODEL_MAPPINGS_REQUEST,
    payload,
  };
};
export const searchCityPlanModelMappingsSuccess = payload => {
  return {
    type: SEARCH_CITY_PLAN_MODEL_MAPPINGS_SUCCESS,
    payload,
  };
};
export const searchCityPlanModelMappingsError = payload => {
  return {
    type: SEARCH_CITY_PLAN_MODEL_MAPPINGS_ERROR,
    payload,
  };
};

export const fetchValidPlansTransForCityReq = payload => {
  return {
    type: FETCH_VALID_PLANS_TRANS_FOR_CITY_REQ,
    payload,
  };
};
export const fetchValidPlansTransForCitySuc = payload => {
  return {
    type: FETCH_VALID_PLANS_TRANS_FOR_CITY_SUC,
    payload,
  };
};
export const fetchValidPlansTransForCityErr = payload => {
  return {
    type: FETCH_VALID_PLANS_TRANS_FOR_CITY_ERR,
    payload,
  };
};
export const createChangeModelDRTJobRequest = payload => {
  return {
    type: CREATE_CHANGE_MODEL_DRT_JOB_REQUEST,
    payload,
  };
}
export const createChangeModelDRTJobSuccess = payload => {
  return {
    type: CREATE_CHANGE_MODEL_DRT_JOB_SUCCESS,
    payload,
  };
};
export const createChangeModelDRTJobError = payload => {
  return {
    type: CREATE_CHANGE_MODEL_DRT_JOB_ERROR,
    payload,
  };
};
export const fetchValidDeviceModelsForCityReq = payload => {
  return {
    type: FETCH_VALID_DEVICE_MODELS_FOR_CITY_REQ,
    payload,
  };
};
export const fetchValidDeviceModelsForCitySuc = payload => {
  return {
    type: FETCH_VALID_DEVICE_MODELS_FOR_CITY_SUC,
    payload,
  };
};
export const fetchValidDeviceModelsForCityErr = payload => {
  return {
    type: FETCH_VALID_DEVICE_MODELS_FOR_CITY_ERR,
    payload,
  };
};
export const clearCpmCacheRequest = payload => {
  return {
    type: CLEAR_CACHE_CPM_MAPPING_REQUEST,
    payload
  }
}
export const clearCpmCacheSuccess = payload => {
  return {
    type: CLEAR_CACHE_CPM_MAPPING_SUCCESS,
    payload,
  };
};
export const clearCpmCacheError = payload => {
  return {
    type: CLEAR_CACHE_CPM_MAPPING_ERROR,
    payload,
  };
};

export const clearDeviceModelsCacheRequest = payload => {
  return {
    type: CLEAR_DEVICE_MODEL_CACHE_REQUEST,
    payload,
  };
};
export const clearDeviceModelsCacheSuccess = payload => {
  return {
    type: CLEAR_DEVICE_MODEL_CACHE_SUCCESS,
    payload,
  };
};
export const clearDeviceModelsCacheError = payload => {
  return {
    type: CLEAR_DEVICE_MODEL_CACHE_ERROR,
    payload,
  };
};

export const requestCreateCPMMapping = payload => {
  return {
    type: REQUEST_CREATE_CPM_MAPPING,
    payload,
  };
};
export const successCreateCPMMapping = payload => {
  return {
    type: SUCCESS_CREATE_CPM_MAPPING,
    payload,
  };
};
export const errorCreateCPMMapping = payload => {
  return {
    type: ERROR_CREATE_CPM_MAPPING,
    payload,
  };
};
export const requestInvalidateCPMMappingForNewDelivery = payload => {
  return {
    type: REQUEST_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY,
    payload,
  };
};
export const successInvalidateCPMMappingForNewDelivery = payload => {
  return {
    type: SUCCESS_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY,
    payload,
  };
};
export const errorInvalidateCPMMappingForNewDelivery = payload => {
  return {
    type: ERROR_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY,
    payload,
  };
};
export const validateCPMMappingRequest = payload => {
  return {
    type: VALIDATE_CPM_MAPPING_REQUEST,
    payload,
  };
};
export const validateCPMMapingSuccess = payload => {
  return {
    type: VALIDATE_CPM_MAPPING_SUCCESS,
    payload,
  };
};
export const validateCPMMapingError = payload => {
  return {
    type: VALIDATE_CPM_MAPPING_ERROR,
    payload,
  };
};
export const searchAllCPMMappingRequest = payload => {
  return {
    type: SEARCH_ALL_CPM_MAPPING_REQUEST,
    payload,
  };
};
export const searchAllCPMMappingSuccess = payload => {
  return {
    type: SEARCH_ALL_CPM_MAPPING_SUCCESS,
    payload,
  };
};
export const searchAllCPMMappingError = payload => {
  return {
    type: SEARCH_ALL_CPM_MAPPING_ERROR,
    payload,
  };
};
export const searchAllInvalidatedCPMMappingRequest = payload => {
  return {
    type: SEARCH_ALL_INVALIDATED_CPM_MAPPING_REQUEST,
    payload,
  };
};
export const searchAllInvalidatedCPMMappingSuccess = payload => {
  return {
    type: SEARCH_ALL_INVALIDATED_CPM_MAPPING_SUCCESS,
    payload,
  };
};
export const searchAllInvalidatedCPMMappingError = payload => {
  return {
    type: SEARCH_ALL_INVALIDATED_CPM_MAPPING_ERROR,
    payload,
  };
};
export const updateDeviceModelNamesRequest = payload => {
  return {
    type: UPDATE_DEVICE_MODEL_NAMES_REQUEST,
    payload,
  };
}
export const updateDeviceModelNamesSuccess = payload => {
  return {
    type: UPDATE_DEVICE_MODEL_NAMES_SUCCESS,
    payload,
  };
};
export const updateDeviceModelNamesError = payload => {
  return {
    type: UPDATE_DEVICE_MODEL_NAMES_ERROR,
    payload,
  };
};
export const fetchDeviceModelOfDeviceRequest = payload => {
  return {
    type: FETCH_DEVICE_MODEL_OF_DEVICE_REQUEST,
    payload,
  };
}
export const fetchDeviceModelOfDeviceSuccess = payload => {
  return {
    type: FETCH_DEVICE_MODEL_OF_DEVICE_SUCCESS,
    payload,
  };
}
export const fetchDeviceModelOfDeviceError = payload => {
  return {
    type: FETCH_DEVICE_MODEL_OF_DEVICE_ERROR,
    payload,
  };
};

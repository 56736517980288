import {
  addNewSlotCategoryError,
  addNewSlotCategorySuccess,
  addOrUpdateDisplayRuleError,
  addOrUpdateDisplayRuleSuccess,
  assignJobTagsToSlotCategoryError,
  assignJobTagsToSlotCategorySuccess,
  assignZonesAndSlotsToSlotCategoryError,
  assignZonesAndSlotsToSlotCategorySuccess,
  disableZonesAndSlotsToSlotCategoryError,
  disableZonesAndSlotsToSlotCategorySuccess,
  fetchAllCategoriesError,
  fetchAllCategoriesSuccess,
  fetchAssignedZonesToSlotCategoryError,
  fetchAssignedZonesToSlotCategorySuccess,
  fetchDaysSuccess, fetchIssueTypesSuccess,
  fetchSlotCategoryCountByZonesSuccess,
  fetchSlotCountByZoneAndIssue,
  fetchSlotCountByZoneAndIssueSuccess,
  fetchSlotZone,
  fetchSlotZoneError,
  fetchSlotZoneSuccess,
  fetchSlotZonesSuccess,
  fetchSlotsSuccess,
  removeJobTagsToSlotCategoryError,
  removeJobTagsToSlotCategorySuccess,
  searchSlotDisplayRulesError,
  searchSlotDisplayRulesSuccess,
  showAPIMessage,
  updateSlotCategoryError,
  updateSlotCategorySuccess
} from "actions/SlotZoneActions";
import {
  ADD_NEW_SLOT_CATEGORY_REQUEST,
  ADD_OR_UPDATE_DISPLAY_RULE_REQUEST,
  ADD_SLOT_ZONE, ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST, ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST, DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST, FETCH_ALL_SLOT_CATEGORIES, FETCH_ALL_SLOT_ZONE,
  FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_REQUEST,
  FETCH_DAYS, FETCH_ISSUE_TYPES,
  FETCH_SLOTS,
  FETCH_SLOT_CATEGORY_COUNT_BY_ZONE,
  FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE,
  FETCH_SLOT_ZONE,
  REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST,
  SEARCH_SLOT_DISPLAY_RULE_REQUEST,
  UPDATE_SLOT_CATEGORY_REQUEST,
  UPDATE_SLOT_ZONE
} from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios, workForceAxios } from "util/Api";


function* addSlotZoneRequest(action) {
  try {
    const response = yield call(addSlotZone, action.payload);
    yield all([put(fetchSlotCountByZoneAndIssue())]);
    if (response.success) {
      NotificationManager.success("SlotZone Added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, '', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add slotzone");
  }
}

const addSlotZone = async function (params) {
  try {
    const response = await axios.post("/slotzone/add", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateSlotZoneRequest(action) {
  try {
    const response = yield call(updateSlotZone, action.payload);
    yield all([put(fetchSlotZone(action.payload.id))]);

    if (response.success) {
      NotificationManager.success("SlotZone details updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, '', 10000);
    }

  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to update slotzone details");
  }
}

const updateSlotZone = async function (params) {
  try {
    const response = await axios.put("/slotzone/update", {
      ...params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getSlotZones = async function (params) {
  let slotzones = [];
  try {
    const response = await axios.get("/slotzone/list", { params });
    slotzones = response.data;
  } catch (error) {
    console.log(error);
  }
  return slotzones;
};

function* fetchSlotZonesRequest(action) {
  try {
    const fetchedSlotZone = yield call(getSlotZones, action.payload);
    yield put(fetchSlotZonesSuccess(fetchedSlotZone));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getIssueTypes = async function (params) {
  let issueTypes = [];
  try {
    const response = await axios.get("/slotzone/issuetypes", { params });
    issueTypes = response.data;
  } catch (error) {
    console.log(error);
  }
  return issueTypes;
};

function* fetchIssueTypesRequest(action) {
  try {
    const fetchedIssueType = yield call(getIssueTypes, action.payload);
    yield put(fetchIssueTypesSuccess(fetchedIssueType));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getDays = async function (params) {
  let days = [];
  try {
    const response = await axios.get("/slotzone/daysInWeek", { params });
    days = response.data;
  } catch (error) {
    console.log(error);
  }
  return days;
};

function* fetchDaysRequest(action) {
  try {
    const fetchedDay = yield call(getDays, action.payload);
    yield put(fetchDaysSuccess(fetchedDay));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getSlots = async function (params) {
  let slots = [];
  try {
    const response = await axios.get("/slotzone/slots", { params });
    slots = response.data;
  } catch (error) {
    console.log(error);
  }
  return slots;
};

function* fetchSlotsRequest(action) {
  try {
    const fetchedSlot = yield call(getSlots, action.payload);
    yield put(fetchSlotsSuccess(fetchedSlot));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

function* fetchSlotZoneRequest(action) {
  try {
    const slotzone = yield call(getSlotZone, {
      slotzoneId: action.payload.id,
    });
    yield put(fetchSlotZoneSuccess(slotzone));
  } catch (error) {
    yield put(fetchSlotZoneError(error.message));
  }
}

const getSlotZone = async function (params) {
  let slotzone = {};
  try {
    const response = await axios.get("/slotzone/" + params["slotzoneId"], {});
    slotzone = response.data;
  } catch (error) {
    console.log(error);
  }
  return slotzone;
};

const getSlotCountByZoneAndIssue = async function () {
  try {
    const response = await axios.get("/slotzone/slotsByZone/all");
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

const postNewSlotCategory = async function (payload) {
  const response = await axios.post("/slot/slotCategory", payload);
  return response?.data;
}

const updateSlotCategory = async function (payload) {
  const response = await axios.put("/slot/slotCategory", payload);
  return response?.data;
}

const assignJobTagsToSlotCategory = async function (payload) {
  const response = await axios.post("/slot/assignJobTags", payload);
  return response.data;
}

const removeJobTagsToSlotCategory = async function (payload) {
  const response = await axios.put("/slot/removeJobTagMapping", payload);
  return response.data;
};

export function* fetchSlotZoneByZoneAndIssueRequest(action) {
  try {
    const fetchedSlotZones = yield call(getSlotCountByZoneAndIssue);
    yield put(fetchSlotCountByZoneAndIssueSuccess(fetchedSlotZones));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

export const getSlotCountByZones = async function (params) {
  try {
    const response = await axios.get("/slot/zoneWiseCategoryAvailableSlots", { params: { zoneCodes: params } });
    return response?.data?.body;
  } catch (error) {
    console.log(error);
  }
}

export function* fetchSlotCountByZones(action) {
  try {
    const fetchedSlots = yield call(getSlotCountByZones, action.payload);
    yield put(fetchSlotCategoryCountByZonesSuccess(fetchedSlots));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

export const getAllCategories = async function (cityCode) {
  const response = await axios.get("/slot/slotCategories", { params: { cityCode } });
  return response?.data?.body;
}

export function* fetchAllCategories(action) {
  try {
    const allCategories = yield call(getAllCategories, action.payload);
    yield put(fetchAllCategoriesSuccess(allCategories));
  } catch (error) {
    NotificationManager.error(`${error}`)
    fetchAllCategoriesError(error);
    yield put(showAPIMessage(error));
  }
}

export function* addNewSlotCategory(action) {
  try {
    const response = yield call(postNewSlotCategory, action.payload);    
    if (response && response.success) {
      NotificationManager.success(response?.message + "", "", 7000);
      yield put(addNewSlotCategorySuccess(response.body));
    } else {
      NotificationManager.error(
        `Failed: ${response?.message || response || "Response is null"}`
      );
      yield put(addNewSlotCategoryError(response));
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(addNewSlotCategoryError(error));
  }
}

export function* updateSlotCategoryRequest(action) {
  try {
    const response = yield call(updateSlotCategory, action.payload);
    if (response && response.success) {
      NotificationManager.success(response?.message + "", "", 7000);
      yield put(updateSlotCategorySuccess(response.body));
    } else {
      NotificationManager.error(`${response}`);
      yield put(updateSlotCategoryError(response));
    }
  } catch (error) {
    NotificationManager.error(error + "", "", 7000);
    yield put(updateSlotCategoryError(error));
  }
}

const searchSlotDisplayRules = async params => {
  return (await workForceAxios.get("/slot/displayRules", { params })).data;
}

function* searchSlotDisplayRulesRequest(action) {
  try {
    const response = yield call(searchSlotDisplayRules, action.payload);
    if (response && response.success) {
      yield put(searchSlotDisplayRulesSuccess(response.body));
    } else {
      NotificationManager.error(`${response.message || "Failed to fetch display rules"}`);
      yield put(searchSlotDisplayRulesError(response));
    }
  } catch (error) {
    NotificationManager.error(error + "", "", 7000);
    yield put(searchSlotDisplayRulesError(error));
  }
}

const addOrUpdateDisplayRule = async body => {
  return (await workForceAxios.post("/slot/displayRule", body)).data
}

function* addOrUpdateDisplayRuleRequest(action) {
  try {
    const response = yield call(addOrUpdateDisplayRule, action.payload);
    if (response && response.success) {
      NotificationManager.success(`${response.message || "Mapping Successful!!"}`);
      yield put(addOrUpdateDisplayRuleSuccess(response.body));
    } else {
      NotificationManager.error(`${response.message || "Failed to Add/Update Display Rule"}`);
      yield put(addOrUpdateDisplayRuleError(response));
    }
  } catch (error) {
    NotificationManager.error(error + "", "", 7000);
    yield put(addOrUpdateDisplayRuleError(error));
  }
}

export function* assignJobTagsToSlotCategoryRequest(action) {
  try {
    const response = yield call(assignJobTagsToSlotCategory, action.payload);
    if (response && response.success) {
      NotificationManager.success(response?.message);
      yield put(assignJobTagsToSlotCategorySuccess({}));
    } else {
      NotificationManager.error(`${response?.message}`);
      yield put(assignJobTagsToSlotCategoryError(response));
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(assignJobTagsToSlotCategoryError(error));
  }
}

export function* removeJobTagsToSlotCategoryRequest(action) {
  try {
    const response = yield call(removeJobTagsToSlotCategory, action.payload);
    if (response && response.success) {
      NotificationManager.success(response?.message);
      yield put(removeJobTagsToSlotCategorySuccess({}));
    } else {
      NotificationManager.error(`${response?.message}`);
      yield put(removeJobTagsToSlotCategoryError(response));
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(removeJobTagsToSlotCategoryError(error));
  }
}

const fetchAssignedZonesToSLotCategory = async categoryCode => {
  return (
    await workForceAxios.get(
      `/slot/category/${(categoryCode || "").trim()}/assignedZones`
    )
  ).data;
}

function* fetchAssignedZonesToSLotCategoryRequest (action) {
  try {
    const res = yield call(fetchAssignedZonesToSLotCategory, action?.payload);
    if (res?.success) {
      yield put(fetchAssignedZonesToSlotCategorySuccess(res?.body));
    } else {
      NotificationManager.error(res?.message || "Failed to fetch assigned zones to category");
      yield put(fetchAssignedZonesToSlotCategoryError());
    }
  } catch (error) {
      NotificationManager.error(`${error}`);
      yield put(fetchAssignedZonesToSlotCategoryError());
  }
}

const assignZonesAndSlotsToSlotCategory = async body => {
  return (await workForceAxios.post("/slot/assignZoneAndSlots", body)).data;
};

function* assignZonesAndSlotsToSlotCategoryRequest(action) {
  try {
    const res = yield call(assignZonesAndSlotsToSlotCategory, action?.payload);
    if (res?.success) {
      NotificationManager.success(res?.message || "Successfully mapped the zones")
      yield put(assignZonesAndSlotsToSlotCategorySuccess());
    } else {
      NotificationManager.error(`${res?.message || "Failed to add zone mapping to slot"}`);
      yield put(assignZonesAndSlotsToSlotCategoryError());
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(assignZonesAndSlotsToSlotCategoryError());
  }
}

const disableZonesAndSlotsToSlotCategory = async body => {
  return (await (workForceAxios.put("/slot/disableZoneMapping", body))).data
}

function* disableZonesAndSlotsToSlotCategoryRequest(action) {
  try {
    const res = yield call(disableZonesAndSlotsToSlotCategory, action?.payload);
    if (res?.success) {
      NotificationManager.success(res?.message || "Successfully disabled the zones mapping")
      yield put(disableZonesAndSlotsToSlotCategorySuccess());
    } else {
      NotificationManager.error(`${res?.message || "Failed to disable zone mapping to slot"}`);
      yield put(assignZonesAndSlotsToSlotCategoryError());
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(disableZonesAndSlotsToSlotCategoryError());
  }
}

export function* fetchSlotZonesData() {
  yield takeLatest(FETCH_ALL_SLOT_ZONE, fetchSlotZonesRequest);
  yield takeLatest(FETCH_SLOT_ZONE, fetchSlotZoneRequest);
  yield takeLatest(ADD_SLOT_ZONE, addSlotZoneRequest);
  yield takeLatest(UPDATE_SLOT_ZONE, updateSlotZoneRequest);
  yield takeLatest(FETCH_ISSUE_TYPES, fetchIssueTypesRequest);
  yield takeLatest(FETCH_DAYS, fetchDaysRequest);
  yield takeLatest(FETCH_SLOTS, fetchSlotsRequest);
  yield takeLatest(FETCH_SLOTS, fetchSlotsRequest);
  yield takeLatest(
    FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE,
    fetchSlotZoneByZoneAndIssueRequest
  );
  yield takeLatest(FETCH_SLOT_CATEGORY_COUNT_BY_ZONE, fetchSlotCountByZones);
  yield takeLatest(FETCH_ALL_SLOT_CATEGORIES, fetchAllCategories)
  yield takeLatest(ADD_NEW_SLOT_CATEGORY_REQUEST, addNewSlotCategory);
  yield takeLatest(UPDATE_SLOT_CATEGORY_REQUEST, updateSlotCategoryRequest);
  yield takeLatest(SEARCH_SLOT_DISPLAY_RULE_REQUEST, searchSlotDisplayRulesRequest);
  yield takeLatest(ADD_OR_UPDATE_DISPLAY_RULE_REQUEST, addOrUpdateDisplayRuleRequest);

  yield takeLatest(ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST, assignJobTagsToSlotCategoryRequest);
  yield takeLatest(REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST, removeJobTagsToSlotCategoryRequest);
  yield takeLatest(FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_REQUEST, fetchAssignedZonesToSLotCategoryRequest);
  yield takeLatest(ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST, assignZonesAndSlotsToSlotCategoryRequest);
  yield takeLatest(DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST, disableZonesAndSlotsToSlotCategoryRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchSlotZonesData)]);
}

import { fetchNewTechniciansSuccess, showAPIMessage } from "actions/TechniciansAction";
import { FETCH_NEW_TECHNICIANS } from "constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios } from "util/Api";

const getNewTechnicians = async function (params) {
  let deliveryPersons = [];
  if (!params.active || params.active == "false") {
    delete params.active;
  }
  try {
    const response = await axios.get("/employee/master/list?role=Technician", { params });
    deliveryPersons = response.data;
  } catch (error) {
    console.log(error);
  }
  return deliveryPersons;
};

function* fetchNewTechniciansRequest(action) {
  try {
    const fetchedNewEmployee = yield call(getNewTechnicians, action.payload);
    yield put(fetchNewTechniciansSuccess(fetchedNewEmployee));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

export function* fetchNewTechiniciansData() {
  yield takeLatest(FETCH_NEW_TECHNICIANS, fetchNewTechniciansRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchNewTechiniciansData)]);
}
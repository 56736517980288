import {
  clearCpmCacheError,
  clearCpmCacheSuccess,
  clearDeviceModelsCacheError,
  clearDeviceModelsCacheSuccess,
  createChangeModelDRTJobError,
  createChangeModelDRTJobSuccess,
  errorCreateCPMMapping,
  errorInvalidateCPMMappingForNewDelivery,
  fetchCouponError,
  fetchCouponSuccess,
  fetchDeviceError, fetchDeviceModelOfDeviceError, fetchDeviceModelOfDeviceSuccess, fetchDevicesSuccess,

  fetchDeviceSuccess,
  fetchPlanSuccess, fetchValidDeviceModelsForCityErr, fetchValidDeviceModelsForCitySuc, fetchValidPlansTransForCityErr, fetchValidPlansTransForCitySuc, searchAllCPMMappingError, searchAllCPMMappingSuccess, searchAllInvalidatedCPMMappingError, searchAllInvalidatedCPMMappingSuccess, searchCityPlanModelMappingsError, searchCityPlanModelMappingsSuccess,
  showAPIMessage,
  successCreateCPMMapping,
  successInvalidateCPMMappingForNewDelivery,
  updateDeviceModelNamesError,
  updateDeviceModelNamesSuccess,
  validateCPMMapingError,
  validateCPMMapingSuccess
} from "actions/DeviceActions";
import {
  ACTIVATE_DEVICE,


  APPLY_PLAN_FOR_INSTALLATION, CLEAR_CACHE_CPM_MAPPING_REQUEST, CLEAR_DEVICE_MODEL_CACHE_REQUEST, CREATE_CHANGE_MODEL_DRT_JOB_REQUEST, FETCH_ALL_DEVICE,

  FETCH_COUPON_REQUEST,

  FETCH_DEVICE,
  FETCH_DEVICE_MODEL_OF_DEVICE_REQUEST,
  FETCH_PLAN,
  FETCH_VALID_DEVICE_MODELS_FOR_CITY_REQ,
  FETCH_VALID_PLANS_TRANS_FOR_CITY_REQ,
  REQUEST_CREATE_CPM_MAPPING,
  REQUEST_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY,
  SEARCH_ALL_CPM_MAPPING_REQUEST,
  SEARCH_ALL_INVALIDATED_CPM_MAPPING_REQUEST,
  SEARCH_CITY_PLAN_MODEL_MAPPINGS_REQUEST,
  UPDATE_DEVICE_MODEL_NAMES_REQUEST,
  VALIDATE_CPM_MAPPING_REQUEST
} from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios, paymentAxios, workForceAxios } from "util/Api";



const getDevices = async function (params) {
  let devices = [];
  try {
    const response = await axios.get("/device/list", { params });
    devices = response.data;
  } catch (error) {
    console.log(error);
  }
  return devices;
};

function* fetchDevicesRequest(action) {
  try {
    const fetchedDevice = yield call(getDevices, action.payload);
    yield put(fetchDevicesSuccess(fetchedDevice));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const activateDevice = async function (params) {
  try {
    // const response1 = await paymentAxios.post("/utils/plans/change", params);
    // if (response1.data.success) {
    const response = await axios.post("/device/code/" + params["deviceCode"] + "/activate");
    return response.data;
    // }
  } catch (error) {
    throw error;
  }
};

function* activateDeviceRequest(action) {
  try {
    const response = yield call(activateDevice, action.payload);

    if (response.success) {
      NotificationManager.success("Activate Device successfully");
    } else {
      NotificationManager.error("Failed: " + response.message,'', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to activate device details");
  }
}
const applyPlan = async function (params) {
  let plan = {}
  try {
    const response = await paymentAxios.post("/payments/activate", params);
    plan = response.data;
    console.log(plan);
  } catch (error) {
    console.log(error);
    throw error;
  }
  return plan;
};

function* applyPlanRequest(action) {
  try {
    const response = yield call(applyPlan, action.payload);

    if (response.success) {
      NotificationManager.success("Plan applied successfully");
      // this.props.fetchPlansByInstallationId(this.props.installationId);
    } else {
      NotificationManager.error("Failed: " + response.message,'', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to apply plan");
  }
}

function* fetchDeviceRequest(action) {
  try {
    const device = yield call(getDevice, {
      deviceId: action.payload.id,
    });
    yield put(fetchDeviceSuccess(device));
  } catch (error) {
    yield put(fetchDeviceError(error.message));
  }
}

const getDevice = async function (params) {
  let devices = [];
  try {
    const response = await axios.get("/device/" + params["deviceId"], {});
    devices = response.data;
  } catch (error) {
    console.log(error);
  }
  return devices;
};

function* fetchPlanRequest(action) {
  try {
    const device = yield call(getPlan);
    yield put(fetchPlanSuccess(device));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}

const getPlan = async function () {
  let plans = [];
  try {
    const response = await paymentAxios.get("/utils/plans/all");
    plans = response.data;
  } catch (error) {
    console.log(error);
  }
  return plans;
};

const getCoupon = async function(){
  let coupon ={};
  try{
    const response = await paymentAxios.get("discount/coupon");
    coupon = response.data;
  }
  catch(error){
    console.log(error);
    throw error;
  }
  return coupon;
}
function* fetchCouponRequest(action) {
  try {
    const coupon = yield call(getCoupon);
    yield put(fetchCouponSuccess(coupon));
  } catch (error) {
    yield put(fetchCouponError(error));
  }
}

const searchCityPlanModelMappings = async params => {
  return (
    await workForceAxios.get("/device/cityPlanModelMappings", {
      params,
    })
  ).data;
}

function* searchCityPlanModelMappingsRequest(action) {
  try {
    const res = yield call(searchCityPlanModelMappings, action.payload);
    if (res?.success) {
      yield put(searchCityPlanModelMappingsSuccess(res?.body || []));
    } else {
      yield put(searchCityPlanModelMappingsError());
      NotificationManager.error(
        `${res?.message || "Failed to fetch city-plan-model mappings"}`
      );
    }
  } catch (error) {
    yield put(searchCityPlanModelMappingsError());  
    NotificationManager.error(`${error}`)
  }
}

const fetchValidPlansTransForCity = async params => {
  return (
    await workForceAxios.get("/device/validPlans/cities", {
      params,
    })
  ).data;
}
function* fetchValidPlansTransForCityReq(action) {
   try {
    const res = yield call(fetchValidPlansTransForCity, action.payload);
    if (res?.success) {
      yield put(fetchValidPlansTransForCitySuc(res?.body || []));
    } else {
      yield put(fetchValidPlansTransForCityErr());
      NotificationManager.error(
        `${res?.message || "Failed to fetch valid plans for city"}`
      );
    }
  } catch (error) {
    yield put(fetchValidPlansTransForCityErr());  
    NotificationManager.error(`${error}`)
  }
}

const fetchValidDeviceModelsForCity = async params => {
  return (await workForceAxios.get("/device/validModels/cities", { params }))
    .data;
};

function* fetchValidDeviceModelsForCityReq(action) {
  try {
    const res = yield call(fetchValidDeviceModelsForCity, action?.payload);
    if (res?.success) {
      yield put(fetchValidDeviceModelsForCitySuc(res?.body));
    } else {
      yield put(fetchValidDeviceModelsForCityErr());
      NotificationManager.error(
        `${res?.message || "Failed to fetch valid device models for city"}`
      );
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(fetchValidDeviceModelsForCityErr());
  }
}

const createCPMMapping = async createRequest => {
  return (
    await workForceAxios.post(
      "/device/create/cityPlanModelMapping",
      createRequest
    )
  ).data;
}
function* createCPMMappingRequest(action) {
try {
    const res = yield call(createCPMMapping, action?.payload);
    if (res?.success) {
      NotificationManager.success(
        `${res?.message || "Created CPM Mapping(s)"}`
      );
      yield put(successCreateCPMMapping(res?.body));
    } else {
      NotificationManager.error(
        `${res?.message || "Failed to create CPM mapping(s)"}`
      );
      yield put(errorCreateCPMMapping());
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(errorCreateCPMMapping());
  }
}

const invalidateCPMMappingsForNewDelivery = async invalidateRequest => {
  return (
    await workForceAxios.post(
      "/device/invalidate/cityPlanModelMapping/forNewDelivery",
      invalidateRequest
    )
  ).data;
}

function* invalidateCPMMappingsForNewDeliveryRequest(action) {
  try {
    const res = yield call(invalidateCPMMappingsForNewDelivery, action?.payload);
    if (res?.success) {
      NotificationManager.success(
        `${res?.message || "Invalidated CPM Mapping(s) for new deliveries"}`
      );
      yield put(successInvalidateCPMMappingForNewDelivery(res?.body));
    } else {
      NotificationManager.error(
        `${res?.message || "Failed to invalidate CPM mapping(s)"}`
      );
      yield put(errorInvalidateCPMMappingForNewDelivery());
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(errorInvalidateCPMMappingForNewDelivery());
  }
}

const validateCPMMapping = async validateRequest => {
  return (
    await workForceAxios.post(
      "/device/validate/cityPlanModelMapping/forNewDelivery",
      validateRequest
    )
  ).data;
}

function* validateCPMMappingRequest(action) {
  try {
    const res = yield call(validateCPMMapping, action?.payload);
    if (res?.success) {
      yield put(validateCPMMapingSuccess(res?.body || []));
      NotificationManager.success(
        `${res?.message || "Validated CPM Mapping(s) for new deliveries"}`
      );
    } else {
      NotificationManager.error(
        `${res?.message || "Failed to validate CPM mapping(s)"}`
      );
      yield put(validateCPMMapingError());
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
      yield put(validateCPMMapingError());
  }
}

const searchAllCPMMapping = async params => {
  return (
    await workForceAxios.get("/device/cityPlanModelMappings/all", {
      params,
    })
  ).data;
}

function* searchAllCPMMappingRequest(action) {
  try {
    const res = yield call(searchAllCPMMapping, action?.payload);
    if (res?.success) {
      yield put(searchAllCPMMappingSuccess(res?.body || []));
    } else {
      NotificationManager.error(
        `${res?.message || "Failed to search through all CPM mappings"}`
      );
      yield put(searchAllCPMMappingError());
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
      yield put(searchAllCPMMappingError());
  }
}

function* searchAllInvalidatedCPMMappingRequest(action) {
  try {
    const res = yield call(searchAllCPMMapping, { ...(action?.payload || {}), alsoValidForNewDelivery: false});
    if (res?.success) {
      yield put(searchAllInvalidatedCPMMappingSuccess(res?.body || []));
    } else {
      NotificationManager.error(
        `${res?.message || "Failed to search through all CPM mappings"}`
      );
      yield put(searchAllInvalidatedCPMMappingError());
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(searchAllInvalidatedCPMMappingError());
  }
}

const updateDeviceModelNames = async renameRequest => {
  return (await workForceAxios.put("/device/model/rename", renameRequest))
    .data;
}

function* updateDeviceModelNamesRequest(action) {
  try {
    const res = yield call(updateDeviceModelNames, action?.payload);
    if (res?.success) {
      NotificationManager.success(`${res?.message || "Successfully updated device model names"}`)
      yield put(updateDeviceModelNamesSuccess());
    } else {
      NotificationManager.error(`${res?.message || "Failed to update device model names"}`)
      yield put(updateDeviceModelNamesError());
    }
  } catch (error) {
    NotificationManager.error(`${error}`)
    yield put(updateDeviceModelNamesError());
  }
}

const fetchDeviceModelOfDevice = async deviceCode => {
  return (await workForceAxios.get(`/device/${deviceCode}/model`)).data;
}

function* fetchDeviceModelOfDeviceRequest(action) {
  try {
    const res = yield call(fetchDeviceModelOfDevice, action?.payload);
    if (res?.success) {
      yield put(fetchDeviceModelOfDeviceSuccess(res?.body));
    } else {
      NotificationManager.error(`${res?.message || "Failed to fetch device model"}`);
      yield put(fetchDeviceModelOfDeviceError());
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(fetchDeviceModelOfDeviceError());
  }
}

const createChangeModelDRTJob = async body => {
  return (await workForceAxios.post("/workforce/createDRTForChangeModel", body))
    .data;
};

function* createChangeModelDRTJobRequest(action) {
  try {
    const res = yield call(createChangeModelDRTJob, action?.payload);
    if (res?.success) {
      NotificationManager.success("Created the Device Model Replacement job");
      yield put(createChangeModelDRTJobSuccess());
    } else {
      NotificationManager.error(`${res?.message || "Failed to create Device Model Replacement job"}`);
      yield put(createChangeModelDRTJobError());
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(createChangeModelDRTJobError());
  }
}

const clearCacheCPMMapping = async () => {
  return (await workForceAxios.get("/device/cache/clearAll/cityPlanModelMappings")).data;
}

function* clearCacheCPMMappingRequest(action) {
  try {
    const response = yield call(clearCacheCPMMapping, action?.payload);
    if (response?.success) {
      NotificationManager.success(
        `Successfully cleared City-Plan-Model cache!`
      );
      yield put(clearCpmCacheSuccess());
    } else {
      NotificationManager.error(
        `Failed to clear City-Plan-Model cache! Reachout tech for support`
      );
      yield put(clearCpmCacheError());
    }
  } catch (error) {
    NotificationManager.error(
      `Failed to clear City-Plan-Model cache! Reachout tech for support`
    );
    yield put(clearCpmCacheError());
  }
}

const clearDeviceModelsCache = async () => {
  return (await workForceAxios.get("/device/model/cache/clear-all")).data;
};

function* clearDeviceModelsCacheRequest(action) {
  try {
    const response = yield call(clearDeviceModelsCache, action?.payload);
    if (response?.success) {
      NotificationManager.success(
        `Successfully cleared Device Models cache!`
      );
      yield put(clearDeviceModelsCacheSuccess());
    } else {
      NotificationManager.error(
        `Failed to clear Device Models cache! Reachout tech for support`
      );
      yield put(clearDeviceModelsCacheError());
    }
  } catch (error) {
    NotificationManager.error(
      `Failed to clear Device Models cache! Reachout tech for support`
    );
    yield put(clearDeviceModelsCacheError());
  }
}

export function* fetchDevicesData() {
  yield takeLatest(FETCH_ALL_DEVICE, fetchDevicesRequest);
  yield takeLatest(ACTIVATE_DEVICE, activateDeviceRequest);
  yield takeLatest(FETCH_DEVICE, fetchDeviceRequest);
  yield takeLatest(FETCH_PLAN, fetchPlanRequest);
  yield takeLatest(APPLY_PLAN_FOR_INSTALLATION, applyPlanRequest);
  yield takeLatest(FETCH_COUPON_REQUEST, fetchCouponRequest);
  yield takeLatest(SEARCH_CITY_PLAN_MODEL_MAPPINGS_REQUEST, searchCityPlanModelMappingsRequest);
  yield takeLatest(FETCH_VALID_PLANS_TRANS_FOR_CITY_REQ, fetchValidPlansTransForCityReq);
  yield takeLatest(FETCH_VALID_DEVICE_MODELS_FOR_CITY_REQ, fetchValidDeviceModelsForCityReq);
  yield takeLatest(REQUEST_CREATE_CPM_MAPPING, createCPMMappingRequest);
  yield takeLatest(REQUEST_INVALIDATE_CPM_MAPPING_FOR_NEW_DELIVERY, invalidateCPMMappingsForNewDeliveryRequest);
  yield takeLatest(VALIDATE_CPM_MAPPING_REQUEST, validateCPMMappingRequest);
  yield takeLatest(SEARCH_ALL_CPM_MAPPING_REQUEST, searchAllCPMMappingRequest);
  yield takeLatest(SEARCH_ALL_INVALIDATED_CPM_MAPPING_REQUEST, searchAllInvalidatedCPMMappingRequest)
  yield takeLatest(UPDATE_DEVICE_MODEL_NAMES_REQUEST, updateDeviceModelNamesRequest);
  yield takeLatest(FETCH_DEVICE_MODEL_OF_DEVICE_REQUEST, fetchDeviceModelOfDeviceRequest);
  yield takeLatest(CREATE_CHANGE_MODEL_DRT_JOB_REQUEST, createChangeModelDRTJobRequest);
  yield takeLatest(CLEAR_CACHE_CPM_MAPPING_REQUEST, clearCacheCPMMappingRequest)
  yield takeLatest(CLEAR_DEVICE_MODEL_CACHE_REQUEST, clearDeviceModelsCacheRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchDevicesData)]);
}

import { FETCH_DBUI_FORM_ERROR, FETCH_DBUI_FORM_REQUEST, FETCH_DBUI_FORM_SUCCESS } from "constants/ActionTypes";

export const fetchDbUiFormRequest = payload => {
  return {
    type: FETCH_DBUI_FORM_REQUEST,
    payload,
  };
};

export const fetchDbUiFormSuccess = payload => {
  return {
    type: FETCH_DBUI_FORM_SUCCESS,
    payload,
  };
};

export const fetchDbUiFormError = payload => {
  return {
    type: FETCH_DBUI_FORM_ERROR,
    payload,
  };
};

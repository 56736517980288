import {
  ADD_EARNING_ADHOC,
  ADD_EARNING_CONFIG,
  ADD_EARNING_SCHEME,
  ADD_EMPLOYEE,
  ADD_LEAVE,
  ADD_LEAVE_SUCCESS,
  ADD_LEAVE_ERROR,
  ADD_EMPLOYEE_ERROR,
  ADD_EMPLOYEE_SUCCESS,
  FETCH_ALL_CONFIG_FOR_A_SCHEME,
  FETCH_ALL_CONFIG_FOR_A_SCHEME_SUCCESS,
  FETCH_ALL_EMPLOYEE,
  FETCH_ALL_EMPLOYEE_SUCCESS,
  FETCH_ALL_LEAVES,
  FETCH_ALL_LEAVES_ERROR,
  FETCH_ALL_LEAVES_SUCCESS,
  FETCH_ALL_PERMISSIONS,
  FETCH_ALL_PERMISSIONS_SUCCESS,
  FETCH_ALL_ROLES,
  FETCH_ALL_ROLES_SUCCESS,
  FETCH_ALL_SCHEMES,
  FETCH_ALL_SCHEMES_SUCCESS,
  FETCH_CONFIG_BY_ID,
  FETCH_CONFIG_BY_ID_SUCCESS,
  FETCH_DESTINATION_WAREHOUSE,
  FETCH_DESTINATION_WAREHOUSE_SUCCESS,
  FETCH_EMPLOYEE,
  FETCH_EMPLOYEES_LEAVE,
  FETCH_EMPLOYEES_LEAVE_ERROR,
  FETCH_EMPLOYEES_LEAVE_SUCCESS,
  FETCH_EMPLOYEE_ERROR,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_PASSBOOK_ENTRIES,
  FETCH_PASSBOOK_ENTRIES_SUCCESS,
  FETCH_SOURCE_WAREHOUSE,
  FETCH_SOURCE_WAREHOUSE_SUCCESS,
  FETCH_TRANSIT_WAREHOUSE,
  FETCH_TRANSIT_WAREHOUSE_SUCCESS,
  SHOW_API_MESSAGE,
  UPDATE_EARNING_CONFIG,
  UPDATE_LEAVE,
  UPDATE_EMPLOYEE,
  UPLOAD_EMPLOYEE_PROFILE_PICTURE_REQUEST,
  UPLOAD_EMPLOYEE_PROFILE_PICTURE_SUCCESS,
  UPLOAD_EMPLOYEE_PROFILE_PICTURE_ERROR,
  REMOVE_EMPLOYEE_PROFILE_PICTURE_REQUEST,
  REMOVE_EMPLOYEE_PROFILE_PICTURE_SUCCESS,
  REMOVE_EMPLOYEE_PROFILE_PICTURE_ERROR,
} from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";

export const fetchEmployee = id => {
  return {
    type: FETCH_EMPLOYEE,
    payload: { id },
  };
};
export const fetchSourceWarehouse = val => {
  return {
    type: FETCH_SOURCE_WAREHOUSE,
    payload: {
      val: val,
    },
  };
};
export const fetchDestinationWarehouse = val => {
  return {
    type: FETCH_DESTINATION_WAREHOUSE,
    payload: {
      val: val,
    },
  };
};
export const fetchTransitWarehouse = val => {
  return {
    type: FETCH_TRANSIT_WAREHOUSE,
    payload: {
      val: val,
    },
  };
};
export const fetchEmployeesLeave = params => {
  return {
    type: FETCH_EMPLOYEES_LEAVE,
    payload: { params },
  };
};

export const fetchAllLeaves = params => {
  return {
    type: FETCH_ALL_LEAVES,
    payload: { params },
  };
};

export const fetchEmployees = payload => {
  return {
    type: FETCH_ALL_EMPLOYEE,
    payload,
  };
};

export const fetchAllSchemes = payload => {
  return {
    type: FETCH_ALL_SCHEMES,
    payload,
  };
};

export const fetchAllConfigForAScheme = id => {
  return {
    type: FETCH_ALL_CONFIG_FOR_A_SCHEME,
    payload: id,
  };
};
export const fetchConfigById = id => {
  return {
    type: FETCH_CONFIG_BY_ID,
    payload: { id },
  };
};
export const fetchPassbookEntries = params => {
  return {
    type: FETCH_PASSBOOK_ENTRIES,
    payload: params,
  };
};
export const addScheme = scheme => {
  return {
    type: ADD_EARNING_SCHEME,
    payload: scheme,
  };
};
export const addLeave = leave => {
  return {
    type: ADD_LEAVE,
    payload: leave,
  };
};

export const addConfig = config => {
  return {
    type: ADD_EARNING_CONFIG,
    payload: config,
  };
};
export const updateConfig = config => {
  return {
    type: UPDATE_EARNING_CONFIG,
    payload: config,
  };
};
export const updateLeave = leave => {
  return {
    type: UPDATE_LEAVE,
    payload: leave,
  };
};
export const addAdhocPayout = params => {
  return {
    type: ADD_EARNING_ADHOC,
    payload: params,
  };
};
export const addEmployee = employee => {
  return {
    type: ADD_EMPLOYEE,
    payload: employee,
  };
};

export const updateEmployee = employee => {
  return {
    type: UPDATE_EMPLOYEE,
    payload: employee,
  };
};

export const fetchEmployeeSuccess = employee => {
  return {
    type: FETCH_EMPLOYEE_SUCCESS,
    payload: {
      uiState: SUCCESS,
      employee: employee,
    },
  };
};
export const fetchAllLeavesSuccess = leaves => {
  return {
    type: FETCH_ALL_LEAVES_SUCCESS,
    payload: {
      uiState: SUCCESS,
      leaves: leaves,
    },
  };
};

export const fetchSchemeSuccess = schemes => {
  return {
    type: FETCH_ALL_SCHEMES_SUCCESS,
    payload: {
      uiState: SUCCESS,
      schemes: schemes,
    },
  };
};

export const fetchPassbookEntriesSuccess = entries => {
  return {
    type: FETCH_PASSBOOK_ENTRIES_SUCCESS,
    payload: {
      entries: entries,
    },
  };
};
export const fetchAllConfigSuccess = configs => {
  return {
    type: FETCH_ALL_CONFIG_FOR_A_SCHEME_SUCCESS,
    payload: {
      uiState: SUCCESS,
      configs: configs,
    },
  };
};
export const fetchConfigByIdSuccess = config => {
  return {
    type: FETCH_CONFIG_BY_ID_SUCCESS,
    payload: {
      uiState: SUCCESS,
      config: config,
    },
  };
};
export const fetchDestinationWarehousesSuccess = destinationWarehouse => {
  return {
    type: FETCH_DESTINATION_WAREHOUSE_SUCCESS,
    payload: destinationWarehouse,
  };
};
export const fetchTransitWarehousesSuccess = transitWarehouse => {
  return {
    type: FETCH_TRANSIT_WAREHOUSE_SUCCESS,
    payload: transitWarehouse,
  };
};
export const fetchSourceWarehousesSuccess = sourceWarehouse => {
  return {
    type: FETCH_SOURCE_WAREHOUSE_SUCCESS,
    payload: sourceWarehouse,
  };
};
export const fetchEmployeeError = error => {
  return {
    type: FETCH_EMPLOYEE_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const fetchEmployeesLeaveSuccess = employeesLeave => {
  return {
    type: FETCH_EMPLOYEES_LEAVE_SUCCESS,
    payload: {
      uiState: SUCCESS,
      employeesLeave: employeesLeave,
    },
  };
};

export const fetchEmployeesLeaveError = error => {
  return {
    type: FETCH_EMPLOYEES_LEAVE_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const fetchAllLeavesError = error => {
  return {
    type: FETCH_ALL_LEAVES_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};
export const addLeaveSuccess = message => {
  return {
    type: ADD_LEAVE_SUCCESS,
    payload: {
      type: "success",
      status: "",
      showNotification: true,
      message,
    },
  };
};
export const addLeaveError = error => {
  return {
    type: ADD_LEAVE_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};
export const addEmployeeSuccess = message => {
  return {
    type: ADD_EMPLOYEE_SUCCESS,
    payload: {
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const addEmployeeError = error => {
  return {
    type: ADD_EMPLOYEE_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const fetchEmployeesSuccess = employee => {
  return {
    type: FETCH_ALL_EMPLOYEE_SUCCESS,
    payload: employee,
  };
};

export const fetchAllRoles = payload => {
  return {
    type: FETCH_ALL_ROLES,
    payload,
  };
};

export const fetchAllPermissions = payload => {
  return {
    type: FETCH_ALL_PERMISSIONS,
    payload,
  };
};
export const fetchAllPermissionsSuccess = permissions => {
  return {
    type: FETCH_ALL_PERMISSIONS_SUCCESS,
    payload: permissions,
  };
};
export const fetchAllRolesSuccess = roles => {
  return {
    type: FETCH_ALL_ROLES_SUCCESS,
    payload: roles,
  };
};

export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};

export const uploadEmployeeProfilePictureRequest = payload => {
  return {
    type: UPLOAD_EMPLOYEE_PROFILE_PICTURE_REQUEST,
    payload,
  }
}

export const uploadEmployeeProfilePictureSuccess = payload => {
  return {
    type: UPLOAD_EMPLOYEE_PROFILE_PICTURE_SUCCESS,
    payload,
  };
};

export const uploadEmployeeProfilePictureError = payload => {
  return {
    type: UPLOAD_EMPLOYEE_PROFILE_PICTURE_ERROR,
    payload,
  };
};

export const removeEmployeeProfilePictureRequest = payload => {
  return {
    type: REMOVE_EMPLOYEE_PROFILE_PICTURE_REQUEST,
    payload,
  }
}

export const removeEmployeeProfilePictureSuccess = payload => {
  return {
    type: REMOVE_EMPLOYEE_PROFILE_PICTURE_SUCCESS,
    payload,
  };
};

export const removeEmployeeProfilePictureError = payload => {
  return {
    type: REMOVE_EMPLOYEE_PROFILE_PICTURE_ERROR,
    payload,
  };
};
import {
  FETCH_ALL_TECHNICIANS,
  FETCH_ALL_TECHNICIANS_SUCCESS,
  SHOW_API_MESSAGE,
} from "constants/ActionTypes";
  
  const INIT_STATE = {
    technicians: [],
  };

  export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_TECHNICIANS: {
      return {
        ...state,
        allTechnicians: [],
        technicians: [],
        totalCount: 0,
        loader: true,
      };
    }
    case FETCH_ALL_TECHNICIANS_SUCCESS: {
      return {
        ...state,
        allTechnicians: action.payload,
        technicians: action.payload.employees,
        totalCount: action.payload.total,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }

    default:
      return state;
  }
  };
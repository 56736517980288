import {
  ADD_AREA,
  ADD_AREA_ERROR,
  ADD_AREA_SUCCESS,
  FETCH_ALL_AREA,
  FETCH_ALL_AREA_SUCCESS,
  FETCH_ALL_CITIES,
  FETCH_ALL_CITIES_ERROR,
  FETCH_ALL_CITIES_SUCCESS,
  FETCH_AREA,
  FETCH_AREA_ERROR,
  FETCH_AREA_SUCCESS,
  FETCH_CITIES,
  FETCH_CITIES_SUCCESS,
  FETCH_STATES,
  FETCH_STATES_SUCCESS,
  FETCH_STATUSES,
  FETCH_STATUSES_SUCCESS,
  FETCH_ZONES,
  FETCH_ZONES_SUCCESS,
  FETCH_ZONES_BY_CITY_CODE_ERROR,
  FETCH_ZONES_BY_CITY_CODE_REQUEST,
  FETCH_ZONES_BY_CITY_CODE_SUCCESS,
  SHOW_API_MESSAGE,
  UPDATE_AREA,
} from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";

export const fetchArea = id => {
  return {
    type: FETCH_AREA,
    payload: { id },
  };
};

export const fetchAreas = payload => {
  return {
    type: FETCH_ALL_AREA,
    payload,
  };
};

export const fetchStates = payload => {
  return {
    type: FETCH_STATES,
    payload,
  };
};

export const fetchCities = payload => {
  return {
    type: FETCH_CITIES,
    payload,
  };
};

export const fetchAllCities = payload => {
  return {
    type: FETCH_ALL_CITIES,
    payload,
  };
};

export const fetchStatuses = payload => {
  return {
    type: FETCH_STATUSES,
    payload,
  };
};

export const fetchzones = payload => {
  return {
    type: FETCH_ZONES,
    payload,
  };
};

export const fetchZonesByCityCodeRequest = payload => {
  return {
    type: FETCH_ZONES_BY_CITY_CODE_REQUEST,
    payload,
  };
};

export const fetchZonesByCityCodeSuccess = payload => {
  return {
    type: FETCH_ZONES_BY_CITY_CODE_SUCCESS,
    payload,
  };
};

export const fetchZonesByCityCodeError = payload => {
  return {
    type: FETCH_ZONES_BY_CITY_CODE_ERROR,
    payload,
  };
};

export const addArea = area => {
  return {
    type: ADD_AREA,
    payload: area,
  };
};

export const updateArea = area => {
  return {
    type: UPDATE_AREA,
    payload: area,
  };
};

export const fetchAreaSuccess = area => {
  return {
    type: FETCH_AREA_SUCCESS,
    payload: {
      uiState: SUCCESS,
      area: area,
    },
  };
};

export const fetchAreaError = error => {
  return {
    type: FETCH_AREA_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const addAreaSuccess = message => {
  return {
    type: ADD_AREA_SUCCESS,
    payload: {
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const addAreaError = error => {
  return {
    type: ADD_AREA_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const fetchAreasSuccess = area => {
  return {
    type: FETCH_ALL_AREA_SUCCESS,
    payload: area,
  };
};

export const fetchStatesSuccess = states => {
  return {
    type: FETCH_STATES_SUCCESS,
    payload: states,
  };
};

export const fetchCitiesSuccess = cities => {
  return {
    type: FETCH_CITIES_SUCCESS,
    payload: cities,
  };
};

export const fetchAllCitiesSuccess = cities => {
  return {
    type: FETCH_ALL_CITIES_SUCCESS,
    payload: cities,
  };
};

export const fetchAllCitiesError = payload => {
  return {
    type: FETCH_ALL_CITIES_ERROR,
    payload,
  };
};

export const fetchZonesSuccess = zones => {
  return {
    type: FETCH_ZONES_SUCCESS,
    payload: zones,
  };
};

export const fetchStatusesSuccess = statuses => {
  return {
    type: FETCH_STATUSES_SUCCESS,
    payload: statuses,
  };
};

export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};

import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_ERROR,
  ADD_CUSTOMER_SUCCESS,
  DEVICE_REPLACEMENT,
  FETCH_ALL_CUSTOMER,
  FETCH_ALL_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_ADDRESS_ERROR,
  FETCH_CUSTOMER_ADDRESS_SUCCESS,
  FETCH_CUSTOMER_ERROR,
  FETCH_CUSTOMER_PLANS_REQUEST,
  FETCH_CUSTOMER_PLANS_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_INSTALLATION_STATUS,
  FETCH_INSTALLATION_STATUS_ERROR,
  FETCH_INSTALLATION_STATUS_SUCCESS,
  FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_ERROR,
  FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_REQUEST,
  FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_SUCCESS,
  FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_ERROR,
  FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_REQUEST,
  FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_SUCCESS,
  FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_ERROR,
  FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_REQUEST,
  FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_SUCCESS,
  FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_ERROR,
  FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_REQUEST,
  FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_SUCCESS,
  FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_ERROR,
  FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_REQUEST,
  FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_SUCCESS,
  FETCH_PHP_DAILY_INSTALLATIONS_DETAILS,
  FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_SUCCESS,
  FETCH_PHP_MONTHLY_INSTALLATIONS,
  FETCH_PHP_MONTHLY_INSTALLATIONS_SUCCESS,
  FETCH_PHP_MONTHLY_STATS,
  FETCH_PHP_MONTHLY_STATS_SUCCESS,
  FETCH_PHP_PURIFIER_VALIDITY_DETAILS,
  FETCH_PHP_PURIFIER_VALIDITY_DETAILS_SUCCESS,
  FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS,
  FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_SUCCESS,
  FETCH_REWARDS,
  FETCH_REWARDS_SUCCESS,
  MOVE_TO_UNINSTALL,
  REQUEST_NEW_PRIME,
  SHOW_API_MESSAGE,
  UPDATE_ADDRESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_BOT,
  UPDATE_CUSTOMER_NONPAYING,
  UPDATE_CUSTOMER_PRIORITY,
  UPDATE_CUSTOMER_STATUS,
  UPDATE_CUSTOMER_STATUS_ERROR,
  UPDATE_CUSTOMER_STATUS_SUCCESS
} from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";

export const fetchCustomer = id => {
  return {
    type: FETCH_CUSTOMER,
    payload: { id },
  };
};
export const fetchCustomerAddress = id => {
  return {
    type: FETCH_CUSTOMER_ADDRESS,
    payload: { id },
  };
};
export const fetchCustomers = payload => {
  return {
    type: FETCH_ALL_CUSTOMER,
    payload,
  };
};

export const addCustomer = customer => {
  return {
    type: ADD_CUSTOMER,
    payload: customer,
  };
};

export const updateCustomer = customer => {
  return {
    type: UPDATE_CUSTOMER,
    payload: customer,
  };
};
export const updateAddress = (id, installationId, payload) => {
  return {
    type: UPDATE_ADDRESS,
    payload: {
      id: id,
      installationId: installationId,
      params: payload,
    },
  };
};
export const moveToUninstalled = ({ id, formData }) => {
  return {
    type: MOVE_TO_UNINSTALL,
    payload: {
      id: id,
      body: formData,
    },
  };
};
export const requestNewDevice = (installationId, payload) => {
  return {
    type: REQUEST_NEW_PRIME,
    payload: {
      installationId: installationId,
      params: payload,
    },
  };
};

export const fetchCustomerAddressSuccess = address => {
  return {
    type: FETCH_CUSTOMER_ADDRESS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      address: address,
    },
  };
};

export const fetchCustomerAddressError = error => {
  return {
    type: FETCH_CUSTOMER_ADDRESS_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};
export const fetchCustomersSuccess = cust => {
  return {
    type: FETCH_ALL_CUSTOMER_SUCCESS,
    payload: cust,
  };
};

export const fetchCustomerSuccess = customer => {
  return {
    type: FETCH_CUSTOMER_SUCCESS,
    payload: {
      uiState: SUCCESS,
      customer: customer,
    },
  };
};

export const fetchCustomerError = error => {
  return {
    type: FETCH_CUSTOMER_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const addCustomerSuccess = message => {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    payload: {
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const addCustomerError = error => {
  return {
    type: ADD_CUSTOMER_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const updateCustomerStatus = (id, payload) => {
  return {
    type: UPDATE_CUSTOMER_STATUS,
    payload: {
      customerId: id,
      payload,
    },
  };
};

export const updatePriorityUser = payload => {
  return {
    type: UPDATE_CUSTOMER_PRIORITY,
    payload: {
      payload,
    },
  };
};

export const markCustomerNonPaying = payload => {
  return {
    type: UPDATE_CUSTOMER_NONPAYING,
    payload: {
      payload,
    },
  };
};

export const updateCustomerBot = payload => {
  return {
    type: UPDATE_CUSTOMER_BOT,
    payload: {
      payload,
    },
  };
};

export const addCustomerCommunication = (id, payload) => {
  return {
    type: "ADD_CUSTOMER_COMMUNICATION",
    payload: {
      customerId: id,
      payload,
    },
  };
};

export const addPendingPayment = (id, payload) => {
  return {
    type: "ADD_PENDING_PAYMENT",
    payload: {
      customerId: id,
      payload,
    },
  };
};

export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};

export const updateCustomerStatusSuccess = (result, message) => {
  return {
    type: UPDATE_CUSTOMER_STATUS_SUCCESS,
    payload: {
      open: false,
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const updateCustomerStatusError = error => {
  return {
    type: UPDATE_CUSTOMER_STATUS_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};
export const deviceReplacement = payload => {
  return {
    type: DEVICE_REPLACEMENT,
    payload: {
      payload,
    },
  };
};

export const fetchPhpPurifierValidityDetails = payload => {
  return {
    type: FETCH_PHP_PURIFIER_VALIDITY_DETAILS,
    loader: true,
    payload,
  };
};
export const fetchPhpPurifierValidityDetailsSuccess = activePurifiers => {
  return {
    type: FETCH_PHP_PURIFIER_VALIDITY_DETAILS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      activePurifiers: activePurifiers,
      loader: false,
    },
  };
};
export const fetchPhpMonthlyInstallations = payload => {
  return {
    type: FETCH_PHP_MONTHLY_INSTALLATIONS,
    loader: true,
    payload,
  };
};
export const fetchPhpMonthlyInstallationsSuccess = monthlyInstallations => {
  return {
    type: FETCH_PHP_MONTHLY_INSTALLATIONS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      monthlyInstallations: monthlyInstallations,
      loader: false,
    },
  };
};
export const fetchPhpMonthlyStats = payload => {
  return {
    type: FETCH_PHP_MONTHLY_STATS,
    loader: true,
    payload,
  };
};
export const fetchPhpMonthlyStatsSuccess = monthlyStats => {
  return {
    type: FETCH_PHP_MONTHLY_STATS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      monthlyStats: monthlyStats,
      loader: false,
    },
  };
};
export const fetchNewDBCustomersYearlyReportRequest = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_REQUEST,
    payload,
  };
};
export const fetchNewDBCustomersYearlyReportSuccess = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_SUCCESS,
    payload,
  };
};
export const fetchNewDBCustomersYearlyReportError = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_ERROR,
    payload,
  };
};
export const fetchNewDBCustomersMonthlyReportRequest = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_REQUEST,
    payload,
  };
};
export const fetchNewDBCustomersMonthlyReportSuccess = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_SUCCESS,
    payload,
  };
};
export const fetchNewDBCustomersMonthlyReportError = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_ERROR,
    payload,
  };
};
export const fetchNewDBCustomersWeeklyReportRequest = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_REQUEST,
    payload,
  };
};
export const fetchNewDBCustomersWeeklyReportSuccess = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_SUCCESS,
    payload,
  };
};
export const fetchNewDBCustomersWeeklyReportError = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_ERROR,
    payload,
  };
};
export const fetchNewDBCustomersDailyReportRequest = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_REQUEST,
    payload,
  };
};
export const fetchNewDBCustomersDailyReportSuccess = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_SUCCESS,
    payload,
  };
};
export const fetchNewDBCustomersDailyReportError = payload => {
  return {
    type: FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_ERROR,
    payload,
  };
};
export const fetchNewDbDeviceCountsByStatusesRequest = payload => {
  return {
    type: FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_REQUEST,
    payload,
  };
}
export const fetchNewDbDeviceCountsByStatusesSuccess = payload => {
  return {
    type: FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_SUCCESS,
    payload,
  };
};
export const fetchNewDbDeviceCountsByStatusesError = payload => {
  return {
    type: FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_ERROR,
    payload,
  };
};
export const fetchRewards = payload => {
  return {
    type: FETCH_REWARDS,
    loader: true,
    payload,
  };
};
export const fetchRewardsSuccess = rewards => {
  return {
    type: FETCH_REWARDS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      rewards: rewards,
      loader: false,
    },
  };
};
export const fetchPhpDailyInstallationDetails = payload => {
  return {
    type: FETCH_PHP_DAILY_INSTALLATIONS_DETAILS,
    loader: true,
    payload,
  };
};
export const fetchPhpDailyInstallationDetailsSuccess = dailyInstallations => {
  return {
    type: FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      dailyInstallations: dailyInstallations,
      loader: false,
    },
  };
};
export const fetchPhpWeeklyInstallationDetails = payload => {
  return {
    type: FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS,
    loader: true,
    payload,
  };
};
export const fetchPhpWeeklyInstallationDetailsSuccess = weeklyInstallations => {
  return {
    type: FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      weeklyInstallations: weeklyInstallations,
      loader: false,
    },
  };
};

export const fetchCustopmerPlansListSuccess = data => {
  return {
    type: FETCH_CUSTOMER_PLANS_SUCCESS,
    payload: {
      data,
      loading: false
    }
  }
}
export const fetchCustopmerPlansList = data => {
  return {
    type: FETCH_CUSTOMER_PLANS_REQUEST,
    payload: {
      loading: true
    }
  }
}

export const fetchInstallationStatus = data => {
  return {
    type: FETCH_INSTALLATION_STATUS,
    payload: data
  }
}

export const fetchInstallationStatusSuccess = data => {
  return {
    type: FETCH_INSTALLATION_STATUS_SUCCESS,
    payload: data
  }
}

export const fetchInstallationStatusError = data => {
  return {
    type: FETCH_INSTALLATION_STATUS_ERROR,
    payload: data
  }
}
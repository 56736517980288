import {
  DEVICE_CODE_STATE_CLEAN_UP,
  CREATE_NEW_DEVICE_CODE_PREFIX_ERROR,
  CREATE_NEW_DEVICE_CODE_PREFIX_REQUEST,
  CREATE_NEW_DEVICE_CODE_PREFIX_SUCCESS,
  FETCH_ALL_DEVICE_CODE_PREFIX_ERROR,
  FETCH_ALL_DEVICE_CODE_PREFIX_REQUEST,
  FETCH_ALL_DEVICE_CODE_PREFIX_SUCCESS,
  FETCH_DEVICE_CODE_STATUES_ERROR,
  FETCH_DEVICE_CODE_STATUES_REQUEST,
  FETCH_DEVICE_CODE_STATUES_SUCCESS,
  GENERATE_NEW_DEVICE_CODES_ERROR,
  GENERATE_NEW_DEVICE_CODES_REQUEST,
  GENERATE_NEW_DEVICE_CODES_SUCCESS,
  SEARCH_DEVICE_CODES_ERROR,
  SEARCH_DEVICE_CODES_REQUEST,
  SEARCH_DEVICE_CODES_SUCCESS,
  UPDATE_DEVICE_CODES_STATUS_REQUEST,
} from "constants/ActionTypes";

// PREFIX:
export const fetchAllDeviceCodePrefixRequest = payload => {
  return {
    type: FETCH_ALL_DEVICE_CODE_PREFIX_REQUEST,
    payload,
  };
};

export const fetchAllDeviceCodePrefixSuccess = payload => {
  return {
    type: FETCH_ALL_DEVICE_CODE_PREFIX_SUCCESS,
    payload,
  };
};

export const fetchAllDeviceCodePrefixError = payload => {
  return {
    type: FETCH_ALL_DEVICE_CODE_PREFIX_ERROR,
    payload,
  };
};

// CREATE PREFIX:
export const createNewDeviceCodePrefixRequest = payload => {
  return {
    type: CREATE_NEW_DEVICE_CODE_PREFIX_REQUEST,
    payload,
  };
};

export const createNewDeviceCodePrefixSuccess = payload => {
  return {
    type: CREATE_NEW_DEVICE_CODE_PREFIX_SUCCESS,
    payload,
  };
};

export const createNewDeviceCodePrefixError = payload => {
  return {
    type: CREATE_NEW_DEVICE_CODE_PREFIX_ERROR,
    payload,
  };
};

// GENERATE CODES:
export const generateNewDeviceCodesRequest = payload => {
  return {
    type: GENERATE_NEW_DEVICE_CODES_REQUEST,
    payload,
  };
};

export const generateNewDeviceCodesSuccess = payload => {
  return {
    type: GENERATE_NEW_DEVICE_CODES_SUCCESS,
    payload,
  };
};

export const generateNewDeviceCodesError = payload => {
  return {
    type: GENERATE_NEW_DEVICE_CODES_ERROR,
    payload,
  };
};

// SEARCH CODES:
export const searchDeviceCodesRequest = payload => {
  return {
    type: SEARCH_DEVICE_CODES_REQUEST,
    payload,
  };
};

export const searchDeviceCodesSuccess = payload => {
  return {
    type: SEARCH_DEVICE_CODES_SUCCESS,
    payload,
  };
};

export const searchDeviceCodesError = payload => {
  return {
    type: SEARCH_DEVICE_CODES_ERROR,
    payload,
  };
};

// UPDATE CODES STATUS:
export const updateDeviceCodesStatusRequest = payload => {
  return {
    type: UPDATE_DEVICE_CODES_STATUS_REQUEST,
    payload,
  };
};

// FETCH DEVICE CODE STATUES
export const fetchDeviceCodeStatuesRequest = payload => {
  return {
    type: FETCH_DEVICE_CODE_STATUES_REQUEST,
    payload,
  };
};

export const fetchDeviceCodeStatuesSuccess = payload => {
  return {
    type: FETCH_DEVICE_CODE_STATUES_SUCCESS,
    payload,
  };
};

export const fetchDeviceCodeStatuesError = payload => {
  return {
    type: FETCH_DEVICE_CODE_STATUES_ERROR,
    payload,
  };
};

export const doCleanUp = payload => {
  return {
    type: DEVICE_CODE_STATE_CLEAN_UP,
    payload,
  };
}
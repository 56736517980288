import {
  CREATE_NEW_DEVICE_CODE_PREFIX_ERROR,
  CREATE_NEW_DEVICE_CODE_PREFIX_REQUEST,
  CREATE_NEW_DEVICE_CODE_PREFIX_SUCCESS,
  DEVICE_CODE_STATE_CLEAN_UP,
  FETCH_ALL_DEVICE_CODE_PREFIX_ERROR,
  FETCH_ALL_DEVICE_CODE_PREFIX_REQUEST,
  FETCH_ALL_DEVICE_CODE_PREFIX_SUCCESS,
  FETCH_DEVICE_CODE_STATUES_ERROR,
  FETCH_DEVICE_CODE_STATUES_REQUEST,
  FETCH_DEVICE_CODE_STATUES_SUCCESS,
  GENERATE_NEW_DEVICE_CODES_ERROR,
  GENERATE_NEW_DEVICE_CODES_REQUEST,
  GENERATE_NEW_DEVICE_CODES_SUCCESS,
  SEARCH_DEVICE_CODES_ERROR,
  SEARCH_DEVICE_CODES_REQUEST,
  SEARCH_DEVICE_CODES_SUCCESS,
  UPDATE_DEVICE_CODES_STATUS_REQUEST,
} from "constants/ActionTypes";
import { ca } from "date-fns/locale";

const INIT_STATE = {
  // prefix
  prefixList: [],
  allPrefixLoader: false,
  allPrefixError: false,
  // create prefix
  newPrefix: {},
  newPrefixLoader: true,
  newPrefixError: false,
  // generate code
  generatedCodes: [],
  generateCodeLoader: false,
  generateCodeError: false,
  // search code
  deviceCodePage: {},
  searchCodeLoader: false,
  searchCodeError: false,
  // device code statues
  deviceCodeStatues: [],
  deviceCodeStatuesLoader: false,
  deviceCodeStatuesError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // PREFIX:
    case FETCH_ALL_DEVICE_CODE_PREFIX_REQUEST: {
      return {
        ...state,
        prefixList: [],
        allPrefixLoader: true,
        allPrefixError: false,
      };
    }
    case FETCH_ALL_DEVICE_CODE_PREFIX_SUCCESS: {
      return {
        ...state,
        prefixList: action.payload,
        allPrefixError: false,
        allPrefixLoader: false,
      };
    }
    case FETCH_ALL_DEVICE_CODE_PREFIX_ERROR: {
      return {
        ...state,
        prefixList: [],
        allPrefixLoader: false,
        allPrefixError: true,
      };
    }
    // CREATE PREFIX:
    case CREATE_NEW_DEVICE_CODE_PREFIX_REQUEST: {
      return {
        ...state,
        newPrefix: {},
        newPrefixLoader: true,
        newPrefixError: false,
      };
    }
    case CREATE_NEW_DEVICE_CODE_PREFIX_SUCCESS: {
      return {
        ...state,
        newPrefix: action.payload,
        newPrefixLoader: false,
        newPrefixError: false,
      };
    }
    case CREATE_NEW_DEVICE_CODE_PREFIX_ERROR: {
      return {
        ...state,
        newPrefix: {},
        newPrefixLoader: false,
        newPrefixError: true,
      };
    }
    // GENERATE CODE:
    case GENERATE_NEW_DEVICE_CODES_REQUEST: {
      return {
        ...state,
        generatedCodes: [],
        generateCodeLoader: true,
        generateCodeError: false,
      };
    }
    case GENERATE_NEW_DEVICE_CODES_SUCCESS: {
      return {
        ...state,
        generatedCodes: action.payload,
        generateCodeLoader: false,
        generateCodeError: false,
      };
    }
    case GENERATE_NEW_DEVICE_CODES_ERROR: {
      return {
        ...state,
        generatedCodes: [],
        generateCodeLoader: false,
        generateCodeError: true,
      };
    }
    // SEARCH CODES:
    case SEARCH_DEVICE_CODES_REQUEST: {
      return {
        ...state,
        deviceCodePage: [],
        searchCodeLoader: true,
        searchCodeError: false,
      };
    }
    case SEARCH_DEVICE_CODES_SUCCESS: {
      return {
        ...INIT_STATE,
        ...state,
        deviceCodePage: action.payload,
        searchCodeLoader: false,
        searchCodeError: false,
      };
    }
    case SEARCH_DEVICE_CODES_ERROR: {
      return {
        ...INIT_STATE,
        ...state,
        deviceCodePage: [],
        searchCodeLoader: false,
        searchCodeError: true,
      };
    }
    // UPDATE CODES STATUS:
    case UPDATE_DEVICE_CODES_STATUS_REQUEST: {
      return {
        ...state,
      };
    }
    // FETCH DEVICE CODE STATUES
    case FETCH_DEVICE_CODE_STATUES_REQUEST: {
      return {
        ...state,
        deviceCodeStatues: [],
        deviceCodeStatuesLoader: true,
        deviceCodeStatuesError: false,
      };
    }
    case FETCH_DEVICE_CODE_STATUES_SUCCESS: {
      return {
        ...state,
        deviceCodeStatues: action.payload,
        deviceCodeStatuesLoader: false,
        deviceCodeStatuesError: false,
      };
    }
    case FETCH_DEVICE_CODE_STATUES_ERROR: {
      return {
        ...state,
        deviceCodeStatues: [],
        deviceCodeStatuesLoader: false,
        deviceCodeStatuesError: true,
      };
    }
    case DEVICE_CODE_STATE_CLEAN_UP: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};

import {
  REQUEST_UPDATE_INSTALLATION_STATUS_V2,
  RESPONSE_UPDATE_INSTALLATION_STATUS_V2,
  UNINSTALL_PAUSED_INSTALLATION_ERROR,
  UNINSTALL_PAUSED_INSTALLATION_REQUEST,
  UNINSTALL_PAUSED_INSTALLATION_SUCCESS,
  UNPAUSE_SERVICE_ERROR,
  UNPAUSE_SERVICE_REQUEST,
  UNPAUSE_SERVICE_SUCCESS,
} from "constants/ActionTypes";
import { ERROR, SUCCESS } from "constants/uiStates";

export const requestUpdateInstallationStatusAction = payload => {
  return {
    type: REQUEST_UPDATE_INSTALLATION_STATUS_V2,
    payload,
  };
};

export const responseUpdateInstallationStatusAction = payload => {
  return {
    type: RESPONSE_UPDATE_INSTALLATION_STATUS_V2,
    payload,
  };
};

export const uninstallPausedInstallationRequestAction = payload => {
  return {
    type: UNINSTALL_PAUSED_INSTALLATION_REQUEST,
    payload,
  }
}

export const uninstallPausedInstallationSuccess = payload => {
  return {
    type: UNINSTALL_PAUSED_INSTALLATION_SUCCESS,
    payload,
  };
};

export const uninstallPausedInstallationError = payload => {
  return {
    type: UNINSTALL_PAUSED_INSTALLATION_ERROR,
    payload,
  };
};

export const unpauseServiceRequestAction = payload => {
  return {
    type: UNPAUSE_SERVICE_REQUEST,
    payload,
  };
};

export const unpauseServiceSuccess = payload => {
  return {
    type: UNPAUSE_SERVICE_SUCCESS,
    payload,
  };
};

export const unpauseServiceError = payload => {
  return {
    type: UNPAUSE_SERVICE_ERROR,
    payload,
  };
};
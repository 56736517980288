import Footer from "components/Footer";
import Header from "components/Header/index";
import TopNav from "components/TopNav";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import Sidebar from "containers/SideNav/index";
import React from "react";
import { isIOS, isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import asyncComponent from "../util/asyncComponent";
import Area from "./routes/area";
import Customer from "./routes/customer";
import Delivery from "./routes/delivery";
import Device from "./routes/device";
import Employee from "./routes/employee";
import Installation from "./routes/installations";
import Profile from "./routes/profile";
import SlotZone from "./routes/slotzone";
import Sponsor from "./routes/sponsor";
import Technician from "./routes/technician";
import Workforce from "./routes/workforce";
import Iot from "./routes/iot";
import B2B from "./routes/b2b";
import { isAuthorised } from "actions";

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/customer`} component={Customer} />
                <Route path={`${match.url}/delivery`} component={Delivery} />
                <Route path={`${match.url}/employee`} component={Employee} />
                <Route path={`${match.url}/workforce`} component={Workforce} />
                {/* <Route path={`${match.url}/device`} component={Device} /> */}
                <Route path={`${match.url}/area`} component={Area} />
                <Route path={`${match.url}/slotzone`} component={SlotZone} />
                <Route
                  path={`${match.url}/installation`}
                  component={Installation}
                />
                <Route path={`${match.url}/profile`} component={Profile} />
                <Route path={`${match.url}/sponsor`} component={Sponsor} />
                {isAuthorised(this.props.authUser, "OPERATIONS_6") && (
                  <Route path={`${match.url}/b2b-partners`} component={B2B} />
                )}
                <Route
                  path={`${match.url}/technician`}
                  component={Technician}
                />
                <Route path={`${match.url}/iot`} component={Iot} />
                <Route
                  path={`${match.url}/coming-soon`}
                  component={asyncComponent(() =>
                    import("components/ComingSoon")
                  )}
                />
                <Route
                  component={asyncComponent(() =>
                    import("components/Error404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { authUser } = auth;
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition, authUser };
};
export default withRouter(connect(mapStateToProps)(App));

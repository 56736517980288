import {
  ADD_DEVICE_REPLACEMENT_JOB,
  ADD_JOB_TO_SYSTEM,
  ADD_PAYMENT_TO_BE_COLLECTED,
  ADD_PAYMENT_WORKFLOW,
  ADD_RE_DELIVERY_JOB_TO_SYSTEM,
  CREATE_INITIAL_PAYMENTS,
  CREATE_SPARE_TRANSFER_JOB_REQUEST,
  CREATE_SPARE_TRANSFER_JOB_RESPONSE,
  DIAL_PHONE_NUMBER_ERROR,
  DIAL_PHONE_NUMBER_REQUEST,
  DIAL_PHONE_NUMBER_SUCCESS,
  FETCH_ALL_DELIVERY_PERSON,
  FETCH_ALL_DELIVERY_PERSON_ERROR,
  FETCH_ALL_DELIVERY_PERSON_SUCCESS,
  FETCH_ALL_JOB,
  FETCH_ALL_JOB_SUCCESS,
  FETCH_ALL_PURIFIER_MODELS,
  FETCH_ALL_PURIFIER_MODELS_SUCCESS,
  FETCH_ALL_REPEATED_JOBS,
  FETCH_ALL_REPEATED_JOBS_ERROR,
  FETCH_ALL_REPEATED_JOBS_SUCCESS,
  FETCH_ALL_TEAMS,
  FETCH_ALL_TEAMS_ERROR,
  FETCH_ALL_TEAMS_SUCCESS,
  FETCH_AREAS,
  FETCH_AREAS_SUCCESS,
  FETCH_AREA_ZONES_BY_CITY,
  FETCH_AREA_ZONES_BY_CITY_ERROR,
  FETCH_AREA_ZONES_BY_CITY_NAME,
  FETCH_AREA_ZONES_BY_CITY_NAME_SUCCESS,
  FETCH_AREA_ZONES_BY_CITY_SUCCESS,
  FETCH_AVAILABLE_SLOTS,
  FETCH_AVAILABLE_SLOTS_ERROR,
  FETCH_AVAILABLE_SLOTS_SUCCESS,
  FETCH_AVAILABLE_TECHNICIANS,
  FETCH_AVAILABLE_TECHNICIANS_ERROR,
  FETCH_AVAILABLE_TECHNICIANS_SUCCESS,
  FETCH_CATEGORY_AVAILABLE_SLOT,
  FETCH_CATEGORY_AVAILABLE_SLOT_SUCCESS,
  FETCH_DEPENDENCIES,
  FETCH_DEPENDENCIES_ERROR,
  FETCH_DEPENDENCIES_SUCCESS,
  FETCH_JOB,
  FETCH_JOBS_BY_INSTALLATION_ID,
  FETCH_JOBS_BY_INSTALLATION_ID_ERROR,
  FETCH_JOBS_BY_INSTALLATION_ID_SUCCESS,
  FETCH_JOB_ERROR,
  FETCH_JOB_IMAGES,
  FETCH_JOB_IMAGES_SUCCESS,
  FETCH_JOB_SLOTS,
  FETCH_JOB_SLOTS_SUCCESS,
  FETCH_JOB_STATUS,
  FETCH_JOB_STATUS_SUCCESS,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_TAGS,
  FETCH_JOB_TAGS_DEL,
  FETCH_JOB_TAGS_DEL_ERROR,
  FETCH_JOB_TAGS_DEL_SUCCESS,
  FETCH_JOB_TAGS_OPS,
  FETCH_JOB_TAGS_OPS_ERROR,
  FETCH_JOB_TAGS_OPS_SUCCESS,
  FETCH_JOB_TAGS_SUCCESS,
  FETCH_JOB_TYPES,
  FETCH_JOB_TYPES_SUCCESS,
  FETCH_LAST_3_JOBS_BY_INSTALLATION,
  FETCH_LAST_3_JOBS_BY_INSTALLATION_ERROR,
  FETCH_LAST_3_JOBS_BY_INSTALLATION_SUCCESS,
  FETCH_POSTPONED_JOB_DEPENDENCIES_ERROR,
  FETCH_POSTPONED_JOB_DEPENDENCIES_REQUEST,
  FETCH_POSTPONED_JOB_DEPENDENCIES_SUCCESS,
  FETCH_PRIORITY_LIST,
  FETCH_PRIORITY_LIST_ERROR,
  FETCH_PRIORITY_LIST_SUCCESS,
  FETCH_TECHNICIANS,
  FETCH_TECHNICIANS_ERROR,
  FETCH_TECHNICIANS_JOB_SLOTS,
  FETCH_TECHNICIANS_JOB_SLOTS_ERROR,
  FETCH_TECHNICIANS_JOB_SLOTS_SUCCESS,
  FETCH_TECHNICIANS_SUCCESS,
  FETCH_ZONES,
  FETCH_ZONES_SUCCESS,
  GENERATE_DELIVERY_CHALLAN,
  RESET_ZONES,
  SEND_HAPPY_CODE,
  SEND_OTP_FOR_POSTPONEMENT_ERROR,
  SEND_OTP_FOR_POSTPONEMENT_REQUEST,
  SEND_OTP_FOR_POSTPONEMENT_SUCCESS,
  SHOW_API_MESSAGE,
  SUBMIT_RCA_DETAILS,
  SUBMIT_RCA_DETAILS_ERROR,
  SUBMIT_RCA_DETAILS_SUCCESS,
  UPDATE_ELEMENT_ADD_TEXT,
  UPDATE_ELEMENT_ADD_TEXT_ERROR,
  UPDATE_ELEMENT_ADD_TEXT_SUCCESS,
  UPDATE_GS_THRESHOLD_LIMITS,
  UPDATE_GS_THRESHOLD_LIMITS_SUCCESS,
  UPDATE_JOB,
  UPDATE_JOB_APPOINTMENT,
  UPDATE_JOB_APPOINTMENT_ERROR,
  UPDATE_JOB_APPOINTMENT_SUCCESS,
  UPDATE_JOB_ERROR,
  UPDATE_JOB_STATUS,
  UPDATE_JOB_STATUS_ERROR,
  UPDATE_JOB_STATUS_SUCCESS,
  UPDATE_JOB_SUCCESS,
  UPDATE_ZIP,
  UPDATE_ZIP_ERROR,
  UPDATE_ZIP_SUCCESS,
} from "constants/ActionTypes";
import { ERROR, SUCCESS } from "constants/uiStates";

export const fetchJob = id => {
  return {
    type: FETCH_JOB,
    payload: { id },
  };
};

export const fetchJobs = payload => {
  return {
    type: FETCH_ALL_JOB,
    payload,
  };
};

export const updateJob = (id, payload) => {
  return {
    type: UPDATE_JOB,
    payload: {
      jobId: id,
      payload,
    },
  };
};

export const updateJobSuccess = (payload) => {
  return {
    type: UPDATE_JOB_SUCCESS,
    payload,
  };
}


export const updateJobError = payload => {
  return {
    type: UPDATE_JOB_ERROR,
    payload,
  };
};

export const fetchJobSuccess = job => {
  return {
    type: FETCH_JOB_SUCCESS,
    payload: {
      uiState: SUCCESS,
      job: job,
    },
  };
};

export const fetchJobError = error => {
  return {
    type: FETCH_JOB_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const fetchJobsSuccess = jobs => {
  return {
    type: FETCH_ALL_JOB_SUCCESS,
    payload: jobs,
  };
};

export const fetchJobSlots = payload => {
  return {
    type: FETCH_JOB_SLOTS,
    payload,
  };
};

export const fetchJobSlotsSuccess = slots => {
  return {
    type: FETCH_JOB_SLOTS_SUCCESS,
    payload: slots,
  };
};

export const fetchAvailableSlotsWithParams = payload => {
  return {
    type: FETCH_AVAILABLE_SLOTS,
    loader: true,
    payload,
  };
};

export const fetchCategoryAvailableSlotsWithParams = payload => {
  return {
    type: FETCH_CATEGORY_AVAILABLE_SLOT,
    loader: true,
    payload,
  };
};

export const fetchAvailableSlotsWithParamsSuccess = availableSlots => {
  return {
    type: FETCH_AVAILABLE_SLOTS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      availableSlots: availableSlots,
      loader: false,
    },
  };
};

export const fetchCategoryAvailableSlotsWithParamsSuccess = availableSlots => {
  return {
    type: FETCH_CATEGORY_AVAILABLE_SLOT_SUCCESS,
    payload: {
      uiState: SUCCESS,
      availableSlots: availableSlots,
      loader: false,
    },
  };
};

export const fetchAvailableSlotsWithParamsError = error => {
  return {
    type: FETCH_AVAILABLE_SLOTS_ERROR,
    payload: {
      uiState: Error,
      error,
    },
  };
};
export const fetchTeams = payload => {
  return {
    type: FETCH_ALL_TEAMS,
    loader: true,
    payload,
  };
};

export const fetchTeamsSuccess = teams => {
  return {
    type: FETCH_ALL_TEAMS_SUCCESS,
    payload: {
      uiState: SUCCESS,
      teams: teams,
      loader: false,
    },
  };
};

export const fetchTeamsError = error => {
  return {
    type: FETCH_ALL_TEAMS_ERROR,
    payload: {
      uiState: Error,
      error,
    },
  };
};
export const fetchAreasSuccess = slots => {
  return {
    type: FETCH_AREAS_SUCCESS,
    payload: slots,
  };
};

export const fetchAreas = payload => {
  return {
    type: FETCH_AREAS,
    payload,
  };
};
export const fetchZonesSuccess = zones => {
  return {
    type: FETCH_ZONES_SUCCESS,
    payload: zones,
  };
};
export const fetchZones = payload => {
  return {
    type: FETCH_ZONES,
    payload,
  };
};
export const fetchJobTagsSuccess = tags => {
  return {
    type: FETCH_JOB_TAGS_SUCCESS,
    payload: tags,
  };
};
export const fetchJobTags = payload => {
  return {
    type: FETCH_JOB_TAGS,
    payload,
  };
};
export const fetchJobTagsOps = payload => {
  return {
    type: FETCH_JOB_TAGS_OPS,
    payload: {
      isDelivery: false,
    },
  };
};
export const fetchJobTagsOpsSuccess = tags => {
  return {
    type: FETCH_JOB_TAGS_OPS_SUCCESS,
    payload: tags,
  };
};
export const fetchJobTagsOpsError = tags => {
  return {
    type: FETCH_JOB_TAGS_OPS_ERROR,
    payload: tags,
  };
};
export const fetchJobTagsDel = payload => {
  return {
    type: FETCH_JOB_TAGS_DEL,
    payload: {
      isDelivery: true,
    },
  };
};
export const fetchJobTagsDelSuccess = tags => {
  return {
    type: FETCH_JOB_TAGS_DEL_SUCCESS,
    payload: tags,
  };
};
export const fetchJobTagsDelError = tags => {
  return {
    type: FETCH_JOB_TAGS_DEL_ERROR,
    payload: tags,
  };
};
export const fetchJobStatusSuccess = status => {
  return {
    type: FETCH_JOB_STATUS_SUCCESS,
    payload: status,
  };
};
export const fetchJobStatus = payload => {
  return {
    type: FETCH_JOB_STATUS,
    payload,
  };
};

export const fetchPostponedJobDependenciesRequest = payload => {
  return {
    type: FETCH_POSTPONED_JOB_DEPENDENCIES_REQUEST,
    payload
  }
}

export const fetchPostponedJobDependenciesSuccess = payload => {
  return {
    type: FETCH_POSTPONED_JOB_DEPENDENCIES_SUCCESS,
    payload,
  };
};

export const fetchPostponedJobDependenciesError = payload => {
  return {
    type: FETCH_POSTPONED_JOB_DEPENDENCIES_ERROR,
    payload,
  };
};

export const sendOtpForPostponementRequest = payload => {
  return {
    type: SEND_OTP_FOR_POSTPONEMENT_REQUEST,
    payload
  }
}

export const sendOtpForPostponementSuccess = payload => {
  return {
    type: SEND_OTP_FOR_POSTPONEMENT_SUCCESS,
    payload,
  };
};

export const sendOtpForPostponementError = payload => {
  return {
    type: SEND_OTP_FOR_POSTPONEMENT_ERROR,
    payload,
  };
};

export const dialPhoneNumberRequest = phone => {
  return {
    type: DIAL_PHONE_NUMBER_REQUEST,
    payload: phone
  }
}
export const dialPhoneNumberSuccess = phone => {
  return {
    type: DIAL_PHONE_NUMBER_SUCCESS,
    payload: phone,
  };
};
export const dialPhoneNumberError = phone => {
  return {
    type: DIAL_PHONE_NUMBER_ERROR,
    payload: phone,
  };
};

export const fetchJobTypesSuccess = jobTypes => {
  return {
    type: FETCH_JOB_TYPES_SUCCESS,
    payload: jobTypes,
  };
};

export const fetchJobTypes = payload => {
  return {
    type: FETCH_JOB_TYPES,
    payload,
  };
};

export const fetchJobImagesSuccess = jobImages => {
  return {
    type: FETCH_JOB_IMAGES_SUCCESS,
    payload: jobImages,
  };
};

export const fetchJobImages = payload => {
  return {
    type: FETCH_JOB_IMAGES,
    payload,
  };
};

export const fetchTechnicians = payload => {
  return {
    type: FETCH_TECHNICIANS,
    payload,
  };
};

export const fetchTechniciansSuccess = technicians => {
  return {
    type: FETCH_TECHNICIANS_SUCCESS,
    payload: technicians,
  };
};

export const fetchTechniciansError = error => {
  return {
    type: FETCH_TECHNICIANS_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};

export const fetchAvailableTechnicians = payload => {
  return {
    type: FETCH_AVAILABLE_TECHNICIANS,
    payload,
  };
};
export const fetchAvailableTechniciansSuccess = technicians => {
  return {
    type: FETCH_AVAILABLE_TECHNICIANS_SUCCESS,
    payload: technicians,
  };
};

export const fetchAvailableTechniciansError = error => {
  return {
    type: FETCH_AVAILABLE_TECHNICIANS_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};
export const fetchAllDeliveryPerson = payload => {
  return {
    type: FETCH_ALL_DELIVERY_PERSON,
    payload,
  };
};
export const fetchAllDeliveryPersonSuccess = technicians => {
  return {
    type: FETCH_ALL_DELIVERY_PERSON_SUCCESS,
    payload: technicians,
  };
};

export const fetchAllDeliveryPersonError = error => {
  return {
    type: FETCH_ALL_DELIVERY_PERSON_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};

export const fetchLast3JobsByInstallation = id => {
  return {
    type: FETCH_LAST_3_JOBS_BY_INSTALLATION,
    payload: {
      jobId: id,
    },
  };
};

export const fetchLast3JobsByInstallationSuccess = jobs => {
  return {
    type: FETCH_LAST_3_JOBS_BY_INSTALLATION_SUCCESS,
    payload: jobs,
  };
};

export const fetchLast3JobsByInstallationError = error => {
  return {
    type: FETCH_LAST_3_JOBS_BY_INSTALLATION_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};

export const getJobsByInstallationId = payload => {
  return {
    type: FETCH_JOBS_BY_INSTALLATION_ID,
    payload: payload,
  };
};

export const getJobsByInstallationIdSuccess = jobs => {
  return {
    type: FETCH_JOBS_BY_INSTALLATION_ID_SUCCESS,
    payload: jobs,
  };
};

export const getJobsByInstallationIdError = error => {
  return {
    type: FETCH_JOBS_BY_INSTALLATION_ID_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};

export const fetchTechniciansJobSlots = payload => {
  return {
    type: FETCH_TECHNICIANS_JOB_SLOTS,
    payload,
  };
};

export const fetchTechniciansJobSlotsSuccess = technicians => {
  return {
    type: FETCH_TECHNICIANS_JOB_SLOTS_SUCCESS,
    payload: technicians,
  };
};

export const fetchTechniciansJobSlotsError = error => {
  return {
    type: FETCH_TECHNICIANS_JOB_SLOTS_ERROR,
    payload: {
      uiState: ERROR,
      error,
    },
  };
};

export const updateJobStatus = (id, payload) => {
  return {
    type: UPDATE_JOB_STATUS,
    payload: {
      jobId: id,
      payload,
    },
  };
};

export const updateJobStatusSuccess = (result, message) => {
  return {
    type: UPDATE_JOB_STATUS_SUCCESS,
    payload: {
      open: false,
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const updateJobStatusError = error => {
  return {
    type: UPDATE_JOB_STATUS_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const updateZip = (id, payload) => {
  return {
    type: UPDATE_ZIP,
    payload: {
      jobId: id,
      payload,
    },
  };
};

export const updateZipSuccess = (result, message) => {
  return {
    type: UPDATE_ZIP_SUCCESS,
    payload: {
      open: false,
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const updateZipError = error => {
  return {
    type: UPDATE_ZIP_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};
export const updateJobAppointment = (id, payload) => {
  return {
    type: UPDATE_JOB_APPOINTMENT,
    payload: {
      jobId: id,
      payload,
    },
  };
};

export const updateJobAppointmentSuccess = (result, message) => {
  return {
    type: UPDATE_JOB_APPOINTMENT_SUCCESS,
    payload: {
      open: false,
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const updateJobAppointmentError = error => {
  return {
    type: UPDATE_JOB_APPOINTMENT_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const sendHappyCode = job => {
  return {
    type: SEND_HAPPY_CODE,
    payload: job,
  };
};
export const addJobToSystem = payload => {
  return {
    type: ADD_JOB_TO_SYSTEM,
    payload: payload,
  };
};

export const addReDeliveryJobToSystem = payload => {
  return {
    type: ADD_RE_DELIVERY_JOB_TO_SYSTEM,
    payload: payload,
  };
};

export const createInitialPayments = payload => {
  return {
    type: CREATE_INITIAL_PAYMENTS,
    payload: payload,
  };
};
export const addPaymentToBeCollected = payload => {
  return {
    type: ADD_PAYMENT_TO_BE_COLLECTED,
    payload: payload,
  };
};
export const addPayment = payload => {
  return {
    type: ADD_PAYMENT_WORKFLOW,
    payload: payload,
  };
};
export const addDeviceReplacementJob = payload => {
  return {
    type: ADD_DEVICE_REPLACEMENT_JOB,
    payload: payload,
  };
};
export const addSpareTransferJobRequest = payload => {
  return {
    type: CREATE_SPARE_TRANSFER_JOB_REQUEST,
    payload,
  }
}
export const addSpareTransferJobResponse = payload => {
  return {
    type: CREATE_SPARE_TRANSFER_JOB_RESPONSE,
    payload,
  };
};
export const updateElementAddText = params => {
  return {
    type: UPDATE_ELEMENT_ADD_TEXT,
    payload: {
      params,
    },
  };
};

export const generatePdf = payload => {
  return {
    type: GENERATE_DELIVERY_CHALLAN,
    payload: payload,
  };
};
export const updateElementAddTextSuccess = (result, message) => {
  return {
    type: UPDATE_ELEMENT_ADD_TEXT_SUCCESS,
    payload: {
      open: false,
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const updateElementAddTextError = error => {
  return {
    type: UPDATE_ELEMENT_ADD_TEXT_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};
export const fetchAreaZonesByCity = payload => {
  return {
    type: FETCH_AREA_ZONES_BY_CITY,
    payload: payload,
    loader: true,
  };
};

export const fetchAreaZonesByCityName = payload => {
  return {
    type: FETCH_AREA_ZONES_BY_CITY_NAME,
    payload: payload,
    loader: true
  }
}

export const fetchAreaZonesByCityNameSuccess = payload => {
  return {
    type: FETCH_AREA_ZONES_BY_CITY_NAME_SUCCESS,
    payload: payload,
    loader: true
  }
}

export const fetchAreaZonesByCitySuccess = zones => {
  return {
    type: FETCH_AREA_ZONES_BY_CITY_SUCCESS,
    payload: zones,
    loader: false,
  };
};

export const fetchAreaZonesByCityError = error => {
  return {
    type: FETCH_AREA_ZONES_BY_CITY_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const updateGSThresholdLimit = payload => {
  return {
    type: UPDATE_GS_THRESHOLD_LIMITS,
    payload: payload,
  };
};

export const updateGSThresholdLimitSuccess = zones => {
  return {
    type: UPDATE_GS_THRESHOLD_LIMITS_SUCCESS,
    payload: zones,
    loader: false,
  };
};

export const resetZones = () => {
  return {
    type: RESET_ZONES,
    payload: [],
  };
};

export const fetchRepeatedJobs = payload => {
  return {
    type: FETCH_ALL_REPEATED_JOBS,
    payload: payload,
  };
};

export const fetchRepeatedJobsSuccess = jobs => {
  return {
    type: FETCH_ALL_REPEATED_JOBS_SUCCESS,
    payload: jobs,
  };
};

export const fetchRepeatedJobsError = error => {
  return {
    type: FETCH_ALL_REPEATED_JOBS_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  }
}

export const recordRCA = payload => {
  return {
    type: SUBMIT_RCA_DETAILS,
    payload: payload,
  };
};

export const recordRCASuccess = (result, message) => {
  return {
    type: SUBMIT_RCA_DETAILS_SUCCESS,
    payload: result,
    message: message,
  };
};

export const recordRCAError = error => {
  return {
    type: SUBMIT_RCA_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchDependencies = payload => {
  return {
    type: FETCH_DEPENDENCIES,
    payload: payload,
  }
}

export const fetchDependenciesSuccess = dependencies => {
  return {
    type: FETCH_DEPENDENCIES_SUCCESS,
    payload: dependencies,
  };
};

export const fetchDependenciesError = error => {
  return {
    type: FETCH_DEPENDENCIES_ERROR,
    payload: error,
  }
}

export const fetchPriorityList = payload => {
  return {
    type: FETCH_PRIORITY_LIST,
    payload: payload,
  }
}

export const fetchPriorityListSuccess = dependencies => {
  return {
    type: FETCH_PRIORITY_LIST_SUCCESS,
    payload: dependencies,
  };
};

export const fetchPriorityListError = error => {
  return {
    type: FETCH_PRIORITY_LIST_ERROR,
    payload: error,
  }
}

export const fetchPurifierModels = payload => {
  return {
    type: FETCH_ALL_PURIFIER_MODELS,
    payload: payload
  }
}

export const fetchPurifierModelsSuccess = payload => {
  return {
    type: FETCH_ALL_PURIFIER_MODELS_SUCCESS,
    payload: payload
  }
}

export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};

import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const slot = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/all/list`}
        component={asyncComponent(() => import("./allSlotRoutes"))}
      />
      <Route
        path={`${match.url}/custom/list`}
        component={asyncComponent(() => import("./customSlots"))}
      />
      <Route
        path={`${match.url}/manageSlotCategory/:tab/:innerTab`}
        component={asyncComponent(() => import("./manageSlotCategory"))}
      />
      <Route
        path={`${match.url}/manageSlotCategory/:tab`}
        component={asyncComponent(() => import("./manageSlotCategory"))}
      />
      <Route
        path={`${match.url}/manageSlotCategory`}
        component={asyncComponent(() => import("./manageSlotCategory"))}
      />
      <Route component={asyncComponent(() => import("components/Error404"))} />
    </Switch>
  </div>
);

export default slot;

import { FETCH_AVAILABLE_ITEMS_SUCCESS, FETCH_EMPLOYEE_INVENTORY_SUCCESS, FETCH_EMPLOYEE_ISSUANCE_HISTORY, FETCH_EMPLOYEE_ISSUANCE_HISTORY_SUCCESS, FETCH_EMPLOYEE_WAREHOUSE, FETCH_EMPLOYEE_WAREHOUSE_SUCCESS, FETCH_ISSUANCE_JOBS, FETCH_ISSUANCE_JOBS_SUCCESS, FETCH_ISSUANCE_PACKAGE_INFO, FETCH_ISSUANCE_PACKAGE_INFO_SUCCESS } from 'constants/ActionTypes';

const INIT_STATE = {
    inventoryData: {
        part_info: [],
        return_info: [],
        employee_first_name: "",
        employee_last_name: "",
        not_picked_up: 0,
        parts_quantity: 0
    },
    items: [],
    isLoading: true
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_EMPLOYEE_INVENTORY_SUCCESS:
            return {
                ...state,
                inventoryData: action.payload.inventoryData,
                isLoading: false
            }
        case FETCH_AVAILABLE_ITEMS_SUCCESS:
            return {
                ...state,
                items: action.payload.items,
                isLoading: false
            }
        case FETCH_EMPLOYEE_ISSUANCE_HISTORY:
            return {
                ...state,
            }
        case FETCH_EMPLOYEE_ISSUANCE_HISTORY_SUCCESS:
            return {
                ...state,
                issuanceHistory: action.payload
            }
        case FETCH_ISSUANCE_PACKAGE_INFO:
            return {
                ...state,
            }
        case FETCH_ISSUANCE_PACKAGE_INFO_SUCCESS:
            return {
                ...state,
                issuanePackageInfo: action.payload
            }
        case FETCH_ISSUANCE_JOBS:
            return {
                ...state
            }
        case FETCH_ISSUANCE_JOBS_SUCCESS: {
            return {
                ...state,
                issuanceJobs: action.payload
            }
        }
        case FETCH_EMPLOYEE_WAREHOUSE: {
            return {
                ...state,
            }
        }
        case FETCH_EMPLOYEE_WAREHOUSE_SUCCESS: {
            return {
                ...state,
                employeeWarehouse: action?.payload?.employeeWarehouse
            }
        }
        default:
            return { ...state, isLoading: true };
    }
}
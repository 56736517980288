import {
  fetchAllCitiesError,
  fetchAllCitiesSuccess,
  fetchArea,
  fetchAreaError,
  fetchAreas,
  fetchAreasSuccess,
  fetchAreaSuccess,
  fetchCitiesSuccess,
  fetchStatesSuccess,
  fetchStatusesSuccess,
  fetchZonesByCityCodeError,
  fetchZonesByCityCodeSuccess,
  fetchZonesSuccess,
  showAPIMessage
} from "actions/AreaActions";
import { fetchCustomSlotsSuccess } from "actions/SlotZoneActions";
import {
  ADD_AREA,
  FETCH_ALL_AREA,
  FETCH_ALL_CITIES,
  FETCH_AREA,
  FETCH_AREA_ZONES_BY_CITY,
  FETCH_AREA_ZONES_BY_CITY_NAME,
  FETCH_CITIES,
  FETCH_CUSTOM_SLOT_BY_CITY,
  FETCH_STATES,
  FETCH_STATUSES,
  FETCH_ZONES,
  FETCH_ZONES_BY_CITY_CODE_REQUEST,
  UPDATE_AREA
} from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios, workForceAxios } from "util/Api";

function* addAreaRequest(action) {
  try {
    const response = yield call(addArea, action.payload);
    yield all([put(fetchAreas())]);
    if (response.success) {
      NotificationManager.success("Area Added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, '', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add area");
  }
}

const addArea = async function (params) {
  try {
    const response = await axios.post("/area/add", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateAreaRequest(action) {
  try {
    const response = yield call(updateArea, action.payload);
    yield all([put(fetchArea(action.payload.id))]);

    if (response.success) {
      NotificationManager.success("Area details updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, '', 10000);
    }

  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to update area details");
  }
}

const updateArea = async function (params) {
  try {

    const response = await axios.put("/area/update", {
      ...params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAreas = async function (params) {
  let areas = [];
  try {
    const response = await axios.get("/area/all", { params });
    areas = response.data;
  } catch (error) {
    console.log(error);
  }
  return areas;
};

function* fetchAreasRequest(action) {
  try {
    const fetchedArea = yield call(getAreas, action.payload);
    yield put(fetchAreasSuccess(fetchedArea));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

function* fetchAreaRequest(action) {
  try {
    const area = yield call(getArea, {
      areaId: action.payload.id,
    });
    yield put(fetchAreaSuccess(area));
  } catch (error) {
    yield put(fetchAreaError(error.message));
  }
}

const getArea = async function (params) {
  let areas = [];
  try {
    const response = await axios.get("/area/" + params["areaId"], {});
    areas = response.data;
  } catch (error) {
    console.log(error);
  }
  return areas;
};

const getStates = async function (params) {
  let states = [];
  try {
    const response = await axios.get("/area/states", { params });
    states = response.data;
  } catch (error) {
    console.log(error);
  }
  return states;
};

function* fetchStatesRequest(action) {
  try {
    const fetchedStates = yield call(getStates, action.payload);
    yield put(fetchStatesSuccess(fetchedStates));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getCities = async function (params) {
  let cities = [];
  try {
    const response = await axios.get("/area/cities", { params });
    cities = response.data;
  } catch (error) {
    console.log(error);
  }
  return cities;
};

function* fetchCitiesRequest(action) {
  try {
    const fetchedCities = yield call(getCities, action.payload);
    yield put(fetchCitiesSuccess(fetchedCities));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getAllCities = async function (params) {
  let cities = [];
  const response = await axios.get("/area/cities/all", { params });
  cities = response.data;
  return cities;
};

function* fetchAllCitiesRequest(action) {
  try {
    const fetchedCities = yield call(getAllCities, action.payload);
    yield put(fetchAllCitiesSuccess(fetchedCities));
  } catch (error) {
    NotificationManager.error(`Failed to fetch cities. ${error}`);
    yield put(fetchAllCitiesError());
    yield put(showAPIMessage(error));
  }
}

const getZones = async function (params) {
  let zones = [];
  try {
    const response = await axios.get("/area/zones", { params });
    zones = response.data;
  } catch (error) {
    console.log(error);
  }
  return zones;
};

function* fetchZonesRequest(action) {
  try {
    const fetchedZones = yield call(getZones, action.payload);
    yield put(fetchZonesSuccess(fetchedZones));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const fetchZonesByCityCode = async cityCodes => {
  return (await workForceAxios.post(`/area/getAreaZonesByCities`, { cityCodes }))
    .data;
}

function* fetchZonesByCityCodeRequest(action) {
  try {
    const res = yield call(fetchZonesByCityCode, action.payload);
    if (res?.success) {
      yield put(fetchZonesByCityCodeSuccess(res.body));
    } else {
      NotificationManager.error(res?.message || "Failed to fetch city's zones");
      yield put(fetchZonesByCityCodeError());
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(fetchZonesByCityCodeError());
  }
}

const getStatuses = async function (params) {
  let statuses = [];
  try {
    const response = await axios.get("/area/statuses", { params });
    statuses = response.data;
  } catch (error) {
    console.log(error);
  }
  return statuses;
};

function* fetchStatusesRequest(action) {
  try {
    const fetchedStatuses = yield call(getStatuses, action.payload);
    yield put(fetchStatusesSuccess(fetchedStatuses));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

const getAreaZoneByCityCodes = async (cityCodes) => {
  let zones = [];
  try {
    if (Array.isArray(cityCodes)) {
      const response = await axios.post("/area/getAreaZonesByCities", {
        cityCodes
      });
      const data = response.data;
      if (!data.success) {
        NotificationManager.error("Failed: " + data.message, '', 10000);
      }
      zones = data.body;
    }
  } catch (error) {
    console.log(error);
  }

  return zones;
}

function* fetchAreaZonesByCity(action) {
  try {
    const fetchedAreaZones = yield call(getAreaZoneByCityCodes, action.payload);
    yield put(fetchZonesSuccess(fetchedAreaZones));
  } catch (error) {
    yield put(showAPIMessage(error))
  }
}

const getAreaZoneByCityName = async (cityName) => {
  let zones = [];
  try {
    const response = await axios.post("/area/getAreaZoneByCity", {
      cityName
    });
    const data = response.data;
    if (!data.success) {
      NotificationManager.error("Failed: " + data.message, '', 10000);
    }
    zones = data.body;

  } catch (error) {
    console.log(error);
  }

  return zones;
}

function* fetchAreaZonesByCityName(action) {
  try {
    const fetchedAreaZones = yield call(getAreaZoneByCityName, action.payload);
    yield put(fetchZonesSuccess(fetchedAreaZones));
  } catch (error) {
    yield put(showAPIMessage(error))
  }
}

//cityWiseCategoryCustomSlots
const getCityWiseCategoryCustomSlots = async (cityName) => {
  let zones = [];
  try {
    const url = `/slot/zoneWiseCategoryCustomSlotsForCity?cityCode=${cityName}`;
    const response = await axios.get(url);
    const data = response.data;
    if (!data.success) {
      NotificationManager.error("Failed: " + data.message, '', 3000);
    }
    zones = data.body;

  } catch (error) {
    console.log(error);
  }

  return zones;
}

function* fetchCityWiseCategoryCustomSlots(action) {
  try {
    const fetchedCityWiseCategoryCustomSlots = yield call(getCityWiseCategoryCustomSlots, action.payload);
    yield put(fetchCustomSlotsSuccess(fetchedCityWiseCategoryCustomSlots));
  } catch (error) {
    yield put(showAPIMessage(error))
  }
}

export function* fetchAreasData() {
  yield takeLatest(FETCH_ALL_AREA, fetchAreasRequest);
  yield takeLatest(FETCH_AREA, fetchAreaRequest);
  yield takeLatest(FETCH_STATES, fetchStatesRequest);
  yield takeLatest(FETCH_CITIES, fetchCitiesRequest);
  yield takeLatest(FETCH_ALL_CITIES, fetchAllCitiesRequest);
  yield takeLatest(FETCH_STATUSES, fetchStatusesRequest);
  yield takeLatest(FETCH_ZONES, fetchZonesRequest);
  yield takeLatest(FETCH_ZONES_BY_CITY_CODE_REQUEST, fetchZonesByCityCodeRequest);
  yield takeLatest(ADD_AREA, addAreaRequest);
  yield takeLatest(UPDATE_AREA, updateAreaRequest);
  yield takeLatest(FETCH_AREA_ZONES_BY_CITY, fetchAreaZonesByCity);
  yield takeLatest(FETCH_AREA_ZONES_BY_CITY_NAME, fetchAreaZonesByCityName)
  yield takeLatest(FETCH_CUSTOM_SLOT_BY_CITY, fetchCityWiseCategoryCustomSlots)
}

export default function* rootSaga() {
  yield all([fork(fetchAreasData)]);
}

import {
  FETCH_ALL_CUSTOMER,
  FETCH_ALL_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_ADDRESS,
  FETCH_CUSTOMER_ADDRESS_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_INSTALLATION_STATUS,
  FETCH_INSTALLATION_STATUS_ERROR,
  FETCH_INSTALLATION_STATUS_SUCCESS,
  FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_ERROR,
  FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_REQUEST,
  FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_SUCCESS,
  FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_ERROR,
  FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_REQUEST,
  FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_SUCCESS,
  FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_ERROR,
  FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_REQUEST,
  FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_SUCCESS,
  FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_ERROR,
  FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_REQUEST,
  FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_SUCCESS,
  FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_ERROR,
  FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_REQUEST,
  FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_SUCCESS,
  FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_SUCCESS,
  FETCH_PHP_MONTHLY_INSTALLATIONS_SUCCESS,
  FETCH_PHP_MONTHLY_STATS_SUCCESS,
  FETCH_PHP_PURIFIER_VALIDITY_DETAILS_SUCCESS,
  FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_SUCCESS,
  FETCH_REWARDS_SUCCESS,
  SHOW_API_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
  customers: [],
  address: [],
  installation_statuses: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CUSTOMER: {
      return {
        ...state,
        loader: true,
      };
    }

    case FETCH_ALL_CUSTOMER_SUCCESS: {
      return {
        ...state,
        allCustomers: action.payload,
        customers: action.payload.customers,
        totalCount: action.payload.total,
        loader: false,
      };
    }
    case FETCH_CUSTOMER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_CUSTOMER_ADDRESS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_CUSTOMER_ADDRESS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_PHP_PURIFIER_VALIDITY_DETAILS_SUCCESS: {
      return {
        ...state,
        activePurifiers: action.payload.activePurifiers.body.activepurifiers,
        loader: false,
      };
    }
    case FETCH_PHP_MONTHLY_INSTALLATIONS_SUCCESS: {
      return {
        ...state,
        monthlyInstallations: action.payload.monthlyInstallations.body,
        loader: false,
      };
    }
    case FETCH_PHP_MONTHLY_STATS_SUCCESS: {
      return {
        ...state,
        monthlyStats: action.payload.monthlyStats.body,
        loader: false,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_REQUEST: {
      return {
        ...state,
        customersYearlyReport: [],
        customersYearlyReportLoader: true,
        customersYearlyReportError: false,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_SUCCESS: {
      return {
        ...state,
        customersYearlyReport: action.payload || [],
        customersYearlyReportLoader: false,
        customersYearlyReportError: false,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_YEARLY_REPORT_ERROR: {
      return {
        ...state,
        customersYearlyReport: [],
        customersYearlyReportLoader: false,
        customersYearlyReportError: true,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_REQUEST: {
      return {
        ...state,
        customersMonthlyReport: [],
        customersMonthlyReportLoader: true,
        customersMonthlyReportError: false,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_SUCCESS: {
      return {
        ...state,
        customersMonthlyReport: action.payload || [],
        customersMonthlyReportLoader: false,
        customersMonthlyReportError: false,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_MONTHLY_REPORT_ERROR: {
      return {
        ...state,
        customersMonthlyReport: [],
        customersMonthlyReportLoader: false,
        customersMonthlyReportError: true,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_REQUEST: {
      return {
        ...state,
        customersWeeklyReport: [],
        customersWeeklyReportLoader: true,
        customersWeeklyReportError: false,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_SUCCESS: {
      return {
        ...state,
        customersWeeklyReport: action.payload || [],
        customersWeeklyReportLoader: false,
        customersWeeklyReportError: false,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_WEEKLY_REPORT_ERROR: {
      return {
        ...state,
        customersWeeklyReport: [],
        customersWeeklyReportLoader: false,
        customersWeeklyReportError: true,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_REQUEST: {
      return {
        ...state,
        customersDailyReport: [],
        customersDailyReportLoader: true,
        customersDailyReportError: false,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_SUCCESS: {
      return {
        ...state,
        customersDailyReport: action.payload || [],
        customersDailyReportLoader: false,
        customersDailyReportError: false,
      };
    }
    case FETCH_NEW_DB_CUSTOMERS_DAILY_REPORT_ERROR: {
      return {
        ...state,
        customersDailyReport: [],
        customersDailyReportLoader: false,
        customersDailyReportError: true,
      };
    }
    case FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_REQUEST: {
      return {
        ...state,
        deviceCountLoader: true,
        deviceCount: {},
        deviceCountError: false,
      };
    }
    case FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_SUCCESS: {
      return {
        ...state,
        deviceCountLoader: false,
        deviceCount: action?.payload,
        deviceCountError: false,
      };
    }
    case FETCH_NEW_DB_DEVICE_COUNTS_BY_STATUSES_ERROR: {
      return {
        ...state,
        deviceCountLoader: false,
        deviceCount: {},
        deviceCountError: true,
      };
    }
    case FETCH_REWARDS_SUCCESS: {
      return {
        ...state,
        rewards: action.payload.rewards.body,
        loader: false,
      };
    }
    case FETCH_PHP_DAILY_INSTALLATIONS_DETAILS_SUCCESS: {
      return {
        ...state,
        dailyInstallations: action.payload.dailyInstallations.body,
        loader: false,
      };
    }
    case FETCH_PHP_WEEKLY_INSTALLATIONS_DETAILS_SUCCESS: {
      return {
        ...state,
        weeklyInstallations: action.payload.weeklyInstallations.body,
        loader: false,
      };
    }
    case FETCH_INSTALLATION_STATUS: {
      return {
        ...state,
        installation_statuses: action.payload,
        loader: false,
      };
    }
    case FETCH_INSTALLATION_STATUS_ERROR: {
      return {
        ...state,
        installation_statuses: [],
        loader: false,
      };
    }
    case FETCH_INSTALLATION_STATUS_SUCCESS: {
      return {
        ...state,
        installation_statuses: action.payload,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }

    default:
      return state;
  }
};

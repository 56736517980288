import {
  FETCH_B2B_INSTALLATION_BILLS_REQUEST,
  FETCH_B2B_INSTALLATION_BILLS_RESPONSE,
  FETCH_BUSSINESS_PARTNERS_BILLS,
  FETCH_BUSSINESS_PARTNERS_BILLS_REQUEST,
  FETCH_BUSSINESS_PARTNERS_REQUEST,
  FETCH_BUSSINESS_PARTNERS_RESPONSE,
  FETCH_BUSSINESS_PARTNER_BILL_DETAILS_REQUEST,
  FETCH_BUSSINESS_PARTNER_BILL_DETAILS_RESPONSE,
  POST_B2B_BILL_PAYMENT_REQUEST,
  POST_B2B_BILL_PAYMENT_RESPONSE,
  POST_B2B_BILL_PAYMENT_FAILED,
  RECHARGE_B2B_PAYMENT,
  RECHARGE_B2B_PAYMENT_FAILURE,
  RECHARGE_B2B_PAYMENT_REQUESTED,
  RECHARGE_B2B_PAYMENT_RESET,
  RECHARGE_B2B_PAYMENT_SUCCESS,
  SHOW_API_MESSAGE,
  FETCH_B2B_BILL_PAYMENT_TYPE_REQUEST,
  FETCH_B2B_BILL_PAYMENT_TYPE_SUCCESS,
  FETCH_B2B_BILL_PAYMENT_TYPE_ERROR,
  FETCH_B2B_BILL_TRANSACTIONS_REQUEST,
  FETCH_B2B_BILL_TRANSACTIONS_SUCCESS,
  FETCH_B2B_BILL_TRANSACTIONS_ERROR,
  POST_CHANGE_INSTALLATION_PARTNER_REQUEST,
  POST_CHANGE_INSTALLATION_PARTNER_SUCCESS,
  POST_CHANGE_INSTALLATION_PARTNER_ERROR,
  CREATE_NEW_BUSSINESS_PARTNERS_REQ,
  CREATE_NEW_BUSSINESS_PARTNERS_SUCCESS,
  CREATE_NEW_BUSSINESS_PARTNERS_ERROR,
} from "constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  data: {
    purifier_ids: [],
    payment_type: "",
    payment_mode: "AccountTransfer",
    plan: "CoupleB2B",
    payment_reference: "",
    amount: 0.0,
    validity: "",
    liters: 0,
    recharge_period: "",
  },
  error: false,
  partnersPage: {
    partners: [],
  },
  billsPage: {
    bills: [],
  },
};

export default (state = INIT_STATE, action ) => {
    switch (action.type) {
      case RECHARGE_B2B_PAYMENT: {
        return { ...state, loading: false, error: false, ...action.payload };
      }
      case RECHARGE_B2B_PAYMENT_REQUESTED: {
        return { ...state, loading: true, ...action.payload };
      }
      case RECHARGE_B2B_PAYMENT_SUCCESS: {
        return {
          ...INIT_STATE,
        };
      }
      case RECHARGE_B2B_PAYMENT_FAILURE: {
        return { ...state, loading: false, error: true };
      }
      case RECHARGE_B2B_PAYMENT_RESET: {
        return { ...INIT_STATE };
      }
      case CREATE_NEW_BUSSINESS_PARTNERS_REQ: {
        return {
          ...state,
          newBussinessPartners: [],
          createNewBussinessPartnersLoader: true,
          createNewBussinessPartnersError: false,
        };
      }
      case CREATE_NEW_BUSSINESS_PARTNERS_SUCCESS: {
        return {
          ...state,
          newBussinessPartners: action.payload,
          createNewBussinessPartnersLoader: false,
          createNewBussinessPartnersError: false,
        };
      }
      case CREATE_NEW_BUSSINESS_PARTNERS_ERROR: {
        return {
          ...state,
          newBussinessPartners: [],
          createNewBussinessPartnersLoader: false,
          createNewBussinessPartnersError: true,
        };
      }
      case FETCH_BUSSINESS_PARTNERS_REQUEST: {
        return {
          ...state,
          partnersPage: {
            partners: [],
          },
          loading: true,
        };
      }
      case FETCH_BUSSINESS_PARTNERS_RESPONSE: {
        return {
          ...state,
          partnersPage: { ...action.payload },
          loading: false,
        };
      }
      case FETCH_BUSSINESS_PARTNERS_BILLS_REQUEST: {
        return {
          ...state,
          billsPage: {},
          loading: true,
        };
      }
      case FETCH_BUSSINESS_PARTNERS_BILLS: {
        return {
          ...state,
          billsPage: { ...action.payload },
          loading: false,
        };
      }
      case FETCH_BUSSINESS_PARTNER_BILL_DETAILS_REQUEST: {
        return {
          ...state,
          businessPartnerBillDetails: {},
          fetchB2BOartnerBillDetailsLoader: true,
        };
      }
      case FETCH_BUSSINESS_PARTNER_BILL_DETAILS_RESPONSE: {
        return {
          ...state,
          businessPartnerBillDetails: { ...action.payload },
          fetchB2BOartnerBillDetailsLoader: false,
        };
      }
      case POST_B2B_BILL_PAYMENT_REQUEST: {
        return {
          ...state,
          makeBillPaymentResponse: null,
          markBillAsPaidLoader: true,
        };
      }
      case POST_B2B_BILL_PAYMENT_RESPONSE: {
        return {
          ...state,
          makeBillPaymentResponse: action.payload,
          markBillAsPaidLoader: false,
        };
      }
      case POST_B2B_BILL_PAYMENT_FAILED: {
        return {
          ...state,
          makeBillPaymentResponse: action.payload,
          billPaymentError: true,
          markBillAsPaidLoader: false,
        };
      }
      case FETCH_B2B_INSTALLATION_BILLS_REQUEST: {
        return {
          ...state,
          b2bInstallationBills: null,
          installationBillsLoader: true,
        };
      }
      case FETCH_B2B_INSTALLATION_BILLS_RESPONSE: {
        return {
          ...state,
          b2bInstallationBills: action.payload,
          installationBillsLoader: false,
        };
      }
      case FETCH_B2B_BILL_PAYMENT_TYPE_REQUEST: {
        return {
          ...state,
          b2bBillPaymentModes: [],
          b2bBillPaymentTypeLoader: true,
          b2bBillPaymentTypeError: false,
        };
      }
      case FETCH_B2B_BILL_PAYMENT_TYPE_SUCCESS: {
        return {
          ...state,
          b2bBillPaymentModes: action.payload,
          b2bBillPaymentTypeLoader: false,
          b2bBillPaymentTypeError: false,
        };
      }
      case FETCH_B2B_BILL_PAYMENT_TYPE_ERROR: {
        return {
          ...state,
          b2bBillPaymentModes: [],
          b2bBillPaymentTypeLoader: false,
          b2bBillPaymentTypeError: true,
        };
      }
      case FETCH_B2B_BILL_TRANSACTIONS_REQUEST: {
        return {
          ...state,
          b2bBillTransactions: [],
          b2bBillTransactionLoader: true,
          b2bBillTransactionError: false,
        };
      }
      case FETCH_B2B_BILL_TRANSACTIONS_SUCCESS: {
        return {
          ...state,
          b2bBillTransactions: action.payload,
          b2bBillTransactionLoader: false,
          b2bBillTransactionError: false,
        };
      }
      case FETCH_B2B_BILL_TRANSACTIONS_ERROR: {
        return {
          ...state,
          b2bBillTransactions: [],
          b2bBillTransactionLoader: false,
          b2bBillTransactionError: true,
        };
      }
      case POST_CHANGE_INSTALLATION_PARTNER_REQUEST: {
        return {
          ...state,
          changeInstPartnerLoader: true,
          changeInstPartnerError: false,
        };
      }
      case POST_CHANGE_INSTALLATION_PARTNER_SUCCESS: {
        return {
          ...state,
          changeInstPartnerLoader: false,
          changeInstPartnerError: false,
        };
      }
      case POST_CHANGE_INSTALLATION_PARTNER_ERROR: {
        return {
          ...state,
          changeInstPartnerLoader: false,
          changeInstPartnerError: true,
        };
      }
      case SHOW_API_MESSAGE: {
        return {
          ...state,
          alertMessage: action.payload,
          showMessage: true,
          loader: false,
        };
      }
      default:
        return state;
    }
}
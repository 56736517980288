import { FETCH_DBUI_FORM_ERROR, FETCH_DBUI_FORM_REQUEST, FETCH_DBUI_FORM_SUCCESS } from "constants/ActionTypes";

const INIT_STATE = {
  dbuiFormLoader: true,
  dbuiFormError: false,
  dbuiFormModel: {},
};

export default (state = INIT_STATE, action) => { 
    switch (action.type) {
      case FETCH_DBUI_FORM_REQUEST: {
        return {
          dbuiFormLoader: true,
          dbuiFormError: false,
          dbuiFormModel: {},
        };
      }
      case FETCH_DBUI_FORM_SUCCESS: {
        return {
          dbuiFormLoader: false,
          dbuiFormError: false,
          dbuiFormModel: action.payload,
        };
      }
      case FETCH_DBUI_FORM_ERROR: {
        return { dbuiFormLoader: false, dbuiFormError: true, dbuiFormModel: {} };
      }
      default:
        return state;
    }
}


import { fetchAvailableItemsError, fetchAvailableItemsSuccess, fetchEmployeeInventory, fetchEmployeeInventoryError, fetchEmployeeInventorySuccess, fetchEmployeeWarehouseSuccess, fetchIssuanceHistorySuccess, fetchIssuanceJobsSuccess, fetchIssuancePackageInfoSuccess, setIsLoading, showAPIMessage } from "actions/EmployeeInventoryActions";
import { ALLOCATE_TO_EMPLOYEE, CONFIRM_NON_SERIALIZE_ITEM_RETURN, CONFIRM_RETURN_ITEM, FETCH_AVAILABLE_ITEMS, FETCH_EMPLOYEE_INVENTORY, FETCH_EMPLOYEE_ISSUANCE_HISTORY, FETCH_EMPLOYEE_WAREHOUSE, FETCH_ISSUANCE_JOBS, FETCH_ISSUANCE_PACKAGE_INFO, REMOVE_NONPICKED_ITEMS, REMOVE_PICKED_ITEMS } from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios } from "util/Api";

// inventory
const fetchEmployeeInventoryf = async (params) => {
  let inventoryData = {
    part_info: [],
    return_info: [],
    employee_first_name: "",
    employee_last_name: ""
  };
  try {
    const response = await axios.get("/employee/inventory/" + params["id"]);
    inventoryData = { ...response.data };
  } catch (error) {
    console.log(error);
  }
  return inventoryData;
};

function* fetchEmployeeInventoryRequest(action) {
  try {
    yield put(setIsLoading(true));
    const fetchedInventory = yield call(fetchEmployeeInventoryf, action.payload);
    yield put(fetchEmployeeInventorySuccess(fetchedInventory));
  } catch (error) {
    yield put(fetchEmployeeInventoryError(error.message));
  }
}

const fetchEmployeeWarehouse = async (params) => {
  let data;
  try {
    const response = await axios.get("/employee/employeeWarehouseDetails/" + params["id"]);
    if (response.status === 200) {
      data = response.data?.body
    } else {
      throw response
    }
  } catch (error) {
    console.log(error);
  }
  return data;
};

function* fetchEmployeeWarehouseRequest(action) {
  try {
    const response = yield call(fetchEmployeeWarehouse, action.payload);
    yield put(fetchEmployeeWarehouseSuccess(response));
  } catch (error) {
    console.log(error);
  }
}

// available items for delivery agents
const fetchAvailableItems = async () => {
  let items = []
  try {
    let res = await axios.get('/inventory/getItemsForDeliveryAgent')
    items = res.data.body
  } catch (error) {
    console.log(error)
  }
  return items;
}

function* fetchAvailableItemsRequest() {
  try {
    const fetchedItems = yield call(fetchAvailableItems)
    yield put(fetchAvailableItemsSuccess(fetchedItems))
  } catch (error) {
    yield put(fetchAvailableItemsError(error.message))
  }
}

// post request 
const allocateToEmployee = async (params) => {
  try {
    let res = await axios.post(`/inventory/allocateToEmployee`, { ...params })
    return res.data
  } catch (error) {
    throw error
  }
}
function* allocateToEmployeeRequest(action) {
  try {
    const response = yield call(allocateToEmployee, action.payload);
    yield all([put(fetchEmployeeInventory(action.payload["employee_id"]))]);
    if (response.success) {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Allocated To Employee successfully");
    } else {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.error(`Failed:${response.message.includes("NegativeStockError") ? "Allocated quantities for this item is not available in ERPNext." : response.message}`, '', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    NotificationManager.error("Failed to allocate");
  }
}
const removeNonPickedItems = async (params) => {
  try {
    let res = await axios.post(`/inventory/removeNonPickedUpItems`, { ...params })
    return res.data
  } catch (error) {
    throw error
  }
}
function* removeNonPickedItemsRequest(action) {
  try {
    const response = yield call(removeNonPickedItems, action.payload);
    yield all([put(fetchEmployeeInventory(action.payload["employee_id"]))]);
    if (response.success) {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Item Removed successfully");
    } else {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.error("Failed: " + response.message, '', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    NotificationManager.error("Failed to remove item");
  }
}
const removePickedItems = async (params) => {
  try {
    let res = await axios.post(`/inventory/removePickedUpItems`, { ...params })
    return res.data
  } catch (error) {
    throw error
  }
}
function* removePickedItemsRequest(action) {
  try {
    const response = yield call(removePickedItems, action.payload);
    yield all([put(fetchEmployeeInventory(action.payload["employee_id"]))]);
    if (response.success) {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Item removed successfully");
    } else {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.error("Failed: " + response.message, '', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    NotificationManager.error("Failed to pick item");
  }
}

const confirmNonSerializeItemReturn = async (params) => {
  try {
    let res = await axios.post(`/inventory/confirmNonSerializeItemReturn`, { ...params })
    return res.data
  } catch (error) {
    throw error
  }
}
function* confirmNonSerializeItemReturnRequest(action) {
  try {
    const response = yield call(confirmNonSerializeItemReturn, action.payload);
    yield all([put(fetchEmployeeInventory(action.payload["employee_id"]))]);
    if (response.success) {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Item Returned successfully");
    } else {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.error("Failed: " + response.message, '', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    NotificationManager.error("Failed to return item");
  }
}

const confirmReturnForItem = async (params) => {
  try {
    let res = await axios.post(`/inventory/confirmReturnForItem`, { ...params })
    return res.data
  } catch (error) {
    throw error
  }
}
function* confirmReturnForItemRequest(action) {
  try {
    const response = yield call(confirmReturnForItem, action.payload);
    yield all([put(fetchEmployeeInventory(action.payload["employee_id"]))]);
    if (response.success) {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.success("Item returned successfully");
    } else {
      NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
      NotificationManager.error("Failed: " + response.message, '', 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
    NotificationManager.error("Failed to pick item");
  }
}

const fetchEmployeeIssuanceHistory = async (id) => {
  try {
    const response = await axios.get(`/spare/issuanceHistory/${id}`)
    if (response.status === 200) {
      return response.data?.body
    } else {
      throw response
    }
  } catch (error) {
    console.log(error);
    NotificationManager.error("Couldn't fetch issuance history for the employee");
    return null
  }
}

function* fetchEmployeeIssuanceHistoryRequest(action) {
  try {
    const response = yield call(fetchEmployeeIssuanceHistory, action.payload);
    yield put(fetchIssuanceHistorySuccess(response));
  } catch (error) {
    console.log(error);
    yield put(showAPIMessage(error));
  }
}

const fetchIssuancePackageInfo = async (id) => {
  try {
    const response = await axios.get(`/spare/issuance/${id}`)
    if (response.status === 200) {
      return response.data?.body
    } else {
      throw response
    }
  } catch (error) {
    console.log(error);
    NotificationManager.error("Couldn't fetch issuance info");
    return null
  }
}

function* fetchIssuancePackageInfoRequest(action) {
  try {
    const response = yield call(fetchIssuancePackageInfo, action.payload);
    yield put(fetchIssuancePackageInfoSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(showAPIMessage(error));
  }
}

const fetchIssuanceJobs = async (id) => {
  try {
    const response = await axios.get(`/spare/issuanceJobs/${id}`)
    if (response.status === 200) {
      return response.data?.body
    } else {
      throw response
    }
  } catch (error) {
    console.log(error);
    NotificationManager.error("Couldn't fetch issuance jobs");
    return null
  }
}

function* fetchIssuanceJobsRequest(action) {
  try {
    const response = yield call(fetchIssuanceJobs, action.payload);
    yield put(fetchIssuanceJobsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(showAPIMessage(error));
  }
}

export function* fetchEmployeesInventoryData() {
  yield takeLatest(FETCH_EMPLOYEE_INVENTORY, fetchEmployeeInventoryRequest)
  yield takeLatest(FETCH_AVAILABLE_ITEMS, fetchAvailableItemsRequest)
  yield takeLatest(ALLOCATE_TO_EMPLOYEE, allocateToEmployeeRequest)
  yield takeLatest(REMOVE_NONPICKED_ITEMS, removeNonPickedItemsRequest)
  yield takeLatest(REMOVE_PICKED_ITEMS, removePickedItemsRequest)
  yield takeLatest(CONFIRM_RETURN_ITEM, confirmReturnForItemRequest)
  yield takeLatest(CONFIRM_NON_SERIALIZE_ITEM_RETURN, confirmNonSerializeItemReturnRequest)
  yield takeLatest(FETCH_EMPLOYEE_ISSUANCE_HISTORY, fetchEmployeeIssuanceHistoryRequest)
  yield takeLatest(FETCH_ISSUANCE_PACKAGE_INFO, fetchIssuancePackageInfoRequest)
  yield takeLatest(FETCH_ISSUANCE_JOBS, fetchIssuanceJobsRequest)
  yield takeLatest(FETCH_EMPLOYEE_WAREHOUSE, fetchEmployeeWarehouseRequest)
}

export default function* rootSaga() {
  yield all([fork(fetchEmployeesInventoryData)]);
}

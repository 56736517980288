import {
  ADD_NEW_SLOT_CATEGORY_ERROR,
  ADD_NEW_SLOT_CATEGORY_REQUEST,
  ADD_NEW_SLOT_CATEGORY_SUCCESS,
  ADD_OR_UPDATE_DISPLAY_RULE_ERROR,
  ADD_OR_UPDATE_DISPLAY_RULE_REQUEST,
  ADD_OR_UPDATE_DISPLAY_RULE_SUCCESS,
  ADD_SLOT_ZONE,
  ADD_SLOT_ZONE_ERROR,
  ADD_SLOT_ZONE_SUCCESS,
  ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_ERROR,
  ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST,
  ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_SUCCESS,
  ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_ERROR,
  ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST,
  ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_SUCCESS,
  DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_ERROR,
  DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST,
  DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_SUCCESS,
  FETCH_ALL_SLOT_CATEGORIES,
  FETCH_ALL_SLOT_CATEGORIES_ERROR,
  FETCH_ALL_SLOT_CATEGORIES_SUCCESS,
  FETCH_ALL_SLOT_ZONE,
  FETCH_ALL_SLOT_ZONE_SUCCESS,
  FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_ERROR,
  FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_REQUEST,
  FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_SUCCESS,
  FETCH_CUSTOM_SLOT_BY_CITY,
  FETCH_CUSTOM_SLOT_BY_CITY_SUCCESS,
  FETCH_DAYS,
  FETCH_DAYS_SUCCESS,
  FETCH_ISSUE_TYPES,
  FETCH_ISSUE_TYPES_SUCCESS,
  FETCH_SLOTS,
  FETCH_SLOTS_SUCCESS,
  FETCH_SLOT_CATEGORY_COUNT_BY_ZONE,
  FETCH_SLOT_CATEGORY_COUNT_BY_ZONE_SUCCESS,
  FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE,
  FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE_SUCCESS,
  FETCH_SLOT_ZONE,
  FETCH_SLOT_ZONE_ERROR,
  FETCH_SLOT_ZONE_SUCCESS,
  REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_ERROR,
  REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST,
  REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_SUCCESS,
  SEARCH_SLOT_DISPLAY_RULE_ERROR,
  SEARCH_SLOT_DISPLAY_RULE_REQUEST,
  SEARCH_SLOT_DISPLAY_RULE_SUCCESS,
  SHOW_API_MESSAGE,
  UPDATE_SLOT_CATEGORY_ERROR,
  UPDATE_SLOT_CATEGORY_REQUEST,
  UPDATE_SLOT_CATEGORY_SUCCESS,
  UPDATE_SLOT_ZONE,
} from "constants/ActionTypes";
import { SUCCESS } from "constants/uiStates";

export const fetchSlotZone = id => {
  return {
    type: FETCH_SLOT_ZONE,
    payload: { id },
  };
};

export const fetchSlotZones = payload => {
  return {
    type: FETCH_ALL_SLOT_ZONE,
    payload,
  };
};

export const fetchIssueTypes = payload => {
  return {
    type: FETCH_ISSUE_TYPES,
    payload,
  };
};

export const fetchDays = payload => {
  return {
    type: FETCH_DAYS,
    payload,
  };
};

export const fetchSlots = payload => {
  return {
    type: FETCH_SLOTS,
    payload,
  };
};

export const addSlotZone = slotZone => {
  return {
    type: ADD_SLOT_ZONE,
    payload: slotZone,
  };
};

export const updateSlotZone = slotZone => {
  return {
    type: UPDATE_SLOT_ZONE,
    payload: slotZone,
  };
};

export const fetchSlotZoneSuccess = slotZone => {
  return {
    type: FETCH_SLOT_ZONE_SUCCESS,
    payload: {
      uiState: SUCCESS,
      slotZone: slotZone,
    },
  };
};

export const fetchSlotZoneError = error => {
  return {
    type: FETCH_SLOT_ZONE_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const addSlotZoneSuccess = message => {
  return {
    type: ADD_SLOT_ZONE_SUCCESS,
    payload: {
      status: "",
      showNotification: true,
      type: "success",
      message,
    },
  };
};

export const addSlotZoneError = error => {
  return {
    type: ADD_SLOT_ZONE_ERROR,
    payload: {
      showNotification: true,
      message: error,
    },
  };
};

export const addNewSlotCategoryRequest = payload => {
  return {
    type: ADD_NEW_SLOT_CATEGORY_REQUEST,
    payload,
  };
};
export const addNewSlotCategorySuccess = newlyCreatedSlotCategory => {
  return {
    type: ADD_NEW_SLOT_CATEGORY_SUCCESS,
    payload: newlyCreatedSlotCategory,
  };
}
export const addNewSlotCategoryError = error => {
  return {
    type: ADD_NEW_SLOT_CATEGORY_ERROR,
    payload: error,
  };
};

export const updateSlotCategoryRequest = payload => {
  return {
    type: UPDATE_SLOT_CATEGORY_REQUEST,
    payload,
  };
};
export const updateSlotCategorySuccess = newlyCreatedSlotCategory => {
  return {
    type: UPDATE_SLOT_CATEGORY_SUCCESS,
    payload: newlyCreatedSlotCategory,
  };
};
export const updateSlotCategoryError = error => {
  return {
    type: UPDATE_SLOT_CATEGORY_ERROR,
    payload: error,
  };
};

export const searchSlotDisplayRulesRequest = payload => {
  return {
    type: SEARCH_SLOT_DISPLAY_RULE_REQUEST,
    payload,
  };
};
export const searchSlotDisplayRulesSuccess = payload => {
  return {
    type: SEARCH_SLOT_DISPLAY_RULE_SUCCESS,
    payload,
  };
};
export const searchSlotDisplayRulesError = payload => {
  return {
    type: SEARCH_SLOT_DISPLAY_RULE_ERROR,
    payload,
  };
};

export const addOrUpdateDisplayRuleRequest = payload => {
  return {
    type: ADD_OR_UPDATE_DISPLAY_RULE_REQUEST,
    payload,
  };
};
export const addOrUpdateDisplayRuleSuccess = payload => {
  return {
    type: ADD_OR_UPDATE_DISPLAY_RULE_SUCCESS,
    payload,
  };
};
export const addOrUpdateDisplayRuleError = payload => {
  return {
    type: ADD_OR_UPDATE_DISPLAY_RULE_ERROR,
    payload,
  };
};

export const fetchSlotZonesSuccess = slotZone => {
  return {
    type: FETCH_ALL_SLOT_ZONE_SUCCESS,
    payload: slotZone,
  };
};

export const fetchIssueTypesSuccess = issueTypes => {
  return {
    type: FETCH_ISSUE_TYPES_SUCCESS,
    payload: issueTypes,
  };
};

export const fetchDaysSuccess = days => {
  return {
    type: FETCH_DAYS_SUCCESS,
    payload: days,
  };
};

export const fetchSlotsSuccess = slots => {
  return {
    type: FETCH_SLOTS_SUCCESS,
    payload: slots,
  };
};

export const fetchSlotCountByZoneAndIssue = slotCount => {
  return {
    type: FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE,
    payload: slotCount,
  };
};

export const fetchSlotCountByZoneAndIssueSuccess = slotCount => {
  return {
    type: FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE_SUCCESS,
    payload: slotCount,
  };
};

export const showAPIMessage = message => {
  return {
    type: SHOW_API_MESSAGE,
    payload: message,
  };
};

export const fetchSlotCategoryCountByZones = payload => {
  return {
    type: FETCH_SLOT_CATEGORY_COUNT_BY_ZONE,
    payload: payload,
  }
}

export const fetchSlotCategoryCountByZonesSuccess = payload => {
  return {
    type: FETCH_SLOT_CATEGORY_COUNT_BY_ZONE_SUCCESS,
    payload: payload
  }
}

export const fetchAllCategories = payload => {
  return {
    type: FETCH_ALL_SLOT_CATEGORIES,
    payload: payload
  }
}

export const fetchAllCategoriesSuccess = payload => {
  return {
    type: FETCH_ALL_SLOT_CATEGORIES_SUCCESS,
    payload: payload
  }
}

export const fetchCustomSlots = payload => {
  return {
    type: FETCH_CUSTOM_SLOT_BY_CITY,
    payload: payload
  }
}

export const fetchCustomSlotsSuccess = payload => {
  return {
    type: FETCH_CUSTOM_SLOT_BY_CITY_SUCCESS,
    payload: payload
  }
}

export const fetchAllCategoriesError = payload => {
  return {
    type: FETCH_ALL_SLOT_CATEGORIES_ERROR,
    payload,
  };
};

export const assignJobTagsToSlotCategoryRequest = payload => {
  return {
    type: ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST,
    payload,
  }
}

export const assignJobTagsToSlotCategorySuccess = payload => {
  return {
    type: ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_SUCCESS,
    payload,
  };
};

export const assignJobTagsToSlotCategoryError = payload => {
  return {
    type: ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_ERROR,
    payload,
  };
};

export const removeJobTagsToSlotCategoryRequest = payload => {
  return {
    type: REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST,
    payload,
  };
};

export const removeJobTagsToSlotCategorySuccess = payload => {
  return {
    type: REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_SUCCESS,
    payload,
  };
};

export const removeJobTagsToSlotCategoryError = payload => {
  return {
    type: REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_ERROR,
    payload,
  };
};

export const fetchAssignedZonesToSlotCategoryRequest = payload => {
  return {
    type: FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_REQUEST,
    payload,
  };
}

export const fetchAssignedZonesToSlotCategorySuccess = payload => {
  return {
    type: FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_SUCCESS,
    payload,
  };
};

export const fetchAssignedZonesToSlotCategoryError = payload => {
  return {
    type: FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_ERROR,
    payload,
  };
};

export const assignZonesAndSlotsToSlotCategoryRequest = payload => {
  return {
    type: ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST,
    payload,
  }
}

export const assignZonesAndSlotsToSlotCategorySuccess = payload => {
  return {
    type: ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_SUCCESS,
    payload,
  };
};

export const assignZonesAndSlotsToSlotCategoryError = payload => {
  return {
    type: ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_ERROR,
    payload,
  };
};

export const disableZonesAndSlotsToSlotCategoryRequest = payload => {
  return {
    type: DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST,
    payload,
  };
};

export const disableZonesAndSlotsToSlotCategorySuccess = payload => {
  return {
    type: DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_SUCCESS,
    payload,
  };
};

export const disableZonesAndSlotsToSlotCategoryError = payload => {
  return {
    type: DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_ERROR,
    payload,
  };
};
import { createNewDeviceCodePrefixError, createNewDeviceCodePrefixSuccess, fetchAllDeviceCodePrefixError, fetchAllDeviceCodePrefixSuccess, fetchDeviceCodeStatuesError, fetchDeviceCodeStatuesSuccess, generateNewDeviceCodesError, generateNewDeviceCodesSuccess, searchDeviceCodesError, searchDeviceCodesSuccess } from "actions/DeviceCodeAction";
import { CREATE_NEW_DEVICE_CODE_PREFIX_REQUEST, FETCH_ALL_DEVICE_CODE_PREFIX_REQUEST, FETCH_DEVICE_CODE_STATUES_REQUEST, GENERATE_NEW_DEVICE_CODES_REQUEST, SEARCH_DEVICE_CODES_REQUEST, UPDATE_DEVICE_CODES_STATUS_REQUEST } from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { isSuccessfulStatus, sponsorAxios } from "util/Api";

// API Callers:
const fetchAllDeviceCodePrefix = async payload => {
    return await sponsorAxios.get("/device/code/prefix/all");
};

const createNewDeviceCodePrefix = async payload => {
    return await sponsorAxios.post("/device/code/prefix", payload);
}

const generateNewDeviceCodes = async payload => {
    return await sponsorAxios.post("/device/code/generate", payload);
}

const searchDeviceCodes = async payload => {
    return await sponsorAxios.post("/device/code/search", payload);
}

const updateDeviceCodesStatus = async ({ newStatus, deviceCodeIds }) => {
  return await sponsorAxios.put(
    `/device/code/status/${newStatus}`,
    deviceCodeIds
  );
};

const fetchDeviceCodeStatues = async () => {
    return await sponsorAxios.get("/device/code/statues");
}

// Dispatchers:
function* fetchAllDeviceCodePrefixRequest(action) {
    const failureMsg = "Failed to fetch Device Code Prefix!!";
    try {
        const response = yield call(fetchAllDeviceCodePrefix, action.payload);
        if (isSuccessfulStatus(response?.status) && response.data.success) {
            yield put(fetchAllDeviceCodePrefixSuccess(response.data.body));
        } else {
            NotificationManager.error(response.message || failureMsg);
            yield put(fetchAllDeviceCodePrefixError(null));
        }
    } catch (error) {
        NotificationManager.error(error.message);
        yield put(fetchAllDeviceCodePrefixError(null));
    }
}

function* createNewDeviceCodePrefixRequest(action) {
    const failureMsg = "Failed to create new Device Code Prefix!!";
    const successMessage = "Added a new Device Code Prefix!!";
    try {
        const response = yield call(createNewDeviceCodePrefix, action.payload);
        if (isSuccessfulStatus(response?.status) && response.data.success) {
            NotificationManager.success(response.message || successMessage);
            yield put(createNewDeviceCodePrefixSuccess(response.data.body));
        } else {
            NotificationManager.error(response.message || failureMsg);
            yield put(createNewDeviceCodePrefixError(null));
        }
    } catch (error) {
        NotificationManager.error(error.message);
        yield put(createNewDeviceCodePrefixError(null));
    }
}

function* generateNewDeviceCodesRequest(action) {
    const failureMsg = "Failed to generate new device codes!!";
    const successMessage = "Generated new device codes!!";
    try {
        const response = yield call(generateNewDeviceCodes, action.payload);
        if (isSuccessfulStatus(response?.status) && response.data.success) {
            NotificationManager.success(response.message || successMessage);
            yield put(generateNewDeviceCodesSuccess(response.data.body));
        } else {
            NotificationManager.error(response.message || failureMsg);
            yield put(generateNewDeviceCodesError(null));
        }
    } catch (error) {
        NotificationManager.error(error.message);
        yield put(generateNewDeviceCodesError(null));
    }
}

function* updateDeviceCodesStatusRequest(action) {
    const failureMsg = "Failed to update device code statuses!!";
    try {
        const response = yield call(updateDeviceCodesStatus, action.payload);
        if (!isSuccessfulStatus(response?.status) && response.data.success) {
            NotificationManager.error(failureMsg);
        }
    } catch (error) {
        NotificationManager.error(error.message);
    }
}

function* searchDeviceCodesRequest(action) {
    const failureMsg = "Failed to search device codes!!";
    try {
        const response = yield call(searchDeviceCodes, action.payload);
        if (isSuccessfulStatus(response?.status) && response.data.success) {
            yield put(searchDeviceCodesSuccess(response.data.body));
        } else {
            yield put(searchDeviceCodesError(response.message || failureMsg));
        }
    } catch (error) {
        NotificationManager.error(error.message);
        yield put(searchDeviceCodesError(failureMsg));
    }
}

function* fetchDeviceCodeStatuesRequest(action) {
     const failureMsg = "Failed to fetch device code statues!!";
    try {
        const response = yield call(fetchDeviceCodeStatues, action.payload);
        if (isSuccessfulStatus(response?.status) && response.data.success) {
            yield put(fetchDeviceCodeStatuesSuccess(response.data.body));
        } else {
            yield put(fetchDeviceCodeStatuesError(response.message || failureMsg));
        }
    } catch (error) {
        NotificationManager.error(error.message);
        yield put(fetchDeviceCodeStatuesError(failureMsg));
    }
}

export function* fetchDeviceCodeData() { 
    yield takeLatest(FETCH_ALL_DEVICE_CODE_PREFIX_REQUEST, fetchAllDeviceCodePrefixRequest);
    yield takeLatest(CREATE_NEW_DEVICE_CODE_PREFIX_REQUEST, createNewDeviceCodePrefixRequest);
    yield takeLatest(GENERATE_NEW_DEVICE_CODES_REQUEST, generateNewDeviceCodesRequest);
    yield takeLatest(UPDATE_DEVICE_CODES_STATUS_REQUEST, updateDeviceCodesStatusRequest);
    yield takeLatest(SEARCH_DEVICE_CODES_REQUEST, searchDeviceCodesRequest);
    yield takeLatest(FETCH_DEVICE_CODE_STATUES_REQUEST, fetchDeviceCodeStatuesRequest);
}

export default function* deviceCodeAPI() {
  yield all([fork(fetchDeviceCodeData)]);
}

import {
  CREATE_SPARE_TRANSFER_JOB_REQUEST,
  CREATE_SPARE_TRANSFER_JOB_RESPONSE,
  DIAL_PHONE_NUMBER_ERROR,
  DIAL_PHONE_NUMBER_REQUEST,
  DIAL_PHONE_NUMBER_SUCCESS,
  FETCH_ALL_DELIVERY_PERSON,
  FETCH_ALL_DELIVERY_PERSON_ERROR,
  FETCH_ALL_DELIVERY_PERSON_SUCCESS,
  FETCH_ALL_JOB,
  FETCH_ALL_JOB_SUCCESS,
  FETCH_ALL_PURIFIER_MODELS,
  FETCH_ALL_PURIFIER_MODELS_SUCCESS,
  FETCH_ALL_REPEATED_JOBS,
  FETCH_ALL_REPEATED_JOBS_SUCCESS,
  FETCH_ALL_TEAMS,
  FETCH_ALL_TEAMS_SUCCESS,
  FETCH_AREAS_SUCCESS,
  FETCH_AREA_ZONES_BY_CITY,
  FETCH_AREA_ZONES_BY_CITY_SUCCESS,
  FETCH_AVAILABLE_SLOTS,
  FETCH_AVAILABLE_SLOTS_SUCCESS,
  FETCH_AVAILABLE_TECHNICIANS,
  FETCH_AVAILABLE_TECHNICIANS_ERROR,
  FETCH_AVAILABLE_TECHNICIANS_SUCCESS,
  FETCH_CATEGORY_AVAILABLE_SLOT,
  FETCH_CATEGORY_AVAILABLE_SLOT_SUCCESS,
  FETCH_DEPENDENCIES,
  FETCH_DEPENDENCIES_SUCCESS,
  FETCH_JOBS_BY_INSTALLATION_ID,
  FETCH_JOBS_BY_INSTALLATION_ID_ERROR,
  FETCH_JOBS_BY_INSTALLATION_ID_SUCCESS,
  FETCH_JOB_IMAGES_SUCCESS,
  FETCH_JOB_SLOTS_SUCCESS,
  FETCH_JOB_STATUS,
  FETCH_JOB_STATUS_SUCCESS,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_TAGS,
  FETCH_JOB_TAGS_DEL,
  FETCH_JOB_TAGS_DEL_ERROR,
  FETCH_JOB_TAGS_DEL_SUCCESS,
  FETCH_JOB_TAGS_OPS,
  FETCH_JOB_TAGS_OPS_ERROR,
  FETCH_JOB_TAGS_OPS_SUCCESS,
  FETCH_JOB_TAGS_SUCCESS,
  FETCH_JOB_TYPES_SUCCESS,
  FETCH_LAST_3_JOBS_BY_INSTALLATION,
  FETCH_LAST_3_JOBS_BY_INSTALLATION_ERROR,
  FETCH_LAST_3_JOBS_BY_INSTALLATION_SUCCESS,
  FETCH_POSTPONED_JOB_DEPENDENCIES_ERROR,
  FETCH_POSTPONED_JOB_DEPENDENCIES_REQUEST,
  FETCH_POSTPONED_JOB_DEPENDENCIES_SUCCESS,
  FETCH_PRIORITY_LIST,
  FETCH_PRIORITY_LIST_ERROR,
  FETCH_PRIORITY_LIST_SUCCESS,
  FETCH_TECHNICIANS,
  FETCH_TECHNICIANS_ERROR,
  FETCH_TECHNICIANS_JOB_SLOTS,
  FETCH_TECHNICIANS_JOB_SLOTS_ERROR,
  FETCH_TECHNICIANS_JOB_SLOTS_SUCCESS,
  FETCH_TECHNICIANS_SUCCESS,
  RESET_ZONES,
  SEND_OTP_FOR_POSTPONEMENT_ERROR,
  SEND_OTP_FOR_POSTPONEMENT_REQUEST,
  SEND_OTP_FOR_POSTPONEMENT_SUCCESS,
  SHOW_API_MESSAGE,
  UPDATE_ELEMENT_ADD_TEXT,
  UPDATE_ELEMENT_ADD_TEXT_ERROR,
  UPDATE_ELEMENT_ADD_TEXT_SUCCESS,
  UPDATE_GS_THRESHOLD_LIMITS,
  UPDATE_GS_THRESHOLD_LIMITS_SUCCESS,
  UPDATE_JOB,
  UPDATE_JOB_ERROR,
  UPDATE_JOB_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  jobLoader: false,
  repeatedJobs: [],
  updateJobLoader: false,
  updateJobError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_JOB: {
      return {
        ...state,
        jobLoader: true,
      };
    }
    case FETCH_ALL_JOB_SUCCESS: {
      return {
        ...state,
        allJobs: action.payload,
        jobs: action.payload.jobs,
        totalCount: action.payload.total,
        jobLoader: false,
      };
    }
    case FETCH_JOB_SLOTS_SUCCESS: {
      return {
        ...state,
        slots: action.payload,
        fetchJobSlotLoader: false,
      };
    }
    case FETCH_AREAS_SUCCESS: {
      return {
        ...state,
        areas: action.payload,
        loader: false,
      };
    }
    case FETCH_JOB_TYPES_SUCCESS: {
      return {
        ...state,
        jobTypes: action.payload,
        loader: false,
      };
    }
    case FETCH_JOB_IMAGES_SUCCESS: {
      return {
        ...state,
        jobImages: action.payload,
        loader: false,
      };
    }
    case FETCH_TECHNICIANS: {
      return {
        ...state,
        technicians: [],
        loader: true,
        fetchTechnicianLoader: true,
      };
    }
    case FETCH_TECHNICIANS_SUCCESS: {
      return {
        ...state,
        technicians: action.payload,
        technicianPartners: action.payload, // recammended
        loader: false,
        fetchTechnicianLoader: false,
      };
    }
    case FETCH_TECHNICIANS_ERROR: {
      return {
        ...state,
        technicians: [],
        ...action.payload,
        fetchTechnicianLoader: false,
      };
    }
    case FETCH_TECHNICIANS_JOB_SLOTS: {
      return {
        ...state,
        techJobSlots: [],
        techJobSlotsLoader: true,
        techJobSlotsError: false,
      };
    }
    case FETCH_TECHNICIANS_JOB_SLOTS_SUCCESS: {
      return {
        ...state,
        techJobSlots: action.payload,
        techJobSlotsLoader: false,
        techJobSlotsError: false,
      };
    }
    case FETCH_TECHNICIANS_JOB_SLOTS_ERROR: {
      return {
        ...state,
        techJobSlots: [],
        ...action.payload,
        techJobSlotsLoader: false,
        techJobSlotsError: true,
      };
    }
    case FETCH_AVAILABLE_TECHNICIANS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_AVAILABLE_TECHNICIANS_SUCCESS: {
      return {
        ...state,
        technicians: action.payload,
        loader: false,
      };
    }
    case FETCH_AVAILABLE_TECHNICIANS_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_ALL_DELIVERY_PERSON: {
      return {
        ...state,
        loader: true,
        fetchTechnicianLoader: true,
      };
    }
    case FETCH_ALL_DELIVERY_PERSON_SUCCESS: {
      return {
        ...state,
        technicians: action.payload,
        deliveryAgents: action.payload, // recammended
        loader: false,
        fetchTechnicianLoader: false,
      };
    }
    case FETCH_ALL_DELIVERY_PERSON_ERROR: {
      return {
        ...state,
        ...action.payload,
        fetchTechnicianLoader: false,
      };
    }
    case FETCH_LAST_3_JOBS_BY_INSTALLATION: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_LAST_3_JOBS_BY_INSTALLATION_SUCCESS: {
      return {
        ...state,
        jobs: action.payload,
        loader: false,
      };
    }
    case FETCH_LAST_3_JOBS_BY_INSTALLATION_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_JOB: {
      return {
        ...state,
        ...action.payload,
        updateJobLoader: true,
        updateJobError: false,
      };
    }
    case UPDATE_JOB_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        updateJobLoader: false,
        updateJobError: false,
      };
    }
    case UPDATE_JOB_ERROR: {
      return {
        ...state,
        ...action.payload,
        updateJobLoader: false,
        updateJobError: true,
      };
    }
    case FETCH_JOB_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_JOB_TAGS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_JOB_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload,
        loader: false,
      };
    }
    case FETCH_JOB_TAGS_OPS: {
      return {
        ...state,
        loader: true,
        opsJobTags: [],
      };
    }
    case FETCH_JOB_TAGS_OPS_SUCCESS: {
      return {
        ...state,
        opsJobTags: action.payload,
        loader: false,
      };
    }
    case FETCH_JOB_TAGS_OPS_ERROR: {
      return {
        ...state,
        opsJobTags: [],
        loader: false,
      };
    }
    case FETCH_JOB_TAGS_DEL: {
      return {
        ...state,
        loader: true,
        delJobTags: [],
      };
    }
    case FETCH_JOB_TAGS_DEL_SUCCESS: {
      return {
        ...state,
        delJobTags: action.payload,
        loader: false,
      };
    }
    case FETCH_JOB_TAGS_DEL_ERROR: {
      return {
        ...state,
        delJobTags: [],
        loader: false,
      };
    }
    case FETCH_JOB_STATUS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_JOB_STATUS_SUCCESS: {
      return {
        ...state,
        status: action.payload,
        loader: false,
      };
    }
    case FETCH_POSTPONED_JOB_DEPENDENCIES_REQUEST: {
      return {
        ...state,
        postponedJobDependencies: [],
        postponedJobDependenciesLoader: true,
        postponedJobDependenciesError: false,
      };
    }
    case FETCH_POSTPONED_JOB_DEPENDENCIES_SUCCESS: {
      return {
        ...state,
        postponedJobDependencies: action.payload,
        postponedJobDependenciesLoader: false,
        postponedJobDependenciesError: false,
      };
    }
    case FETCH_POSTPONED_JOB_DEPENDENCIES_ERROR: {
      return {
        ...state,
        postponedJobDependencies: [],
        postponedJobDependenciesLoader: false,
        postponedJobDependenciesError: true,
      };
    }
    case SEND_OTP_FOR_POSTPONEMENT_REQUEST: {
      return {
        ...state,
        sendOtpForPostponementResponse: null,
        sendOtpForPostponementLoader: true,
        sendOtpForPostponementError: false,
      };
    }
    case SEND_OTP_FOR_POSTPONEMENT_SUCCESS: {
      return {
        ...state,
        sendOtpForPostponementResponse: action.payload,
        sendOtpForPostponementLoader: false,
        sendOtpForPostponementError: false,
      };
    }
    case SEND_OTP_FOR_POSTPONEMENT_ERROR: {
      return {
        ...state,
        sendOtpForPostponementResponse: null,
        sendOtpForPostponementLoader: false,
        sendOtpForPostponementError: true,
      };
    }
    case DIAL_PHONE_NUMBER_REQUEST: {
      return {
        ...state,
        dialPhoneNumberLoader: true,
        dialPhoneNumberError: false,
      };
    }
    case DIAL_PHONE_NUMBER_SUCCESS: {
      return {
        ...state,
        dialPhoneNumberLoader: false,
        dialPhoneNumberError: false,
      };
    }
    case DIAL_PHONE_NUMBER_ERROR: {
      return {
        ...state,
        dialPhoneNumberLoader: false,
        dialPhoneNumberError: true,
      };
    }
    case FETCH_AVAILABLE_SLOTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_CATEGORY_AVAILABLE_SLOT: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_AVAILABLE_SLOTS_SUCCESS: {
      return {
        ...state,
        availableSlots: action.payload,
        loader: false,
      };
    }
    case FETCH_CATEGORY_AVAILABLE_SLOT_SUCCESS: {
      return {
        ...state,
        categoryAvailableSlots: action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_TEAMS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_ALL_TEAMS_SUCCESS: {
      return {
        ...state,
        teams: action.payload.teams,
        loader: false,
      };
    }
    case UPDATE_ELEMENT_ADD_TEXT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_ELEMENT_ADD_TEXT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_ELEMENT_ADD_TEXT_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case FETCH_JOBS_BY_INSTALLATION_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_JOBS_BY_INSTALLATION_ID_SUCCESS: {
      return {
        ...state,
        jobs: action.payload,
        loader: false,
      };
    }
    case FETCH_JOBS_BY_INSTALLATION_ID_ERROR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_AREA_ZONES_BY_CITY: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_AREA_ZONES_BY_CITY_SUCCESS: {
      return {
        ...state,
        zones: action.payload.body,
        loader: false,
      };
    }
    case UPDATE_GS_THRESHOLD_LIMITS: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_GS_THRESHOLD_LIMITS_SUCCESS: {
      return {
        ...state,
        zones: action.payload.body,
        loader: false,
      };
    }
    case FETCH_ALL_REPEATED_JOBS: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_ALL_REPEATED_JOBS_SUCCESS: {
      return {
        ...state,
        repeatedJobs: action.payload,
        loader: false,
      };
    }
    case FETCH_DEPENDENCIES: {
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_DEPENDENCIES_SUCCESS: {
      return {
        ...state,
        dependencies: action.payload,
        loader: false,
      };
    }
    case RESET_ZONES: {
      return {
        ...state,
        zones: [],
        loader: false,
      };
    }
    case CREATE_SPARE_TRANSFER_JOB_REQUEST: {
      return {
        ...state,
        loader: true,
        spareJobCreated: null,
      };
    }
    case CREATE_SPARE_TRANSFER_JOB_RESPONSE: {
      return {
        ...state,
        loader: true,
        spareJobCreated: action.payload,
      };
    }
    case FETCH_PRIORITY_LIST: {
      return {
        ...state,
        priorityList: action.payload,
      };
    }
    case FETCH_PRIORITY_LIST_SUCCESS: {
      return {
        ...state,
        priorityList: action.payload,
      };
    }
    case FETCH_PRIORITY_LIST_ERROR: {
      return {
        ...state,
        priorityList: action.payload,
      };
    }
    case FETCH_ALL_PURIFIER_MODELS: {
      return {
        ...state,
        purifierModels: action.payload,
        purifierModelsLoader: true,
      };
    }
    case FETCH_ALL_PURIFIER_MODELS_SUCCESS: {
      return {
        ...state,
        purifierModels: action.payload,
        purifierModelsLoader: false,
      };
    }
    default:
      return state;
  }
};

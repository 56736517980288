import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const B2B = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Route
          path={`${match.url}/list`}
          component={asyncComponent(() => import("./routes/B2bPartners"))}
        />
        <Route
          path={`${match.url}/:partnerId/bills`}
          component={asyncComponent(() => import("./routes/Bills"))}
        />
        <Route
          path={`${match.url}/:partnerId/bill/:billId`}
          component={asyncComponent(() => import("./routes/BillDetails"))}
        />
        <Route
          path={`${match.url}/`}
          exact
          component={() => <Redirect to={`${match.url}/list`} />}
        />
        <Route
          component={asyncComponent(() => import("components/Error404"))}
        />
      </Switch>
    </div>
  );
};

export default B2B;

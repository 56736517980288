import React from "react"
import { fetchB2BBillPaymentTypeError, fetchB2BBillPaymentTypeSuccess, fetchB2BBillTransactionError, fetchB2BBillTransactionSuccess, fetchB2BPartnersResponse, postB2bBillPaymentFailed, postB2bBillPaymentResponse, postChangeInstallationPartnerError, postChangeInstallationPartnerSuccess, postNewBusinessPartnersError, postNewBusinessPartnersSuccess, showAPIMessage } from "actions/PaymentActions";
import {
  fetchB2BBillsResponse,
  fetchB2BPartnerBillDetailsResponse,
  fetchInstallationBillsResponse,
} from "actions/PaymentActions";
import { CREATE_NEW_BUSSINESS_PARTNERS_REQ, FETCH_B2B_BILL_PAYMENT_TYPE_REQUEST, FETCH_B2B_BILL_TRANSACTIONS_REQUEST, FETCH_B2B_INSTALLATION_BILLS_REQUEST, FETCH_BUSSINESS_PARTNERS_BILLS_REQUEST, FETCH_BUSSINESS_PARTNERS_REQUEST, FETCH_BUSSINESS_PARTNER_BILL_DETAILS_REQUEST, POST_B2B_BILL_PAYMENT_REQUEST, POST_CHANGE_INSTALLATION_PARTNER_REQUEST } from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { paymentAxios, workForceAxios } from "util/Api";

const getB2BPartners = async function(payload) {
  if (payload && payload.params && payload.params.orderDir) {
    payload.params.orderDir = payload.params.orderDir.toUpperCase();
  }
  const response = await paymentAxios.get(
    "/b2b-partner/list",
    payload
  );
  let partnersPage = response.data;
  return partnersPage;
};

const getB2BBills = async function (payload) {
  let partnerId = payload.partnerId;
  if (payload && payload.params && payload.params.orderDir) {
    payload.params.orderDir = payload.params.orderDir.toUpperCase();
  }

  const response = await paymentAxios.get(
    "/b2b-partner/bill/" + partnerId,
    payload
  );

  let billsPage = response.data || [];
  return billsPage;
};

const getB2PartnerBillDetails = async function (payload) {
  let response = await paymentAxios.get(
    `/b2b-partner/billDetails/${payload.billId}`,
    {}
  );
  let partnerBillDetails = response.data;
  return partnerBillDetails;
};

const getInstallationBills = async function(installationIds) {
  const response = await paymentAxios.post(
    "/b2b-partner/bill/installationsBill",
    installationIds
  );
  let bills = response.data;
  return bills;
};

const makeBillPaymentRequest = async function (payload) {
  const response = await paymentAxios.post(
    "/b2b-partner/bill/payment",
    payload
  );
  return response.data;
};

const fetchB2BBillPaymentType = async payload => {
  const response = await paymentAxios.get("/b2b-partner/bill/paymentModes");
  return response.data;
}

const fetchB2BBillTransactions = async payload => {
  const response = await paymentAxios.get(`/b2b-partner/bill/${payload.billId}/payment/transactions`);
  return response.data;
}

const postChangeInstallationPartner = async payload => {
  const response = await workForceAxios.put(
    "/installation/businessPartner",
    payload
  );
  return response.data;
};

const createNewBussinessPartners = async payload => {
  const response = await paymentAxios.post(`/b2b-partner/addAll`, payload);
  return response.data;
}

function* fetchB2BPartners(action) {
  try {
    const partners = yield call(getB2BPartners, action.payload);
    yield put(fetchB2BPartnersResponse(partners));
  } catch (error) {
    NotificationManager.error("Failed: " + error, "", 10000);
    yield put(showAPIMessage(error));
  }
}
function* fetchB2BBills(action) {
  try {
    const bills = yield call(getB2BBills, action.payload);
    yield put(fetchB2BBillsResponse(bills));
  } catch (error) {
    NotificationManager.error("Failed: " + error, "", 10000);
    yield put(fetchB2BBillsResponse([]));
  }
}

function* fetchB2PartnerDetails(action) {
  try {
    const billDetails = yield call(getB2PartnerBillDetails, action.payload);
    yield put(fetchB2BPartnerBillDetailsResponse(billDetails));
  } catch (error) {
    NotificationManager.error("Failed: " + error, "", 10000);
    yield put(fetchB2BPartnerBillDetailsResponse({}));
  }
}

function* postBillPaymentRequest(action) {
  try {
    const response = yield call(makeBillPaymentRequest, action.payload);
    if (response?.success) {
      NotificationManager.success(`${response?.message || "Success!!"}`);
      yield put(postB2bBillPaymentResponse(response));
    } else {
      NotificationManager.success(response?.message || "Failed!!");
      yield put(postB2bBillPaymentFailed({}));
    }
  } catch (error) {
    NotificationManager.error("Failed: " + error);
    yield put(postB2bBillPaymentFailed({}));
  }
}

function* fetchInstallationBills(action) {
  try {
    const installationBills = yield call(getInstallationBills, action.payload);
    yield put(fetchInstallationBillsResponse(installationBills));
  } catch (error) {
    NotificationManager.error("Failed: " + error, "", 10000);
    yield put(fetchInstallationBillsResponse([]));
  }
}

function* fetchB2BBillPaymentTypeRequest(action) {
  try {
    const response = yield call(fetchB2BBillPaymentType, action.payload);
    
    let errorMessage = null;
    if (response == null) {
      errorMessage = "Response is null";
    } else if (!response.success) {
      errorMessage = response.message || "Unable to fetch payment types";
    }

    if (errorMessage) {
      yield put(fetchB2BBillPaymentTypeError([]));
      NotificationManager.error(`Failed: ${errorMessage}`)
    } else {
      yield put(fetchB2BBillPaymentTypeSuccess(response.body || []));
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(fetchB2BBillPaymentTypeError([]));
  }
}
function* fetchB2BBillTransactionsRequest(action) {
  try {
    const response = yield call(fetchB2BBillTransactions, action.payload);
    let errorMessage = null;
    if (response == null) {
      errorMessage = "Response is null";
    } else if (!response.success) {
      errorMessage = response.message || "Unable to fetch bill transactions";
    }

    if (errorMessage) {
      yield put(fetchB2BBillTransactionError([]));
      NotificationManager.error(`Failed: ${errorMessage}`)
    } else {
      yield put(fetchB2BBillTransactionSuccess(response.body || []));
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(fetchB2BBillTransactionError([]));
  }
}
function* postChangeInstallationPartnerRequest(action) {
  try {
    const response = yield call(postChangeInstallationPartner, action.payload);
    let errorMessage = null;
    if (response == null) {
      errorMessage = "Response is null";
    } else if (!response.success) {
      errorMessage = response.message || "Unable to update partner";
    }

    if (errorMessage) {
      yield put(postChangeInstallationPartnerError(errorMessage));
      NotificationManager.error(`Failed: ${errorMessage}`)
    } else {
      NotificationManager.success(
        response.message || "Successfully updated partner!!"
      );
      yield put(postChangeInstallationPartnerSuccess(response?.message));
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(postChangeInstallationPartnerError(`${error}`));
  }
}
function* createNewBussinessPartnersRequest(action) {
  try {
    const response = yield call(createNewBussinessPartners, action.payload);
    let errorMessage = null;
    if (response == null) {
      errorMessage = "Response is null";
    } else if (!response.success) {
      errorMessage = response.message || "Unable to create partner(s)";
    }

    if (errorMessage) {
      yield put(postNewBusinessPartnersError());
      console.error(errorMessage);
      NotificationManager.error(`Failed: ${errorMessage}`)
    } else {
      NotificationManager.success(
        response.message || "Successfully created partner(s)!!"
      );
      yield put(postNewBusinessPartnersSuccess(response?.body || []));
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(postNewBusinessPartnersError());
  }
}

export function* fetchB2BBillData() {
  yield takeLatest(FETCH_BUSSINESS_PARTNERS_BILLS_REQUEST, fetchB2BBills);
  yield takeLatest(FETCH_BUSSINESS_PARTNER_BILL_DETAILS_REQUEST, fetchB2PartnerDetails);
  yield takeLatest(POST_B2B_BILL_PAYMENT_REQUEST, postBillPaymentRequest);
  yield takeLatest(FETCH_B2B_INSTALLATION_BILLS_REQUEST, fetchInstallationBills);
  yield takeLatest(CREATE_NEW_BUSSINESS_PARTNERS_REQ, createNewBussinessPartnersRequest)
  yield takeLatest(FETCH_BUSSINESS_PARTNERS_REQUEST, fetchB2BPartners);
  yield takeLatest(FETCH_B2B_BILL_PAYMENT_TYPE_REQUEST, fetchB2BBillPaymentTypeRequest);
  yield takeLatest(FETCH_B2B_BILL_TRANSACTIONS_REQUEST, fetchB2BBillTransactionsRequest);
  yield takeLatest(POST_CHANGE_INSTALLATION_PARTNER_REQUEST, postChangeInstallationPartnerRequest)
}
export default function* rootSaga() {
  yield all([fork(fetchB2BBillData)]);
}

import {
  fetchAllConfigSuccess, fetchAllLeaves, fetchAllLeavesSuccess, fetchAllPermissionsSuccess,
  fetchAllRolesSuccess,
  fetchConfigByIdSuccess,
  fetchDestinationWarehousesSuccess,
  fetchEmployee,
  fetchEmployeeError,
  fetchEmployees,
  fetchEmployeesLeaveError,
  fetchEmployeesLeaveSuccess,
  fetchEmployeesSuccess,
  fetchEmployeeSuccess,
  fetchPassbookEntriesSuccess,
  fetchSchemeSuccess,
  fetchSourceWarehousesSuccess,
  fetchTransitWarehousesSuccess,
  removeEmployeeProfilePictureError,
  removeEmployeeProfilePictureSuccess,
  showAPIMessage,
  uploadEmployeeProfilePictureError,
  uploadEmployeeProfilePictureSuccess
} from "actions/EmployeeActions";
import {
  ADD_EARNING_ADHOC,
  ADD_EARNING_CONFIG,
  ADD_EARNING_SCHEME,
  ADD_EMPLOYEE,
  ADD_LEAVE,
  FETCH_ALL_CONFIG_FOR_A_SCHEME,
  FETCH_ALL_EMPLOYEE, FETCH_ALL_LEAVES, FETCH_ALL_PERMISSIONS,
  FETCH_ALL_ROLES,
  FETCH_ALL_SCHEMES,
  FETCH_CONFIG_BY_ID,
  FETCH_DESTINATION_WAREHOUSE,
  FETCH_EMPLOYEE,
  FETCH_EMPLOYEES_LEAVE,
  FETCH_PASSBOOK_ENTRIES,
  FETCH_SOURCE_WAREHOUSE,
  FETCH_TRANSIT_WAREHOUSE,
  REMOVE_EMPLOYEE_PROFILE_PICTURE_REQUEST,
  UPDATE_EARNING_CONFIG, UPDATE_EMPLOYEE, UPDATE_LEAVE,
  UPLOAD_EMPLOYEE_PROFILE_PICTURE_REQUEST
} from "constants/ActionTypes";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { workForceAxios as axios } from "util/Api";

function* addEmployeeRequest(action) {
  try {
    const response = yield call(addEmployee, action.payload);
    yield all([put(fetchEmployees())]);
    if (response.success) {
      NotificationManager.success("Employee Added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add employee");
  }
}

const addEmployee = async function (params) {
  try {
    const response = await axios.post("/employee/add", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* updateEmployeeRequest(action) {
  try {
    const response = yield call(updateEmployee, action.payload);
    yield all([put(fetchEmployee(action.payload.id))]);

    if (response.success) {
      NotificationManager.success("Employee details updated successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to update employee details");
  }
}

const updateEmployee = async function (params) {
  try {
    const response = await axios.put("/employee/" + params["id"], {
      ...params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const getEmployees = async function (params) {
  let employees = [];
  try {
    const response = await axios.get("/employee/list", { params });
    employees = response.data;
  } catch (error) {
    console.log(error);
  }
  return employees;
};

function* fetchEmployeesRequest(action) {
  try {
    const fetchedEmployee = yield call(getEmployees, action.payload);
    yield put(fetchEmployeesSuccess(fetchedEmployee));
  } catch (error) {
    yield put(showAPIMessage(error));
  }
}

function* fetchEmployeeRequest(action) {
  try {
    const employee = yield call(getEmployee, {
      employeeId: action.payload.id,
    });
    yield put(fetchEmployeeSuccess(employee));
  } catch (error) {
    yield put(fetchEmployeeError(error.message));
  }
}

const getEmployee = async function (params) {
  let employees = [];
  try {
    const response = await axios.get("/employee/" + params["employeeId"], {});
    employees = response.data;
  } catch (error) {
    console.log(error);
  }
  return employees;
};

function* fetchWarehousesRequest(action) {
  try {
    const sourceWarehouse = yield call(getWarehouses, {
      type: "source",
      flag: action.payload.val,
    });
    yield put(fetchSourceWarehousesSuccess(sourceWarehouse));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}

function* fetchDestinationWarehousesRequest(action) {
  try {
    const destinationWarehouse = yield call(getWarehouses, {
      type: "destination",
      flag: action.payload.val,
    });
    yield put(fetchDestinationWarehousesSuccess(destinationWarehouse));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}
function* fetchTransitWarehousesRequest(action) {
  try {
    const transitWarehouse = yield call(getWarehouses, {
      type: "transit",
      flag: action.payload.val,
    });
    yield put(fetchTransitWarehousesSuccess(transitWarehouse));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}
const getWarehouses = async function (params) {
  if (params.flag == undefined) params.flag = null;
  let employees = [];
  try {
    const response = await axios.get(
      "/employee/getWarehouses?type=" + params.type + "&flag=" + params.flag,
      {}
    );
    employees = response.data;
  } catch (error) {
    console.log(error);
  }
  return employees.body;
};
function* fetchEmployeesLeaveRequest(action) {
  try {
    const employeesLeave = yield call(getEmployeesLeave, {
      params: action.payload.params,
    });
    yield put(fetchEmployeesLeaveSuccess(employeesLeave));
  } catch (error) {
    yield put(fetchEmployeesLeaveError(error.message));
  }
}

const getEmployeesLeave = async function (params) {
  let employeesLeave = [];
  // try {
  //   const response = await axios.get("/employee/employeesLeave", params);
  //   employeesLeave = response.data;
  // } catch (error) {
  //   console.log(error);
  // }
  return employeesLeave;
};

function* fetchAllPermissionsRequest(action) {
  try {
    const permissions = yield call(getAllPermissions);
    yield put(fetchAllPermissionsSuccess(permissions));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}

const getAllPermissions = async function () {
  let permissions = [];
  try {
    const response = await axios.get("/employee/permissions/list");
    permissions = response.data;
  } catch (error) {
    console.log(error);
  }
  return permissions;
};

function* fetchAllRolesRequest(action) {
  try {
    const roles = yield call(getAllRoles);
    yield put(fetchAllRolesSuccess(roles));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}

const getAllRoles = async function () {
  let roles = [];
  try {
    const response = await axios.get("/employee/roles/list");
    roles = response.data;
  } catch (error) {
    console.log(error);
  }
  return roles;
};

function* fetchAllSchemes(action) {
  try {
    const schemes = yield call(getAllSchemes);
    yield put(fetchSchemeSuccess(schemes));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}

const getAllSchemes = async function () {
  let schemes = [];
  try {
    const response = await axios.get("/earnings/scheme/list");
    schemes = response.data;
  } catch (error) {
    console.log(error);
  }
  return schemes;
};

function* fetchPassbookEntriesRequest(action) {
  try {
    const entries = yield call(fetchEntries, action.payload);
    yield put(fetchPassbookEntriesSuccess(entries));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}

const fetchEntries = async function (params) {
  if ("CREDIT" == params?.onlyCredit) params.onlyCredit = true;
  else if ("DEBIT" == params?.onlyCredit) params.onlyCredit = false;
  else params.onlyCredit = null;
  
  let entries = [];
  try {
    const response = await axios.get("/earnings/entries/", { params });
    entries = response.data;
  } catch (error) {
    console.log(error);
  }
  return entries;
};

function* fetchAllConfigForScheme(action) {
  try {
    const configs = yield call(fetchAllConfig, action.payload);
    yield put(fetchAllConfigSuccess(configs));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}

const fetchAllConfig = async function (payload) {
  let configs = [];
  try {
    const response = await axios.get("/earnings/config/list/" + payload);
    configs = response.data;
  } catch (error) {
    console.log(error);
  }
  return configs;
};

function* fetchConfigByIdRequest(action) {
  try {
    const config = yield call(fetchConfig, action.payload.id);
    yield put(fetchConfigByIdSuccess(config));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}

const fetchConfig = async function (payload) {
  let config = {};
  try {
    const response = await axios.get("/earnings/config/" + payload);
    config = response.data;
  } catch (error) {
    console.log(error);
  }
  return config;
};

function* addEarningSchemeRequest(action) {
  try {
    const response = yield call(addScheme, action.payload);
    if (response.success) {
      NotificationManager.success("Scheme Added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add scheme");
  }
}

const addScheme = async function (params) {
  try {
    const response = await axios.post("/earnings/scheme/add/", { ...params });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
function* addEarningAdhocRequest(action) {
  try {
    const response = yield call(addAdhoc, action.payload);
    action.payload["id"] = action.payload.employeeId;
    if (response.success) {
      NotificationManager.success("Payout Added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add payout");
  }
}

const addAdhoc = async function (params) {
  try {
    const response = await axios.post("/earnings/adHocPayment", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};
function* updateEarningConfigRequest(action) {
  try {
    const response = yield call(updateConfig, action.payload);
    if (response.success) {
      NotificationManager.success("Config Updated Successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add config");
  }
}

const updateConfig = async function (params) {
  try {
    const response = await axios.put(
      "/earnings/config/update/" + params["id"],
      { ...params }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
function* addEarningConfigRequest(action) {
  try {
    const response = yield call(addConfig, action.payload);
    if (response.success) {
      NotificationManager.success("Config Added successfully");
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error("Failed to add config");
  }
}

const addConfig = async function (params) {
  try {
    const response = await axios.post("/earnings/config/add", { ...params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* fetchLeavesRequest(action) {
  try {
    const leaves = yield call(getLeaves, action.payload.params);
    yield put(fetchAllLeavesSuccess(leaves));
  } catch (error) {
    yield put(showAPIMessage(error.message));
  }
}

const getLeaves = async function (params) {
  let leaves = [];
  try {
    const response = await axios.get(
      `/employee/employeesLeave/${params.id}?fromDate=${params.fromDate}&toDate=${params.toDate}`
    );
    leaves = response.data;
  } catch (error) {
    throw new Error(error);
  }
  return leaves;
};

function* addLeaveRequest(action) {
  try {
    const response = yield call(addLeave, action.payload);
    if (response.success) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error("Failed: " + response.message, "", 10000);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error(error.message);
  }
}

const addLeave = async function (params) {
  try {
    const response = await axios.post(
      `/employee/${params.employeeId}/addLeave`,
      { ...params }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

function* uploadEmployeeProfilePictureRequest(action) {
  try {
    const response = yield call(uploadEmployeeProfilePicture, action.payload);
    if (response?.data?.success) {
      NotificationManager.success(`${response?.data?.message}`);
      yield put(uploadEmployeeProfilePictureSuccess(response.data));
    } else {
      NotificationManager.error(`${response?.data?.message}`);
      yield put(uploadEmployeeProfilePictureError(response.data || {}));
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(uploadEmployeeProfilePictureError(error));
  }
}

const uploadEmployeeProfilePicture = async ({ employeeId, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  return await axios.post(`/employee/${employeeId}/profile/picture`, formData);
};

function* removeEmployeeProfilePictureRequest(action) {
  try {
    const response = yield call(removeEmployeeProfilePicture, action.payload);
    if (response?.data?.success) {
      NotificationManager.success(`${response?.data?.message}`);
      yield put(removeEmployeeProfilePictureSuccess(response.data));
    } else {
      NotificationManager.error(`${response?.data?.message}`);
      yield put(removeEmployeeProfilePictureError(response?.data || {}));
    }
  } catch (error) {
    NotificationManager.error(`${error}`);
    yield put(removeEmployeeProfilePictureError(error));
  }
}

const removeEmployeeProfilePicture = async ({ employeeId }) => {
  return await axios.put(`/employee/delete/${employeeId}/profile/picture`);
};

export const getLeaveType = async () => {
  try {
    const { data } = await axios.get(`/employee/leaveType`);
    return data;
  } catch (error) {
    throw error;
  }
};
function* updateLeaveRequest(action) {
  try {
    const response = yield call(updateLeave, action.payload);
    yield all([
      put(
        fetchAllLeaves({
          id: action.payload.employeeId,
          fromDate: action.payload.fromDate,
          toDate: action.payload.toDate,
        })
      ),
    ]);
    if (response.success) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error("Failed: " + response.message);
    }
  } catch (error) {
    yield put(showAPIMessage(error));
    NotificationManager.error(error.message);
  }
}

export const updateLeave = async params => {
  try {
    const response = await axios.put(
      `/employee/${params.employeeId}/updateLeave/${params.id}`,
      { ...params }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export function* fetchEmployeesData() {
  yield takeLatest(FETCH_ALL_EMPLOYEE, fetchEmployeesRequest);
  yield takeLatest(FETCH_EMPLOYEE, fetchEmployeeRequest);
  yield takeLatest(FETCH_EMPLOYEES_LEAVE, fetchEmployeesLeaveRequest);
  yield takeLatest(ADD_EMPLOYEE, addEmployeeRequest);
  yield takeLatest(UPDATE_EMPLOYEE, updateEmployeeRequest);
  yield takeLatest(FETCH_ALL_ROLES, fetchAllRolesRequest);
  yield takeLatest(FETCH_ALL_SCHEMES, fetchAllSchemes);
  yield takeLatest(FETCH_ALL_CONFIG_FOR_A_SCHEME, fetchAllConfigForScheme);
  yield takeLatest(FETCH_CONFIG_BY_ID, fetchConfigByIdRequest);
  yield takeLatest(ADD_EARNING_CONFIG, addEarningConfigRequest);
  yield takeLatest(ADD_LEAVE, addLeaveRequest);
  yield takeLatest(UPDATE_EARNING_CONFIG, updateEarningConfigRequest);
  yield takeLatest(UPDATE_LEAVE, updateLeaveRequest);
  yield takeLatest(ADD_EARNING_SCHEME, addEarningSchemeRequest);
  yield takeLatest(ADD_EARNING_ADHOC, addEarningAdhocRequest);
  yield takeLatest(FETCH_ALL_PERMISSIONS, fetchAllPermissionsRequest);
  yield takeLatest(FETCH_ALL_LEAVES, fetchLeavesRequest);
  yield takeLatest(FETCH_PASSBOOK_ENTRIES, fetchPassbookEntriesRequest);
  yield takeLatest(FETCH_SOURCE_WAREHOUSE, fetchWarehousesRequest);
  yield takeLatest(
    FETCH_DESTINATION_WAREHOUSE,
    fetchDestinationWarehousesRequest
  );
  yield takeLatest(FETCH_TRANSIT_WAREHOUSE, fetchTransitWarehousesRequest);
  yield takeLatest(UPLOAD_EMPLOYEE_PROFILE_PICTURE_REQUEST, uploadEmployeeProfilePictureRequest);
  yield takeLatest(REMOVE_EMPLOYEE_PROFILE_PICTURE_REQUEST, removeEmployeeProfilePictureRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchEmployeesData)]);
}

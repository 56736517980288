import axios from "axios";

export const workForceAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});


export const paymentAxios = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_BACKEND_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const authGateway = axios.create({
  baseURL: process.env.REACT_APP_AUTH_GATEWAY_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});


export const sponsorAxios = axios.create({
  baseURL: process.env.REACT_APP_SPONSOR_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  }
})


export const customerAxios = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  }
})

export const appAxios = axios.create({
  baseURL: process.env.REACT_APP_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  }
})

export const isSuccessfulStatus = status => {
  return status >= 200 && status <= 299;
}
import { REQUEST_UPDATE_INSTALLATION_STATUS_V2, RESPONSE_UPDATE_INSTALLATION_STATUS_V2, UNINSTALL_PAUSED_INSTALLATION_ERROR, UNINSTALL_PAUSED_INSTALLATION_REQUEST, UNINSTALL_PAUSED_INSTALLATION_SUCCESS, UNPAUSE_SERVICE_ERROR, UNPAUSE_SERVICE_REQUEST, UNPAUSE_SERVICE_SUCCESS } from "constants/ActionTypes";

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_UPDATE_INSTALLATION_STATUS_V2: {
      return {
        installationStatusUpdate: false,
      };
    }
    case RESPONSE_UPDATE_INSTALLATION_STATUS_V2: {
      return {
        installationStatusUpdate: action.payload,
      };
    }
    case UNINSTALL_PAUSED_INSTALLATION_REQUEST: {
      return {
        uninstallPausedInstallationLoader: true,
        uninstallPausedInstallationError: false,
      };
    }
    case UNINSTALL_PAUSED_INSTALLATION_SUCCESS: {
      return {
        uninstallPausedInstallationLoader: false,
        uninstallPausedInstallationError: false,
      };
    }
    case UNINSTALL_PAUSED_INSTALLATION_ERROR: {
      return {
        uninstallPausedInstallationLoader: false,
        uninstallPausedInstallationError: true,
      };
    }
    case UNPAUSE_SERVICE_REQUEST: {
      return {
        unpauseServiceLoader: true,
        unpauseServiceError: false,
      };
    }
    case UNPAUSE_SERVICE_SUCCESS: {
      return {
        unpauseServiceLoader: false,
        unpauseServiceError: false,
      };
    }
    case UNPAUSE_SERVICE_ERROR: {
      return {
        unpauseServiceLoader: false,
        unpauseServiceError: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};
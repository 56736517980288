import {
  ADD_NEW_SLOT_CATEGORY_ERROR,
  ADD_NEW_SLOT_CATEGORY_REQUEST,
  ADD_NEW_SLOT_CATEGORY_SUCCESS,
  ADD_OR_UPDATE_DISPLAY_RULE_ERROR,
  ADD_OR_UPDATE_DISPLAY_RULE_REQUEST,
  ADD_OR_UPDATE_DISPLAY_RULE_SUCCESS,
  ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_ERROR,
  ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST,
  ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_SUCCESS,
  ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_ERROR,
  ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST,
  ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_SUCCESS,
  DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_ERROR,
  DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST,
  DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_SUCCESS,
  FETCH_ALL_SLOT_CATEGORIES,
  FETCH_ALL_SLOT_CATEGORIES_ERROR,
  FETCH_ALL_SLOT_CATEGORIES_SUCCESS,
  FETCH_ALL_SLOT_ZONE_SUCCESS,
  FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_ERROR,
  FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_REQUEST,
  FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_SUCCESS,
  FETCH_DAYS_SUCCESS,
  FETCH_ISSUE_TYPES_SUCCESS,
  FETCH_SLOTS_SUCCESS,
  FETCH_SLOT_CATEGORY_COUNT_BY_ZONE,
  FETCH_SLOT_CATEGORY_COUNT_BY_ZONE_SUCCESS,
  FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE_SUCCESS,
  FETCH_SLOT_ZONE_SUCCESS,
  REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_ERROR,
  REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST,
  REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_SUCCESS,
  SEARCH_SLOT_DISPLAY_RULE_ERROR,
  SEARCH_SLOT_DISPLAY_RULE_REQUEST,
  SEARCH_SLOT_DISPLAY_RULE_SUCCESS,
  SHOW_API_MESSAGE,
  UPDATE_SLOT_CATEGORY_ERROR,
  UPDATE_SLOT_CATEGORY_REQUEST,
  UPDATE_SLOT_CATEGORY_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  slotCategoryByZone: undefined,
  categories: undefined
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_SLOT_ZONE_SUCCESS: {
      return {
        ...state,
        allSlotZones: action.payload,
        slotZones: action.payload.records,
        totalCount: action.payload.total,
        loader: false,
      };
    }
    case FETCH_SLOT_ZONE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }
    case FETCH_ISSUE_TYPES_SUCCESS: {
      return {
        ...state,
        issueTypes: action.payload,
        loader: false,
      };
    }
    case FETCH_DAYS_SUCCESS: {
      return {
        ...state,
        days: action.payload,
        loader: false,
      };
    }
    case FETCH_SLOTS_SUCCESS: {
      return {
        ...state,
        slots: action.payload,
        loader: false,
      };
    }
    case SHOW_API_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case FETCH_SLOT_COUNT_BY_ZONE_AND_ISSUE_SUCCESS: {
      return {
        ...state,
        slotCountByZoneAndIssue: action.payload,
        loader: false,
      };
    }
    case FETCH_SLOT_CATEGORY_COUNT_BY_ZONE: {
      return {
        ...state,
        slotCategoryByZone: action.payload,
        loader: false,
      };
    }
    case FETCH_SLOT_CATEGORY_COUNT_BY_ZONE_SUCCESS: {
      return {
        ...state,
        slotCategoryByZone: action.payload,
        loader: false,
      };
    }
    case FETCH_ALL_SLOT_CATEGORIES: {
      return {
        ...state,
        categories: [],
        loader: false,
        fetchSlotCategoryLoader: true,
        fetchSlotCategoryError: false,
      };
    }
    case FETCH_ALL_SLOT_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
        loader: false,
        fetchSlotCategoryLoader: false,
        fetchSlotCategoryError: false,
      };
    }
    case FETCH_ALL_SLOT_CATEGORIES_ERROR: {
      return {
        ...state,
        categories: [],
        fetchSlotCategoryLoader: false,
        fetchSlotCategoryError: true,
      };
    }
    case ADD_NEW_SLOT_CATEGORY_REQUEST: {
      return {
        ...state,
        newSlotCategorySuccess: false,
      };
    }
    case ADD_NEW_SLOT_CATEGORY_SUCCESS: {
      return {
        ...state,
        newSlotCategory: action.payload,
        newSlotCategorySuccess: true,
      };
    }
    case ADD_NEW_SLOT_CATEGORY_ERROR: {
      return {
        ...state,
        newSlotCategorySuccess: false,
      };
    }
    case UPDATE_SLOT_CATEGORY_REQUEST: {
      return {
        ...state,
        updateSlotCategorySuccess: false,
      };
    }
    case UPDATE_SLOT_CATEGORY_SUCCESS: {
      return {
        ...state,
        newSlotCategory: action.payload,
        updateSlotCategorySuccess: true,
      };
    }
    case UPDATE_SLOT_CATEGORY_ERROR: {
      return {
        ...state,
        updateSlotCategorySuccess: false,
      };
    }
    case SEARCH_SLOT_DISPLAY_RULE_REQUEST: {
      return {
        ...state,
        searchSlotDisplayRulesLoader: true,
        slotDisplayRulePage: null,
        searchSlotDisplayRulesError: false,
      };
    }
    case SEARCH_SLOT_DISPLAY_RULE_SUCCESS: {
      return {
        ...state,
        searchSlotDisplayRulesLoader: false,
        slotDisplayRulePage: action.payload,
        searchSlotDisplayRulesError: false,
      };
    }
    case SEARCH_SLOT_DISPLAY_RULE_ERROR: {
      return {
        ...state,
        searchSlotDisplayRulesLoader: false,
        slotDisplayRulePage: null,
        searchSlotDisplayRulesError: true,
      };
    }
    case ADD_OR_UPDATE_DISPLAY_RULE_REQUEST: {
      return {
        ...state,
        addOrUpdateDisplayRuleLoader: true,
        addOrUpdateDisplayRuleSuccess: false,
        addOrUpdateDisplayRuleError: false,
      };
    }
    case ADD_OR_UPDATE_DISPLAY_RULE_SUCCESS: {
      return {
        ...state,
        addOrUpdateDisplayRuleLoader: false,
        addOrUpdateDisplayRuleSuccess: true,
        addOrUpdateDisplayRuleError: false,
      };
    }
    case ADD_OR_UPDATE_DISPLAY_RULE_ERROR: {
      return {
        ...state,
        addOrUpdateDisplayRuleLoader: false,
        addOrUpdateDisplayRuleSuccess: false,
        addOrUpdateDisplayRuleError: true,
      };
    }
    case ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST: {
      return {
        ...state,
        assignJobtagToSlotCategoryLoader: true,
      };
    }
    case ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_SUCCESS: {
      return {
        ...state,
        assignJobtagToSlotCategoryLoader: false,
      };
    }
    case ASSIGN_JOB_TAGS_TO_SLOT_CATEGORY_ERROR: {
      return {
        ...state,
        assignJobtagToSlotCategoryLoader: false,
      };
    }
    case REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_REQUEST: {
      return {
        ...state,
        removeJobtagToSlotCategoryLoader: true,
      };
    }
    case REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_SUCCESS: {
      return {
        ...state,
        removeJobtagToSlotCategoryLoader: false,
      };
    }
    case REMOVE_JOB_TAGS_TO_SLOT_CATEGORY_ERROR: {
      return {
        ...state,
        removeJobtagToSlotCategoryLoader: false,
      };
    }
    case FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_REQUEST: {
      return {
        ...state,
        assignedZonesToSlotCategoryLoader: true,
        assignedZonesToSlotCategory: [],
        assignedZonesToSlotCategoryError: false,
      };
    }
    case FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_SUCCESS: {
      return {
        ...state,
        assignedZonesToSlotCategoryLoader: false,
        assignedZonesToSlotCategory: action.payload,
        assignedZonesToSlotCategoryError: false,
      };
    }
    case FETCH_ASSIGNED_ZONES_TO_SLOT_CATEGORY_ERROR: {
      return {
        ...state,
        assignedZonesToSlotCategoryLoader: false,
        assignedZonesToSlotCategory: [],
        assignedZonesToSlotCategoryError: true,
      };
    }
    case ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST: {
      return {
        ...state,
        assignZonesAndSlotToCategoryLoader: true,
        assignZonesAndSlotToCategorySuccess: false,
        assignZonesAndSlotToCategoryError: false,
      };
    }
    case ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_SUCCESS: {
      return {
        ...state,
        assignZonesAndSlotToCategoryLoader: false,
        assignZonesAndSlotToCategorySuccess: true,
        assignZonesAndSlotToCategoryError: false,
      };
    }
    case ASSIGN_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_ERROR: {
      return {
        ...state,
        assignZonesAndSlotToCategoryLoader: false,
        assignZonesAndSlotToCategorySuccess: false,
        assignZonesAndSlotToCategoryError: true,
      };
    }
    case DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_REQUEST: {
      return {
        ...state,
        disableZonesAndSlotToCategoryLoader: true,
        disableZonesAndSlotToCategorySuccess: false,
        disableZonesAndSlotToCategoryError: false,
      };
    }
    case DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_SUCCESS: {
      return {
        ...state,
        disableZonesAndSlotToCategoryLoader: false,
        disableZonesAndSlotToCategorySuccess: true,
        disableZonesAndSlotToCategoryError: false,
      };
    }
    case DISABLE_ZONES_AND_SLOTS_TO_SLOT_CATEGORY_ERROR: {
      return {
        ...state,
        disableZonesAndSlotToCategoryLoader: false,
        disableZonesAndSlotToCategorySuccess: false,
        disableZonesAndSlotToCategoryError: true,
      };
    }
    default:
      return state;
  }
};

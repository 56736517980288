import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";
import CardBox from "components/CardBox";

const Iot = ({match})=>{
   return  <div className="app-wrapper">
    <CardBox styleName="col-12" cardStyle=" p-0" heading=" ">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
      <Route
        path={`${match.url}/mqtt`}
        component={asyncComponent(() => import("./routes/mqttStub/index"))}
      />
      <Route component={asyncComponent(() => import("components/Error404"))} />
    </Switch>
    </CardBox>
  </div>
}


export default Iot;